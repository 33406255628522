import React from 'react'
import { connect } from 'react-redux'
import { advancedSearch, setSearchFormContents, setShowSearchResults } from "../../Item/itemReducer"
import { Formik, Form } from 'formik'
import FormikField from '../../../../../hoc/Formik/FormikField'
import FormikSelect from '../../../../../hoc/Formik/FormikSelect'
import FormikRadioGroup from '../../../../../hoc/Formik/FormikRadioGroup'
import { Grid, DialogContent, DialogActions } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ClearFieldButton } from '../../../../../hoc/Formik/ClearFieldButton'
import OkDialogButton from '../../../../../hoc/Buttons/OkDialogButton'
import CancelDialogButton from '../../../../../hoc/Buttons/CancelDialogButton'

const FindItemForm = (props) => {

    const conditions = [
        { label: 'Begins With',     value: 1 },
        { label: 'Ends With',       value: 3 },
        { label: 'Exact Match',     value: 4 },
        { label: 'Match Within',    value: 2 }
    ]

    const inactiveFilters = [
        { label: 'Skip Inactive',       value: 'false' },
        { label: 'Include Inactive',    value: 'true' }
    ]

    const lookInValues = [
        { label: 'Style or Product ID', value: 'styleOrID',     key: 0 }, 
        { label: "Description",         value: 'description',   key: 1 }
    ]

    const initialValues = {
        searchValue:    props.searchFormContents.searchValue    || '',
        condition:      props.searchFormContents.condition      || 2,
        inactiveFilter: props.searchFormContents.inactiveFilter || 'false', 
        lookIn:         props.searchFormContents.lookIn         || 'styleOrID',
    }

    const history = useHistory()

    const handleSubmit = (values) => {
        props.setShowSearchResults(true)
        props.setSearchFormContents(values)
        props.advancedSearch(values, null, null, history)
    }

    return(
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({setFieldValue, values}) => {
            const handleClear = () => setFieldValue('searchValue', '')
            let emptySearchValue = values.searchValue.length === 0
            return (
                <Form>
                    <DialogContent dividers>
                        <Grid container spacing={1} >
                            <Grid item xs = {12}>
                                <FormikField 
                                    label={'Search Value'} 
                                    name={'searchValue'}
                                    required={true} 
                                    margin={'dense'} 
                                    fullWidth={true} 
                                    autoFocus={true}
                                    endAdornment={<ClearFieldButton visible={!emptySearchValue} onClick={handleClear}/>}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikRadioGroup name={'lookIn'} options={lookInValues} required={true}/>
                            </Grid>
                            <Grid item xs = {12}>
                                <FormikSelect label={'Condition'} name={'condition'} items={conditions}/>
                            </Grid>
                            <Grid item xs = {12}>
                                <FormikSelect label={'Inactive Filter'} name={'inactiveFilter'} items={inactiveFilters}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <OkDialogButton type={'submit'} disabled={emptySearchValue}/>
                        <CancelDialogButton onClick={props.onClose}/>
                   </DialogActions>
                </Form>
            )
        }}
    </Formik>
    )
}

const mapStateToProps = (state) => {
    return {
        searchFormContents:  state.itemReducer.searchFormContents
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        advancedSearch: (formResults, vendor, maxResults, history) => dispatch (advancedSearch(formResults, vendor, maxResults, history)),
        setSearchFormContents: (contents) => dispatch(setSearchFormContents(contents)),
        setShowSearchResults: (show) => dispatch(setShowSearchResults(show))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindItemForm)
