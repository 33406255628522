import axios from 'axios'

export async function fixHttp(hostName, doneCallback, failCallback) {

    var http = 'http://'
    var https = 'https://'

    var fixedHostName
    if (hostName.slice(0, http.length).toLowerCase() === http) {
        fixedHostName = hostName.slice(http.length)
    } else if (hostName.slice(0, https.length).toLowerCase() === https) {
        fixedHostName = hostName.slice(https.length)
    } else {
        fixedHostName = hostName
    }
    if (fixedHostName.slice(fixedHostName.length - 1) === '/') {
        fixedHostName = fixedHostName.slice(0, fixedHostName.length - 1)
    }

    var isHttps = false

    axios({
        url: https + fixedHostName + '/apiLogin',
        method: 'get',
        timeout: 500
    })
        .then((response) => {
            
            if(response.data.STORES.length > 0){
                isHttps = true
            }
            
            //Adding https
            doneCallback((isHttps ? https : http) + fixedHostName)
        })
        .catch((error) => {

            //Adding http
            failCallback((isHttps ? https : http) + fixedHostName)
        })
}

export default fixHttp