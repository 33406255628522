import React from 'react'
import MenuButtonWithChildren from '../../../../hoc/MenuButtonWithChildren/MenuButtonWithChildren'
import { SearchIcon } from '../../../icons'
import FindItemDialog from './FindItemDialog/FindItemDialog'
import { PATHS } from '../../../../paths'

export default () => (
    <MenuButtonWithChildren
        buttonText='Search'
        buttonIcon={SearchIcon}
        childPath={PATHS.findItem}
    >
        <FindItemDialog />
    </MenuButtonWithChildren>
)