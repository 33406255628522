import React from 'react';
import { CircularProgress, useTheme, useMediaQuery } from '@material-ui/core'

const DialogLoading = () => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'))

    const mobileStyle = {
        minWidth: '20vw', minHeight: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center'
    }

    const desktopStyle = {
        minWidth: '30vw', minHeight: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center'
    } 

    return (
        <div style={mobile ? mobileStyle : desktopStyle}>
            <CircularProgress/>
        </div> 
    );
};

export default DialogLoading;