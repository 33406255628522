import axios from 'axios'
import sessionIdParm from './sessionIdParm';

export const apiGetNewColor = (account, session, colorCode, item, doneCallback, failCallback, timeout) => {

    if (!timeout) {
        timeout = 10000;
    }

    const url = account.hostName + '/apiGetNewColor' 
        + '?c='             + colorCode 
        + '&shwInactive='   + (item.showInactive || 'true')
        + '&s='             + item.style 
        + '&m='             + item.manufacturer 
        + '&sz='            + item.size 
        + '&sn='            + account.dashStoreNumber 
        + '&apiToken='      + session.apiToken + sessionIdParm(session.sessionId, account.hostName)

    axios({
        url: url,
        method: 'get',
        timeout: timeout
    })
        .then((response) => {
            if (response.data.NEWBARCODE === '') {
                failCallback('Could not load item!')
            }
            else {
                doneCallback(response.data)
            }
        })
        .catch((error) => {
            failCallback(error)
        })
}

export default apiGetNewColor