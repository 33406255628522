import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import OkDialogButton from "../../hoc/Buttons/OkDialogButton"
import CancelDialogButton from "../../hoc/Buttons/CancelDialogButton"

const ConfirmDialog = props => {
    const { title, children, open, setOpen, onConfirm } = props

    return <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
    >
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
            <OkDialogButton
                onClick={() => {
                    setOpen(false);
                    onConfirm();
                }}
            />
            <CancelDialogButton
                onClick={() => setOpen(false)}
            />
        </DialogActions>
    </Dialog>
}

export default ConfirmDialog