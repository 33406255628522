import React from 'react'
import { connect } from 'react-redux'
import { DialogContent, DialogActions } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { setShowSearchResults, itemFetch, advancedSearch } from '../../Item/itemReducer'
import DialogLoading from "../../../../../hoc/Loading/DialogLoading"
import DialogButton from "../../../../../hoc/Buttons/DialogButton"

const FindItemResultsDialog = (props) => {
    
    const history = useHistory()

    const [searchWithVendor, setSearchWithVendor] = React.useState(false)
    const [showVendorList, setShowVendorList] = React.useState(false)
    const [showResultsList, setShowResultsList] = React.useState(false)

    const searchAgain = () => props.advancedSearch(props.searchFormContents)

    const narrowSearch = (limit) => props.advancedSearch(props.searchFormContents, '', limit)

    const onClose = () => props.setShowSearchResults(false)
    
    const handleItemClick = (BARCODE) => props.itemFetch(BARCODE, history, false, false)
    
    const handleNarrowByVendor = (vendor) => {
        props.advancedSearch(props.searchFormContents, vendor)
        setSearchWithVendor(true)
        setShowVendorList(false)
    }

    const ItemList = () => {
        return(
            <List>
                {props.searchResults.RESULTS.map(item => (
                    <ListItem button divider onClick={()=> handleItemClick(item.BARCODE)} key={item.BARCODE}>
                        <ListItemText 
                            primary={<b>{item.COMPANY}</b>}
                            secondary={item.ITEM_DES + " - " + item.ITEMIDENTIFIER + "(" + item.NUMBEROFCOLORS + (Number(item.NUMBEROFCOLORS) > 1 ? " colors)" : " color)")}
                        />
                    </ListItem>
                ))}
            </List>
        )
    }

    const RenderItemList = () => {

        if(props.loading || !props.searchResults.RESULTS){
            return <DialogContent> <DialogLoading/></DialogContent>
        }
        else{
            return(
                <React.Fragment>
                    <DialogContent dividers>
                        There are {props.searchResults.NUMBEROFITEMS} search results.
                        <ItemList/>
                    </DialogContent>
                    <br/>
                    <DialogActions>
                        <DialogButton onClick={onClose}> Back </DialogButton>
                    </DialogActions>
                </React.Fragment>
            )
        }
    }

    const VendorList = () => {
        return(
            <List style={{maxHeight: '50vh'}}>
                {props.searchResults.VENDORS.map(vendor => (
                    <ListItem button divider onClick={() => handleNarrowByVendor(vendor.MANUFACTURER)} key={vendor.MANUFACTURER}>
                        <ListItemText 
                            primary={<b>{vendor.COMPANY}</b>}
                            secondary={ vendor.MANUFACTURER }
                        />
                    </ListItem>
                ))}
            </List>
        )
    }

    const GetDialogContent = () => {

        if(props.loading || props.searchResults.length < 1 ){
            return (<DialogLoading/>)
        }
        
        if(showResultsList){
            // props.setTitle("Search Results")
            return RenderItemList()
        }
        
        if(showVendorList === true){
            // props.setTitle("Select A Vendor")
            return (
                <React.Fragment>
                    <DialogContent dividers>
                        <VendorList/>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>setShowVendorList(false)}>Back </DialogButton>
                    </DialogActions>
                </React.Fragment>
            )
        }

        else if(props.searchResults.NUMBEROFITEMS > 250 ){
            // props.setTitle("Search Results")
            return(
                <DialogContent dividers> 
                    <b>{props.searchResults.NUMBEROFITEMS}</b> items found.
                        <List>
                            <ListItem divider button onClick={searchAgain}>
                                <ListItemText primary="Search Again" style={{color: '#307ABC'}} />
                            </ListItem>
                            <ListItem divider button onClick={()=>setShowVendorList(true)}>
                                <ListItemText primary="Narrow By Vendor" style={{color: '#307ABC'}} />
                            </ListItem>
                            <ListItem divider button onClick={()=>{
                                narrowSearch(250)
                                setShowResultsList(true)
                                }}>
                                <ListItemText primary="Show First 250 Items" style={{color: '#307ABC'}} />
                            </ListItem>
                            <ListItem divider button onClick={onClose}>
                                <ListItemText primary="Back"/>
                            </ListItem>
                        </List>
                </DialogContent> 
            )
        }
        else if(props.searchResults.NUMBEROFITEMS > 10){
            // props.setTitle("Search Results")
            return(
                <DialogContent dividers> 
                    Over 10 items found.
                    <List>
                        {!searchWithVendor ?
                        <ListItem divider button onClick={()=>{setShowVendorList(true)}}>
                            <ListItemText primary="Narrow By Vendor" style={{color: '#307ABC'}} />
                        </ListItem> : null}
                        <ListItem divider button onClick={()=>setShowResultsList(true)}>
                            <ListItemText primary="Show Search Results" style={{color: '#307ABC'}} />
                        </ListItem>
                        <ListItem divider button onClick={onClose}>
                            <ListItemText primary="Back"/>
                        </ListItem>
                    </List>
                </DialogContent>
            )
        }
        else return( RenderItemList() )
    }

    return <GetDialogContent/>
}



const mapStateToProps = (state) => {
    return {
        searchResults: state.itemReducer.searchResults,
        showSearchResults: state.itemReducer.showSearchResults,
        searchFormContents:  state.itemReducer.searchFormContents,
        loading: state.itemReducer.advancedSearchLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setShowSearchResults: (isOpen) => dispatch( setShowSearchResults(isOpen)),
        itemFetch: (id, history, showInactive, scanned) => dispatch (itemFetch(id, history, showInactive, scanned)),
        advancedSearch: (formResults, vendor, maxResults) => dispatch (advancedSearch(formResults, vendor, maxResults)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FindItemResultsDialog)
