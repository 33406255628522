import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import OkDialogButton from "../../../../../hoc/Buttons/OkDialogButton"

export default () => {
    const history = useHistory()

    const goBack = () => {
        history.goBack()
    }

    return <Dialog
        open={true}
        onClose={goBack}
        aria-labelledby='about-dialog-title'
        aria-describedby='about-dialog-description'
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
    >
        <DialogTitle id='about-dialog-title'>The Uniform Solution</DialogTitle>
        <DialogContent dividers>
            <div style={{ textAlign: 'center' }}>
                <div><img alt='Logo' src='../../../../images/favicon.ico' width='64px' /></div>
                <div style={{ fontWeight: 'bold' }}>Version 2.0.00</div>
                <div style={{ fontSize: 'smaller' }}>
                    Copyright&nbsp;&copy;&nbsp;2015-2021, 
                    Diamond&nbsp;Data&nbsp;Systems</div>
            </div>
            {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
            <OkDialogButton onClick={goBack} autoFocus/>
        </DialogActions>
    </Dialog>
}
