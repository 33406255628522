import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, useHistory } from 'react-router-dom'
import {
    Box,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Divider,
} from "@material-ui/core"
import CartTable from './CartTable/CartTable'
import ScanItemDialog from '../ItemsTab/ScanItem/ScanItemDialog/ScanItemDialog'
import SaveToPOSDialog from './SaveToPOSDialog/SaveToPOSDialog'
import { CART_ENTER_ITEM, cartFetchBarcodeThenAdd, emptyCart } from "./cartReducer"
import PATHS from '../../../paths'
import EnterItemDialog from '../ItemsTab/EnterItem/EnterItemDialog/EnterItemDialog'
import ConfirmDialog from '../../../hoc/ConfirmDialog/ConfirmDialog'
import isNonBlankString from '../../../utils/isNonBlankString'
import { showToast } from '../../Toast/toastReducer'
import { MenuTitleIcon, ScanBarcodeTitleIcon } from '../../icons'

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
}))

export const CartTab = props => {
    const classes = useStyles();
    const history = useHistory()

    // Menu Controls
    const [anchorEl, setAnchorEl] = useState(null)

    const openMenu = event => setAnchorEl(event.currentTarget)

    const closeMenu = () => {
        if (anchorEl) {
            setAnchorEl(null)
        }
    }

    // Scanner
    const openScanner = () => {
        closeMenu()
        history.push(PATHS.cartScan)
    }

    const takeScan = (barcode) => {
        const callback = success => {
            setTimeout(openScanner, success ? 1000 : 2000)
        }

        history.goBack()
        props.cartFetchBarcodeThenAdd(barcode, callback)
    }

    // Enter Item
    const openEnterItem = () => {
        closeMenu()
        history.push(PATHS.cartEnterItem)
    }

    // Save to POS
    const openSaveToPos = () => {
        closeMenu()
        history.push(PATHS.cartSaveToPOS)
    }

    // Empty Cart
    const openConfirmEmpty = () => {
        closeMenu()
        history.push(PATHS.cartEmpty)
    }

    const emptyCart = () => {
        closeMenu()
        props.cartEmpty()
    }

    const cartTabTitleContent = () => {
        if (isNonBlankString(props.cart.name)) {
            return <Fragment>
                <span>Cart: </span>
                <Box component="span" color="secondary.main">{props.cart.name}</Box>
            </Fragment>
        } else if (props.cart.details.length === 0) {
            return <span>Empty Cart</span>
        } else {
            return <span>Cart</span>
        }
    }

    const cartNotEmpty = (props.cart.details.length > 0)

    return <span>
        < div className={classes.row} >
            {/* Scan barcodes */}
            < IconButton onClick={openScanner} >
                <ScanBarcodeTitleIcon />
            </IconButton >

            {/* Title */}
            <h2 style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                {cartTabTitleContent()}
            </h2>

            {/* Menu */}
            < IconButton onClick={openMenu} >
                <MenuTitleIcon />
            </IconButton >
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                disableScrollLock
            >
                <MenuItem onClick={openScanner}>Scan Barcode</MenuItem>
                <MenuItem onClick={openEnterItem}>Enter Barcode</MenuItem>
                {cartNotEmpty && <MenuItem onClick={openSaveToPos}>Save to POS</MenuItem>}
                {cartNotEmpty && <MenuItem onClick={openConfirmEmpty}>Empty Cart</MenuItem>}
            </Menu>
        </div >
        <Divider />
        <CartTable />
        <Switch>
            <Route exact path={PATHS.cartScan}>
                <ScanItemDialog singleCode={false} processCode={takeScan} onClose={history.goBack} />
            </Route>
            <Route exact path={PATHS.cartEnterItem}>
                <EnterItemDialog pathOnSuccess={PATHS.goBack} actionOnSuccess={CART_ENTER_ITEM} toastOnSuccess='Item added to cart' />
            </Route>
            <Route exact path={PATHS.cartSaveToPOS}>
                <SaveToPOSDialog />
            </Route>
            <Route exact path={PATHS.cartEmptyAfterSaveToPOS}>
                <ConfirmDialog
                    title='Empty Cart after Saving?'
                    open={true}
                    setOpen={history.goBack}
                    onConfirm={emptyCart}
                >
                    <div>The cart has been submitted successfully to the POS as <strong>{props.cart.name}</strong>.<br /></div>
                    <div>Do you want to empty the cart?</div>
                </ConfirmDialog>
            </Route>
            <Route exact path={PATHS.cartEmpty}>
                <ConfirmDialog
                    title='Empty Cart?'
                    open={true}
                    setOpen={history.goBack}
                    onConfirm={emptyCart}
                >
                    Are you sure you want to empty the cart?
                </ConfirmDialog>
            </Route>
        </Switch>
    </span>
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
})

const mapDispatchToProps = dispatch => ({
    cartFetchBarcodeThenAdd: (barcode, callAfter) => dispatch(cartFetchBarcodeThenAdd(barcode, callAfter)),
    cartEmpty: () => dispatch(emptyCart()),
    showToast: (message, severity, duration) => dispatch(showToast(message, severity, duration))
})

export default connect(mapStateToProps, mapDispatchToProps)(CartTab)
