import React from 'react'
import { makeStyles, FormControlLabel, Switch } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}))

export default (props) => {
    const classes = useStyles()

    return (
        <FormControlLabel
            className={classes.formControl}
            control={<Switch checked={props.value} onChange={props.onChange} name={props.name} color='primary' />}
            label={props.label}
            labelPlacement='start'
        />
    )
}
