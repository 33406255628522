import React from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { 
    Typography,
    List, 
    ListItem, 
    ListItemText, 
    DialogTitle,
    DialogActions,
    Dialog, 
    DialogContent,
    ListItemIcon,
    Radio,
    useTheme,
    useMediaQuery
} from '@material-ui/core';
import { itemFetchFromColor } from "../Item/itemReducer"
import CancelDialogButton from "../../../../hoc/Buttons/CancelDialogButton"

const SelectColorDialog = (props) => {

    const history = useHistory()
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'))
    const selectedColorCode = props.item.thisColor
    const originalColorCode = props.item.thisColor
    const handleClose = () => props.closeDialog()

    const handleListItemClick = (color) => {
        if(color.COLORCODE !== originalColorCode){
            props.itemFetchFromColor(color.COLORCODE, props.item, history)
        }
        props.closeDialog()
    };
    
    return (
        <Dialog onClose={handleClose} open={props.open} aria-labelledby='selectColor-dialog-title'>
            <DialogTitle id='selectColor-dialog-title'>Select Color</DialogTitle>
            <DialogContent dividers>
                <List>
                    {props.item.colors.map((color) => (
                    <ListItem 
                        button 
                        onClick={() => handleListItemClick(color)} 
                        key={color.ID}
                        style={mobile ? {padding: 0, margin: 0} : {}}
                    >
                        <ListItemIcon><Radio checked={color.COLORCODE === selectedColorCode} color='primary' disableRipple/></ListItemIcon>
                        <ListItemText primary={
                            <Typography color={color.COLORCODE === selectedColorCode ? 'primary' : 'textPrimary'}>
                                {color.NAME}
                            </Typography>
                        }/>
                    </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <CancelDialogButton onClick={handleClose} />
            </DialogActions>
        </Dialog>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        itemFetchFromColor: (color, item, history) => dispatch (itemFetchFromColor(color, item, history))
    }
}

export default connect(null, mapDispatchToProps)(SelectColorDialog)