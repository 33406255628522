import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2),
    width: "200px",
    height: "80px",
    borderRadius: "10px",
    backgroundColor: "white",
    color: "#307ABC",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "30px",
    height: "30px",
  }
}));

export default (props) => {
  const classes = useStyles();

  const TheIcon = () => <props.icon className={classes.icon} />;

  return (
    <div>
      <Button
        variant="contained"
        className={classes.button}
        onClick={props.onClick}
      >
        <Grid container spacing={2}>
          <Grid item xs={3} className={classes.row}>
            <TheIcon />
          </Grid>
          <Grid item xs={9} className={classes.row} style={{ textAlign: "left", lineHeight: 1.20 }}>
            {props.children}
          </Grid>
        </Grid>
      </Button>
    </div>
  );
};

// import React from 'react'
// import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { Button } from '@material-ui/core'

// const buttonStyles = {
//     label: {
//         flexDirection: 'column'
//     }
// }

// const MenuButton = props => {
// const TheIcon = () => (
//     <props.icon />
// )
//
// const imageStyles = { root: { width: 64, height: 64, marginBottom: 0 } }

// const Image = withStyles(imageStyles)(({ classes }) =>
//     <TheIcon classes={classes} />  //{ props.icon }
// )

//     return (
//         <div>
//             <Button
//                 dense
//                 color='primary'
//                 variant='contained'
//                 classes={props.classes}
//                 onClick={props.onClick}
//             >
//                 <Image />
//                 {props.children}
//             </Button>
//         </div>
//     )
// }

// export default () => {
//     withStyles(buttonStyles)(MenuButton)
// }
