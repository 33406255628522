import React from 'react'
import FormSwitch from '../../../../FormSwitch/FormSwitch'
import { CARTMODE_RETAIL, CARTMODE_VENDOR } from '../../../../../classes/Preferences'
import { connect } from 'react-redux'
import { updatePreferences } from '../preferencesReducer'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    FormGroup,
    FormLabel,
    FormControlLabel,
    Grid,
    Select,
    MenuItem,
    makeStyles,
    DialogActions
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import OkDialogButton from '../../../../../hoc/Buttons/OkDialogButton'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}))

const PreferencesDialog = ({ preferences, updatePreferences }) => {
    const classes = useStyles()
    const history = useHistory()

    const goBack = () => {
        history.goBack()
    }

    //Update the Form
    const updateField = (e) => {
        var newPreferences = {}

        switch (e.target.name) {
            case 'cartMode':
                newPreferences = {
                    ...preferences,
                    cartMode: e.target.value,
                }
                break
            default:
                // Everything else is a switch
                newPreferences = {
                    ...preferences,
                    [e.target.name]: !preferences[e.target.name],
                }
                break
        }

        //Update Redux
        updatePreferences(newPreferences)
    }

    return (
        <Dialog
            open={true}
            onClose={goBack}
            aria-labelledby='preferences-dialog-title'
            aria-describedby='preferences-dialog-description'
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
        >
            <DialogTitle id='preferences-dialog-title'>Preferences</DialogTitle>
            <DialogContent dividers>
                {/* <DialogContentText id='preferences-dialog-description'>Preferences</DialogContentText> */}
                <Grid container direction='column'>
                    <FormControl component='fieldset'>
                        <FormLabel component='legend'>Dashboard</FormLabel>
                        <FormGroup>
                            <FormSwitch
                                onChange={updateField}
                                value={preferences.showUserSales}
                                name='showUserSales'
                                label="Show User's Sales"
                            />
                            <FormSwitch
                                onChange={updateField}
                                value={preferences.showInventory}
                                name='showInventory'
                                label='Show Inventory'
                            />
                            <FormSwitch
                                onChange={updateField}
                                value={preferences.showPOs}
                                name='showPOs'
                                label="Show Open PO's"
                            />
                            <FormSwitch
                                onChange={updateField}
                                value={preferences.showAR}
                                name='showAR'
                                label='Show AR'
                            />
                            <FormSwitch
                                onChange={updateField}
                                value={preferences.showAP}
                                name='showAP'
                                label='Show AP'
                            />
                            <FormSwitch
                                onChange={updateField}
                                value={preferences.showRefreshButton}
                                name='showRefreshButton'
                                label='Refresh Button'
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component='fieldset'>
                        <FormLabel component='legend'>Scanner</FormLabel>
                        <FormGroup>
                            <FormSwitch
                                onChange={updateField}
                                value={preferences.vibrateOnScan}
                                name='vibrateOnScan'
                                label='Vibrate'
                            />
                            <FormSwitch
                                onChange={updateField}
                                value={preferences.playSoundOnScan}
                                name='playSoundOnScan'
                                label='Play Sound'
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component='fieldset'>
                        <FormLabel component='legend'>Cart</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                className={classes.formControl}
                                control={
                                    <React.Fragment>
                                        <Select
                                            labelId='cartmode-select-label'
                                            id='cartmode-select'
                                            name='cartMode'
                                            value={preferences.cartMode}
                                            onChange={updateField}
                                        >
                                            <MenuItem value={CARTMODE_RETAIL}>{CARTMODE_RETAIL}</MenuItem>
                                            <MenuItem value={CARTMODE_VENDOR}>{CARTMODE_VENDOR}</MenuItem>
                                        </Select>
                                    </React.Fragment>
                                }
                                label='Mode:&nbsp;'
                                labelPlacement='start'
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component='fieldset'>
                        <FormLabel component='legend'>Miscellaneous</FormLabel>
                        <FormGroup>
                            <FormSwitch
                                onChange={updateField}
                                value={preferences.keepLoggedIn}
                                name='keepLoggedIn'
                                label='Stay Connected to Server'
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <OkDialogButton onClick={goBack}/>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => {
    return {
        preferences: state.preferencesReducer.preferences,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updatePreferences: (preferences) => dispatch(updatePreferences(preferences)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesDialog)
