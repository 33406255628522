import axios from 'axios';
import sessionIdParm from './sessionIdParm';

export const apiCheckStockForStore = (hostName, session, store, id, doneCallback, failCallback ) => {

    var url = store.URL + '&barcode=' + id + '&apiToken=' + session.apiToken + sessionIdParm(session.sessionId, hostName)
    
    axios({
        url: url,
        method: 'get',
        timeout: 8000
    })
        .then((response) => {
            if(!response.data.StockText){
                failCallback(store, 'Could not load this item.')
            }
            doneCallback(store, response.data)
        })
        .catch((error) => {
            failCallback(store, 'Could not load this item.')
        })
}


export default apiCheckStockForStore