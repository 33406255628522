import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Account from './Account/Account'
import AddAccount from './AddAccount/AddAccount'
import EditAccount from './EditAccount/EditAccount'
import DeleteAccountDialog from './DeleteAccountDialog/DeleteAccountDialog'
import MenuButton from '../../../../hoc/MenuButton/MenuButton'
import { IconButton, makeStyles, List, Menu, MenuItem, Divider, Typography } from '@material-ui/core'
import { LOGIN_MODE, reconnectToServer } from '../../../../redux/reducers/loginReducer'
import { AddAccountIcon, BackTitleIcon, MenuTitleIcon, RedoTitleIcon } from '../../../icons'

const useStyles = makeStyles(() => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    addUserContainer: {
        height: '60vh',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
    }
}))

const Accounts = (props) => {
    const classes = useStyles()
    const history = useHistory()

    const [expandedAccount, setExpandedAccount] = React.useState(null)
    const [openAdd, setOpenAdd]                 = React.useState(false)
    const [openEdit, setOpenEdit]               = React.useState(false)
    const [openDelete, setOpenDelete]           = React.useState(false)

    let connected = (props.mode === LOGIN_MODE.authenticated)
    let accountSelected = (props.selectedAccount !== null)

    const handleEditClick = () => {
        closeMenu()
        setOpenEdit(true)
    }

    const handleAddClick = () => {
        closeMenu()
        setOpenAdd(true)
    }

    const handleDeleteClick = () => {
        closeMenu()
        setOpenDelete(true)
    }

    const handleAddClose = () => setOpenAdd(false)
    const handleEditClose = () => setOpenEdit(false)
    

    //Menu Controls
    const [anchorEl, setAnchorEl] = React.useState(null)
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const closeMenu = () => {
        if (anchorEl) {
            setAnchorEl(null)
        }
    }

    function getAccount(key) {
        for (var i = 0; i < props.accounts.length; i++) {
            if (props.accounts[i].key === key) {
                return props.accounts[i]
            }
        }
    }

    //Render icon in header
    const RenderIcon = () => {

        if(props.selectedAccount === null){
            return <div style={{width: "52.8px"}}></div>
        }
        else if(connected){
            return ( 
                <IconButton onClick={() => history.goBack()}>
                    <BackTitleIcon />
                </IconButton>
            )
        }
        else return(
            <IconButton onClick={() => props.reconnectToServer()}>
                <RedoTitleIcon />
            </IconButton>
        )
    }

    const AddUserContainer = () => {
        return(
            <div className={classes.addUserContainer}>
                <Typography variant='h4'>No Accounts</Typography>
                <MenuButton icon={AddAccountIcon} onClick={handleAddClick}>
                    Add Account
                </MenuButton>
            </div>
        )
    }

    return (
        <React.Fragment>
            {/* Title and Buttons */}
            <div className={classes.row}>

                <RenderIcon/>

                {/* Title */}
                <h2 style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Accounts</h2>

                {/* Menu */}
                <IconButton onClick={openMenu}>
                    <MenuTitleIcon />
                </IconButton>

                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={closeMenu}
                    disableScrollLock
                >
                    {/* ADD ACCOUNT */}
                    <MenuItem onClick={handleAddClick}>Add</MenuItem>
                    {/* EDIT SELECTED ACCOUNT */}
                    {accountSelected && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}
                    {/* RECONNECT */}
                    {(connected && accountSelected) && <MenuItem onClick={() => props.reconnectToServer()}>Reconnect</MenuItem>}
                    {(connected && accountSelected) && <Divider/>}

                    {/* DELETE SELECTED ACCOUNT */}
                    {accountSelected && <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>}
                </Menu>
            </div>

            <Divider />

            {/* NO ACCOUNTS */}
            {props.accounts.length < 1 && <AddUserContainer/>}

            {/* //Accounts are already added. Display list. */}
            {props.accounts.length > 0 && (
                <List style={{ margin: '0', padding: '0' }}>
                    {props.accounts.map((account) => {
                        return (
                            <Account
                                account={account}
                                key={account.key}
                                expandedAccount={expandedAccount}
                                setExpandedAccount={setExpandedAccount}
                            />
                        )
                    })}
                </List>
            )}
            <AddAccount
                isOpen={openAdd}
                onClose={handleAddClose}
            />
            <EditAccount
                isOpen={openEdit}
                onClose={handleEditClose}
                account={getAccount(props.selectedAccount)}
            />
            <DeleteAccountDialog
                open={openDelete}
                setOpen={setOpenDelete}
                accountKey={props.selectedAccount}
            />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        mode: state.loginReducer.mode,
        accounts: state.accountsReducer.accounts,
        selectedAccount: state.accountsReducer.selectedAccount
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        reconnectToServer: () => dispatch(reconnectToServer()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts)
