export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export const thisYear = () => (new Date().getFullYear())
export const lastYear = () => (new Date().getFullYear() - 1)
export const thisMonth = () => (months[new Date().getMonth()])
export const thisDay = () => (new Date().getDate())
