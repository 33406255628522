const makePath = (...segments) => {
    let path = ''
    for (let x = 0; x < segments.length; x++) {
        path += '/' + segments[x]
    }
    return path
}

const TAB_NAME = Object.freeze({
    dashboard: 'dashboard',
    items: 'items',
    cart: 'cart',
    settings: 'settings',
})

// export const HASH = Object.freeze({
//     enterItem: POUND + 'enteritem',
// })

export const PATHS = Object.freeze({
    goBack: '<',

    dashboardTab: makePath(TAB_NAME.dashboard),

    itemsTab: makePath(TAB_NAME.items),
    scanItem: makePath(TAB_NAME.items, 'scanitem'),
    enterItem: makePath(TAB_NAME.items, 'enteritem'),
    findItem: makePath(TAB_NAME.items, 'finditem'),
    item: makePath(TAB_NAME.items, 'item'), // WIP: Change to support ID/barcode

    cartTab: makePath(TAB_NAME.cart),
    cartScan: makePath(TAB_NAME.cart, 'scan'),
    cartEnterItem: makePath(TAB_NAME.cart, 'enteritem'),
    cartSaveToPOS: makePath(TAB_NAME.cart, 'save'),
    cartEmptyAfterSaveToPOS: makePath(TAB_NAME.cart, 'save/empty'),
    cartEmpty: makePath(TAB_NAME.cart, 'empty'),

    settingsTab: makePath(TAB_NAME.settings),
    accounts: makePath(TAB_NAME.settings, 'accounts'),
    preferences: makePath(TAB_NAME.settings, 'preferences'),
    about: makePath(TAB_NAME.settings, 'about'),
})

export default PATHS