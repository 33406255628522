// Occasionally you will need to work with Clarion-style dates and times.
// These two functions convert from a Javascript date to the Clarion separates.

export const toClaDate = date => {
    if (Object.prototype.toString.call(date) !== '[object Date]') {
        return 0
    }

    const baseDate = new Date('1801-01-01T00:00:00Z')
    if (date < baseDate) {
        return 0
    }

    const dateDiffInDays = (d1, d2) => {
        var utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate())
        var utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate())
        var _MS_PER_DAY = 1000 * 60 * 60 * 24

        return Math.floor((utc2 - utc1) / _MS_PER_DAY)
    }

    const _CLADATE_MINDATE = 4
    const claDate = _CLADATE_MINDATE + dateDiffInDays(baseDate, date)
    console.log('claDate', claDate)
    return claDate
}

export const toClaTime = date => {
    if (Object.prototype.toString.call(date) !== '[object Date]') {
        return 0
    }

    const _CLATIME_MIDNIGHT = 1
    const _CLATIME_SECOND = 100
    const _CLATIME_MINUTE = _CLATIME_SECOND * 60
    const _CLATIME_HOUR = _CLATIME_MINUTE * 60

    const claTime = _CLATIME_MIDNIGHT
        + _CLATIME_HOUR * date.getHours()
        + _CLATIME_MINUTE * date.getMinutes()
        + _CLATIME_SECOND * date.getSeconds()
    console.log('claTime', claTime)
    return claTime
}
