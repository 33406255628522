import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import EditAccountDialog from '../EditAccount/EditAccountDialog/EditAccountDialog'
import DeleteAccountDialog from '../DeleteAccountDialog/DeleteAccountDialog'
import { updateSelectedAccount, selectAccountFromList } from '../accountsReducer'
import { LOGIN_MODE, reconnectToServer } from '../../../../../redux/reducers/loginReducer'
import {
    Radio,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Typography,
    ClickAwayListener,
} from '@material-ui/core'
import useLongPress from '../../../../../utils/useLongPress'
import { ConnectedIcon, DisconnectedIcon } from '../../../../icons'

const Account = (props) => {
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)
    })

    let selectedAccount = (props.selectedAccount === props.account.key)
    let expandedAccount = (props.expandedAccount === props.account.key)

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    }

    const onClick = () => {
        if (!selectedAccount) {
            props.selectAccountFromList(props.account.key)
        }
    }

    const assignExpandedAccount = () => props.setExpandedAccount(props.account.key)

    const clearExpandedAccount = () => props.setExpandedAccount(null)

    const onLongPress = () => expandedAccount ? clearExpandedAccount() : assignExpandedAccount()

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions)

    const handleClickAway = () => {
        if (expandedAccount) {
            clearExpandedAccount()
        }
    }

    const handleEditClick = () => {
        setOpenEdit(true)
        clearExpandedAccount()
    }

    const handleDeleteClick = () => {
        setOpenDelete(true)
        clearExpandedAccount()
    }

    const handleConnectToServer = () => {
        props.reconnectToServer()
    }

    const DisplayConnection = () => {
        if (selectedAccount) {
            return (props.mode === LOGIN_MODE.authenticated)
                ? <ConnectedIcon color={"#4caf50"}/>
                : <DisconnectedIcon color={"#f44336"}/>
        }
        return null
    }

    const hostName = () => {
        let hostName = props.account.hostName
        if (hostName.length > 25 && dimensions.width < 360) {
            hostName = hostName.substring(0, 25) + '...'
        }
        return hostName
    }

    return (
        <Fragment>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                    <ListItem dense button key={props.account.key} {...longPressEvent}>
                        <ListItemIcon>
                            <Radio checked={selectedAccount} color={'primary'} disableRipple />
                        </ListItemIcon>
                        <ListItemText
                            primary={<b>{props.account.accountName}</b>}
                            secondary={hostName()}
                        />
                        <DisplayConnection />
                    </ListItem>

                    {expandedAccount && (
                        <List>
                            <ListItem button onClick={handleEditClick}>
                                <Typography color={'primary'}>Edit</Typography>
                            </ListItem>
                            {selectedAccount && (props.mode !== LOGIN_MODE.authenticated) ?
                                <ListItem button onClick={handleConnectToServer}>
                                    <Typography color={'primary'}>Connect To Server</Typography>
                                </ListItem> : null
                            }
                            <ListItem button onClick={handleDeleteClick}>
                                <Typography color={'secondary'}>Delete</Typography>
                            </ListItem>
                        </List>
                    )}
                    <Divider />
                </div>
            </ClickAwayListener>
            <EditAccountDialog
                isOpen={openEdit}
                onClose={() => setOpenEdit(false)}
                account={props.account}
            />
            <DeleteAccountDialog
                open={openDelete}
                setOpen={setOpenDelete}
                accountKey={props.account.key}
            />
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        accounts: state.accountsReducer.accounts,
        selectedAccount: state.accountsReducer.selectedAccount,
        mode: state.loginReducer.mode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedAccount: (account) => dispatch(updateSelectedAccount(account)),
        selectAccountFromList: (accountKey) => dispatch(selectAccountFromList(accountKey)),
        reconnectToServer: () => dispatch(reconnectToServer()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
