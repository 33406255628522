import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ScanItem from './ScanItem/ScanItem'
import EnterItem from './EnterItem/EnterItem'
import FindItem from './FindItem/FindItem'
import Item from './Item/Item'
import MenuButton from '../../../hoc/MenuButton/MenuButton'
import '../../App/App.css'
import { PATHS } from '../../../paths'
import { ScanBarcodeIcon } from '../../icons'

const ItemsTab = () => {
    const [codes, addCodes] = React.useState([])

    const processCode = (readCode) => {
        addCodes([...codes, readCode])
    }

    return (
        <div className={'Button-container'}>
            <Switch>
                <Route path={PATHS.item}>
                    <Item />
                </Route>
                <Route path={PATHS.itemsTab}>
                    <ScanItem
                        scanPath={PATHS.scanItem}
                        singleCode={true}
                        processCode={readCode => processCode(readCode)}>
                            
                        <MenuButton icon={ScanBarcodeIcon}>Scan Barcode</MenuButton>
                    </ScanItem>
                    <EnterItem />
                    <FindItem />
                </Route>
            </Switch>
        </div>
    )
}

export default ItemsTab
