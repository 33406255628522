export const CARTMODE_RETAIL = 'Retail'
export const CARTMODE_VENDOR = 'Vendor'

export class Preferences {
    constructor() {
        this.showUserSales = true
        this.showInventory = true
        this.showPOs = true
        this.showAR = true
        this.showAP = true
        this.showRefreshButton = true
        this.vibrateOnScan = true
        this.playSoundOnScan = true
        this.keepLoggedIn = true
        this.cartMode = CARTMODE_VENDOR
    }
}

export default Preferences