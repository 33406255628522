import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { itemFetch } from "../../Item/itemReducer"
import { connect } from 'react-redux'
import isNonBlankString from '../../../../../utils/isNonBlankString'
import { ClearFieldButton } from '../../../../../hoc/Formik/ClearFieldButton'
import OkDialogButton from '../../../../../hoc/Buttons/OkDialogButton'
import CancelDialogButton from '../../../../../hoc/Buttons/CancelDialogButton'

const EnterItemDialog = (props) => {
    const history = useHistory()

    const [value, setValue] = React.useState('')

    const goBack = () => history.goBack()

    const invalid = () => (!isNonBlankString(value))

    const handleChange = (e) => setValue(e.target.value)

    const fetchItem = () => props.itemFetch(value, history, false, false, props.pathOnSuccess, props.actionOnSuccess, props.toastOnSuccess)

    const handleClear = () => setValue('')

    return (
        <Dialog
            open={true}
            onClose={goBack}
            aria-labelledby={'enteritem-dialog-title'}
            aria-describedby={'enteritem-dialog-description'}
            maxWidth={"xs"}
        >
            <DialogTitle id={'enteritem-dialog-title'}>Enter the UPC or Barcode</DialogTitle>
            <DialogContent dividers>
                <TextField
                    value={value}
                    onChange={handleChange}
                    autoFocus
                    margin={'dense'}
                    id={'barcode'}
                    label={'Item Code'}
                    type={'number'}
                    fullWidth
                    InputProps={{ endAdornment: <ClearFieldButton visible={(value.length > 0)} onClick={handleClear}/>}} 
                />
            </DialogContent>
            <DialogActions>
                <OkDialogButton onClick={fetchItem} disabled={invalid()}/>
                <CancelDialogButton onClick={goBack}/>
            </DialogActions>
        </Dialog>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        itemFetch: (id, history, showInactive, scanned, pathOnSuccess, actionOnSuccess, toastOnSuccess) => dispatch(itemFetch(id, history, showInactive, scanned, pathOnSuccess, actionOnSuccess, toastOnSuccess))
    }
}

export default connect(null, mapDispatchToProps)(EnterItemDialog)
