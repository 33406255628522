import axios from 'axios'
import { updateObject } from '../../../redux/utility'
import { inList } from '../../../utils/inList'
import { sessionIdParm } from '../../../utils/sessionIdParm'
import { loginServerAccessed } from '../../../redux/reducers/loginReducer'

/*****************************************************************************/
// Module data

const DASHBOARD_SET_STATE = '/dashboard/DASHBOARD_SET_STATE'

export const DASHBOARD_MODE = Object.freeze({
    initial: 'Initial', // very temporary on viewShown
    refreshing: 'Refreshing', // often ignored
    loading: 'Loading',
    polling: 'Polling',
    blank: 'Blank',
    dashboard: 'Dashboard', // employee user
    store: 'Store', // retail user
})

const initialState = {
    mode: DASHBOARD_MODE.initial,
    visible: {
        header: false,
        data: false,
        pullDownReminder: false,
        polling: false,
        loading: false,
    },
    polling: {
        pollingCountdown: 10,
        pollingRequestId: 0,
        countdown: {
            interval: 0,
            timer: null,
        },
    },
    summary: {
        todaysSales: '',
        myTodaysSales: '',
        avgTicket: '',
        myAvgTicket: '',
        numberOfTickets: '',
        myNumberOfTickets: '',
        monthThisYear: '',
        monthLastYear: '',
        myMonthThisYear: '',
        myMonthLastYear: '',
        ytdThisYear: '',
        ytdLastYear: '',
        myYtdThisYear: '',
        myYtdLastYear: '',
        accountsReceivable: '',
        accountsPayable: '',
        inventoryRetail: '',
        inventoryCost: '',
        openPOs: '',
        pctChangeMTD: '',
        pctChangeYTD: '',
        myPctChangeMTD: '',
        myPctChangeYTD: '',
    },
}

/*****************************************************************************/
// Action Creators

export const dashboardSetMode = (mode, selectedAccount, session) => {

    return (dispatch, getState) => {
        const workingState = { ...getState().dashboardReducer }
        dashboardSetMode('dashboardSetMode/1', workingState)
        delete (workingState.summary)
        dashboardSetMode('dashboardSetMode/2', workingState)

        setMode(mode, dispatch, selectedAccount, session, workingState)
    }
}

/*****************************************************************************/
// Reducer

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_SET_STATE: return updateOn_DASHBOARD_SET_STATE(state, action)
        default: return state
    }
}

const updateOn_DASHBOARD_SET_STATE = (state, action) => {
    return updateObject(state, action.newState)
}

/*****************************************************************************/
// Module Functions

const setMode = (newMode, dispatch, selectedAccount, session, workingState, data) => {
    console.log('************* setMode: mode=' + workingState.mode + '; newMode=' + newMode, data);

    const setLoadingVisible = isVisible => {
        //console.log('setLoadingVisible: isVisible=' + isVisible)
        workingState.visible.loading = isVisible
        workingState.visible.header = !isVisible
    }

    const setDataVisible = isVisible => {
        //console.log('setDataVisible: isVisible=' + isVisible)
        workingState.visible.header = isVisible
        workingState.visible.data = isVisible
        if (isVisible
            || workingState.mode === DASHBOARD_MODE.loading
            || workingState.mode === DASHBOARD_MODE.polling
            || session.isRetailUser) {
            workingState.visible.pullDownReminder = false
        } else {
            workingState.visible.pullDownReminder = true
        }
    }

    const startCountdown = () => {
        // TODO: Is this how to do it in React?
        // if (newState.polling.countdown.interval === 0) {
        //     newState.polling.countdown.timer = 10;
        //     ///newState.polling.countdown = newState.polling.countdown.timer //????? What's newState.polling.countdown really supposed to be?
        //     newState.polling.countdown.interval = setInterval(() => {
        //         newState.polling.countdown = --newState.polling.countdown.timer
        //         if (newState.polling.countdown.timer <= 0) {
        //             clearInterval(newState.polling.countdown.interval);
        //             newState.polling.countdown.interval = 0;
        //             workingState.mode = DASHBOARD_MODE.loading
        //         }
        //     }, 1000);
        // }
    }

    // This is the changing of the Mode
    const setMode_Engine = () => {

        const setSummaryFromData = () => {
            workingState.summary = {
                todaysSales: data.TODAY,
                myTodaysSales: data.MYTODAY,
                avgTicket: data.AVGTICKET,
                myAvgTicket: data.MYAVGTICKET,
                numberOfTickets: data.NUMBEROFTICKETS,
                myNumberOfTickets: data.MYNUMBEROFTICKETS,
                monthThisYear: data.MONTHTHISYEAR,
                monthLastYear: data.MONTHLASTYEAR,
                myMonthThisYear: data.MYMONTHTHISYEAR,
                myMonthLastYear: data.MYMONTHLASTYEAR,
                ytdThisYear: data.YTDTHISYEAR,
                ytdLastYear: data.YTDLASTYEAR,
                myYtdThisYear: data.MYYTDTHISYEAR,
                myYtdLastYear: data.MYYTDLASTYEAR,
                accountsReceivable: data.CURRENTAR,
                accountsPayable: data.CURRENTAP,
                inventoryRetail: data.INVENTORYRETAIL,
                inventoryCost: data.INVENTORYCOST,
                openPOs: data.OPENPOS,
                pctChangeMTD: data.PCTCHANGEMTD,
                pctChangeYTD: data.PCTCHANGEYTD,
                myPctChangeMTD: data.MYPCTCHANGEMTD,
                myPctChangeYTD: data.MYPCTCHANGEYTD,
            }
        }

        console.log('************* setMode_Engine: mode=' + workingState.mode + '; newMode=' + newMode);
        workingState.mode = newMode
        switch (newMode) {
            case DASHBOARD_MODE.loading:
                setDataVisible(false)
                workingState.visible.polling = false
                setLoadingVisible(true)
                load(dispatch, selectedAccount, session, workingState)
                break
            case DASHBOARD_MODE.polling:
                startCountdown()
                workingState.visible.polling = true
                break
            case DASHBOARD_MODE.blank:
                setLoadingVisible(false)
                setDataVisible(false)
                break
            case DASHBOARD_MODE.dashboard:
                setLoadingVisible(false)
                //console.log('Before setSummaryFromData', data)
                if (data) {
                    setSummaryFromData()
                    setDataVisible(true)
                }
                break;
            case DASHBOARD_MODE.store:
                break
            default:
                alert('Unexpected newMode in setMode_Engine: ' + newMode)
                break
        }
    }

    // This tests whether the Mode change is acceptable
    switch (newMode) {
        case DASHBOARD_MODE.initial: // Redirect to appropriate Mode
            newMode = DASHBOARD_MODE.loading
            setMode_Engine()
            break
        case DASHBOARD_MODE.refreshing: // Redirect to appropriate Mode
            if (inList(workingState.mode, [
                DASHBOARD_MODE.blank,
                DASHBOARD_MODE.dashboard,
                DASHBOARD_MODE.store
            ])) {
                newMode = DASHBOARD_MODE.loading
                setMode_Engine()
            } else if (workingState.mode === DASHBOARD_MODE.polling) {
                // Perhaps let it automatically finish the clear the countdown and refresh
            }
            break
        case DASHBOARD_MODE.loading: // This can only come from these
            if (inList(workingState.mode, [
                DASHBOARD_MODE.initial,
                DASHBOARD_MODE.polling,
                DASHBOARD_MODE.blank,
                DASHBOARD_MODE.dashboard,
                DASHBOARD_MODE.store
            ])) {
                setMode_Engine()
            }
            break;
        case DASHBOARD_MODE.polling: // Each of these must come from Loading
        case DASHBOARD_MODE.blank:
        case DASHBOARD_MODE.dashboard:
        case DASHBOARD_MODE.store:
            if (workingState.mode === DASHBOARD_MODE.loading) {
                setMode_Engine()
            }
            break
        default:
            alert('Unexpected newMode: ' + newMode)
            break
    }

    dispatch({
        type: DASHBOARD_SET_STATE,
        newState: workingState
    })
}

const load = (dispatch, selectedAccount, session, workingState) => {
    console.log('load/IN: current=' + workingState);

    const _setMode = (newMode, data) => {
        setMode(newMode, dispatch, selectedAccount, session, workingState, data);
    }

    if (session.isRetailUser) {
        _setMode(DASHBOARD_MODE.store);
    } else {
        let thisRequestId = 0
        if (workingState.polling.requestId === 0) {
            thisRequestId = Math.floor(Math.random() * 2e9) + 1 // Almost max for Clarion LONG
        } else {
            thisRequestId = workingState.polling.requestId
        }

        const dashboardTimeout = 40000

        // Request dashbaord summary totals
        axios({
            url: selectedAccount.hostName
                + "/apiGetDasboardInfo"
                + "?apiToken=" + session.apiToken
                + sessionIdParm(session.sessionId, selectedAccount.hostName)
                + "&sn=" + selectedAccount.dashStoreNumber
                + "&ri=" + thisRequestId,
            method: 'get',
            timeout: dashboardTimeout
        })
            .then((response) => {
                console.log('apiGetDashboardInfo/then', response)
                dispatch(loginServerAccessed())
                if (response.data.AUTHOK === 0) {
                    _setMode(DASHBOARD_MODE.blank);
                    // loginDisconnect() TODO: We might need this, once I get all the pieces working better
                } else if (inList(response.data.REQUESTID, [0, undefined])) {
                    _setMode(DASHBOARD_MODE.dashboard, response.data);
                    workingState.polling.requestId = 0
                } else {
                    _setMode(DASHBOARD_MODE.polling);
                    workingState.polling.requestId = thisRequestId
                }
            })
            .catch((error) => {
                console.log('apiGetDashboardInfo/catch', error)
                _setMode(DASHBOARD_MODE.blank);
            })
    }
}
