import React, { Fragment } from 'react'
import MenuButton from '../MenuButton/MenuButton'
import { Route, useHistory } from 'react-router-dom'

export default (props) => {
    const history = useHistory()

    const handleClickOpen = () => {
        history.push(props.childPath)
    }

    return <Fragment>
        <MenuButton icon={props.buttonIcon} onClick={handleClickOpen}>
            {props.buttonText}
        </MenuButton>
        <Route exact path={props.childPath}>
            {props.children}
        </Route>
    </Fragment>
}
