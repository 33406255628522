import React from 'react'
import { Dialog, DialogTitle } from '@material-ui/core'
import AccountForm from '../../AccountForm/AccountForm'

const AddAccountDialog = (props) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby='Add-Account-dialog-title'
            aria-describedby='Add-Account-dialog-description'
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth='xs'
        >
            <DialogTitle>Add Account</DialogTitle>
            <AccountForm type='add' onSubmit={props.onClose} onClose={props.onClose}/>
        </Dialog>
    )
}

export default AddAccountDialog
