import React from 'react'
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core'
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        minWidth: "90px"
    },
};

const DialogButton = (props) => {
    const { classes, children, className, ...other } = props

    return(
        <Button color={props.color || "primary"} variant={props.variant || "contained"} className={clsx(classes.root, className)} {...other}>
            {children}
        </Button>
    )
}

DialogButton.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DialogButton);