import axios from 'axios'
import sessionIdParm from './sessionIdParm';

export const apiVStockGet = (hostName, session, id, doneCallback, failCallback) => {

    const url = hostName 
        + "/apiVStockGet?bc=" + id 
        + "&apiToken=" + session.apiToken 
        + sessionIdParm(session.sessionId, hostName);

    console.log(url)

    axios({
        url: url,
        method: 'get',
        timeout: 15000
    })
        .then((response) => {
            doneCallback(response.data)
        })
        .catch((error) => {
            console.log(error)
            failCallback(error)
        })
}

export default apiVStockGet