import { firstServerVersionToUseIdSuffix, workingVersion } from "../redux/reducers/versionReducer"

export const sessionIdParm = (sessionId, hostName) => {
    if (sessionId !== '') {
        let sidSuffix = ''
        if (workingVersion() >= firstServerVersionToUseIdSuffix && hostName.substring(0, 5).toLowerCase() === "http:") {
            sidSuffix = "X"
        }
        return '&SESSIONID' + sidSuffix + '=' + sessionId
    } else {
        return ''
    }
}

export default sessionIdParm