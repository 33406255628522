import axios from 'axios';
import sessionIdParm from './sessionIdParm';

var BARCODE_DELIMITER = ';:;';

export const apiGetProduct = (hostName, session, id, showInactive, scanned, doneCallback, failCallback, timeout) => {

    var idParm;
    if (!Array.isArray(id)) {
        idParm = id;
    } else {
        for (var i = 0; i < id.length; i++) {
            if (i === 0) {
                idParm = encodeURIComponent(id[i]);
            } else {
                idParm += BARCODE_DELIMITER + encodeURIComponent(id[i]);
            }
        }
    }

    if (!timeout) {
        timeout = 10000;
    }

    var inventoryStoreNumber = "1"  // TODO: Should come from account options

    const url = hostName
        + '/apiGetProduct'
        + '?id=' + idParm
        + '&shwInactive=' + (showInactive || 'true')
        + '&s=' + (scanned || '0')
        + '&isn=' + inventoryStoreNumber
        + '&apiToken=' + session.apiToken
        + sessionIdParm(session.sessionId, hostName)
    console.log('url', url)

    axios({
        url: url,
        method: 'get',
        timeout: timeout
    })
        .then((response) => {
            console.log(response)
            if(response.data.Error === 'Too many items for grid!'){
                console.log("Too many items for grid!")
                failCallback('Too many items for grid!')
            }
            if(response.data.BARCODE === 0)
            {
                failCallback('Could not find item!')
            } else {
                doneCallback(response.data)
            }
        })
        .catch((error) => {
            failCallback(error)
        })
}

export default apiGetProduct