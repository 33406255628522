import React from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import DialogButton from "./DialogButton"

const styles = {
    root: {
        backgroundColor: "#00c853",
        color: "white",
        '&:hover': {
            backgroundColor: "#008c3a"
        },
        "&:disabled": {
            color: "white",
            backgroundColor: "#81c784"
        }
    },
};

const OkDialogButton = (props) => {
    const { classes, children, className, ...other } = props

    return(
        <DialogButton variant={props.variant || "contained"} className={clsx(classes.root, className)} {...other}>
            {children || "OK"}
        </DialogButton>
    )
}

DialogButton.propTypes = {
    children: PropTypes.node,
}

export default withStyles(styles)(OkDialogButton);