import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Accounts from './Accounts/Accounts'
import AccountsButton from './Accounts/AccountsButton'
import Preferences from './Preferences/Preferences'
import About from './About/About'
import { PATHS } from '../../../paths'

const SettingsTab = () => (
    <Switch>
        <Route path={PATHS.accounts}>
            <Accounts />
        </Route>
        <Route path={PATHS.settingsTab}>
            <AccountsButton />
            <Preferences />
            <About />
        </Route>
    </Switch>
)

export default SettingsTab