// import axios from 'axios'
import { updateObject } from '../../../../redux/utility'
import { loadFromLocalStorage, saveToLocalStorage } from '../../../../utils/localStorage'
import { loginDisconnect, reconnectToServer } from '../../../../redux/reducers/loginReducer'
import { getSelectedAccount } from './getSelectedAccount'
import { shallowEqual } from '../../../../utils/shallowEqual'
import { createCart, switchCart, deleteCart} from "../../CartTab/cartReducer"

var CryptoJS = require('crypto-js')

/*****************************************************************************/
// Module Data

/*
    MANAGE ACCOUNTS
    -   Load Accounts 
    -   Load selected Account
    -   Update selected Account
*/

const initialState = {
    accounts: [],
    selectedAccount: '',
    error: '',
}

const LOAD_ACCOUNTS_SUCCESS = '/account/LOAD_ACCOUNTS_SUCCESS'
const UPDATE_SELECTED_ACCOUNT = '/account/UPDATE_SELECTED_ACCOUNT'
const CREATE_ACCOUNT_SUCCESS = '/account/CREATE_ACCOUNT_SUCCESS'
const DELETE_ACCOUNT_SUCCESS = '/account/DELETE_ACCOUNT_SUCCESS'
const EDIT_ACCOUNT_SUCCESS = '/account/EDIT_ACCOUNT_SUCCESS'

/*****************************************************************************/
// loadAccounts Action Creators

const loadAccountsSuccess = (accounts) => ({
    type: LOAD_ACCOUNTS_SUCCESS,
    payload: accounts,
})

export const loadAccounts = () => {
    return (dispatch) => {
        var accounts
        var selectedAccount

        try {
            // accounts present in local storage
            accounts = loadFromLocalStorage('accounts')

            // decrypt accounts
            accounts = decryptAccounts(accounts)

            selectedAccount = loadFromLocalStorage('selectedAccount')
            if(selectedAccount === "null"){
                selectedAccount = null
            }
        } catch {
            // accounts not present in local storage!
            console.log('No accounts present in local storage.')
            accounts = []
            selectedAccount = ''
        }

        dispatch(loadAccountsSuccess([accounts, selectedAccount]))
    }
}

/*****************************************************************************/
// updateSelectedAccount Action Creators

export const selectAccountFromList = (accountKey) => {
    return(dispatch) => {
        dispatch(switchCart(accountKey))
        dispatch(updateSelectedAccount(accountKey))
        dispatch(reconnectToServer())
    }
}

export const updateSelectedAccount = (accountKey) => {
   
    saveToLocalStorage('selectedAccount', accountKey)
    return {
        type: UPDATE_SELECTED_ACCOUNT,
        accountKey: accountKey,
    }
}

/*****************************************************************************/
// createAccount Action Creators

const createAccountSuccess = (account) => ({
    type: CREATE_ACCOUNT_SUCCESS,
    payload: account,
})

export const createAccount = (account) => {
    return (dispatch, getState) => {
        // add https
        if (account.hostName.indexOf('https://') <= -1 && account.hostName.indexOf('http://') <= -1) {
            account.hostName = 'https://' + account.hostName
        }

        // Success
        dispatch(createAccountSuccess(account))

        // Update selected account
        dispatch(updateSelectedAccount(account.key))

        // encrypt accounts
        encryptAccounts(Object.values({ ...getState().accountsReducer.accounts }))

        // check if there is only one account
        // if (Object.values({ ...getState().accountsReducer.accounts }).length === 1) {
            // change selected account
            // dispatch(updateSelectedAccount(account.key))
        // }

        let accounts = getState().accountsReducer.accounts
        accounts.length === 1 ? dispatch(reconnectToServer()) : dispatch(loginDisconnect())
        dispatch(createCart(account.key))
    }
}

/*****************************************************************************/
// deleteAccount Action Creators

const deleteAccountSuccess = (accountKey) => ({
    type: DELETE_ACCOUNT_SUCCESS,
    accountKey: accountKey,
})

export const deleteAccount = (accountKey) => {
    return (dispatch, getState) => {
        //Success
        dispatch(deleteAccountSuccess(accountKey))

        // encrypt accounts
        encryptAccounts(Object.values({ ...getState().accountsReducer.accounts }))

        dispatch(deleteCart(accountKey))

        console.log("DELETING ACCOUNT")
        const selectedAccount = getState().accountsReducer.selectedAccount
        if(accountKey === selectedAccount){
            dispatch(updateSelectedAccount(''))
            //Need to disconnect from server!
            console.log("DISCONNECTING FROM SERVER")
            dispatch(loginDisconnect())
        }
    }
}

/*****************************************************************************/
// editAccount Action Creators

const editAccountSuccess = (account) => ({
    type: EDIT_ACCOUNT_SUCCESS,
    payload: account,
})

export const editAccount = (account) => {
    return (dispatch, getState) => {
        const selectedAccount = getSelectedAccount(getState().accountsReducer)

        //Success
        dispatch(editAccountSuccess(account))

        // encrypt accounts
        encryptAccounts(Object.values({ ...getState().accountsReducer.accounts }))

        //Check if something actually changed, that would cause a reconnect.
        if(selectedAccount.key === account.key){
            if(!shallowEqual(selectedAccount, account)){
                //Need to reconnect to server!
                dispatch(reconnectToServer())
            }
        }
    }
}

/*****************************************************************************/
// Reducer Functions

const accountsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_ACCOUNTS_SUCCESS:
            return reduceLoadAccountsSuccess(state, action)
        case UPDATE_SELECTED_ACCOUNT:
            return reduceUpdateSelectedAccount(state, action)
        case CREATE_ACCOUNT_SUCCESS:
            return reduceCreateAccountSuccess(state, action)
        case DELETE_ACCOUNT_SUCCESS:
            return reduceDeleteAccountSuccess(state, action)
        case EDIT_ACCOUNT_SUCCESS:
            return reduceEditAccountSuccess(state, action)
        default:
            return state
    }
}

// ---------- LoadAccounts

const reduceLoadAccountsSuccess = (state, action) => {
    return updateObject(state, { accounts: action.payload[0], selectedAccount: action.payload[1] })
}

// ---------- UpdateSelectedAccount

const reduceUpdateSelectedAccount = (state, action) => {
    return updateObject(state, { selectedAccount: action.accountKey })
}

// ---------- CreateAccount

const reduceCreateAccountSuccess = (state, action) => {
    return updateObject(state, { accounts: [...state.accounts, action.payload] })
}

// ---------- DeleteAccount

const reduceDeleteAccountSuccess = (state, action) => {
    return updateObject(state, { accounts: state.accounts.filter((account) => account.key !== action.accountKey) })
}

// ---------- EditAccount

const reduceEditAccountSuccess = (state, action) => {
    const index = state.accounts.findIndex((account) => account.key === action.payload.key)
    const newArray = [...state.accounts]
    newArray[index] = action.payload
    return updateObject(state, { accounts: newArray })
}

/*****************************************************************************/
// Encryption Functions

const encryptAccounts = (accounts) => {
    var encryptedAccounts = []

    for (var i = 0; i < accounts.length; i++) {
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(accounts[i]), 'DDSENCRYPT').toString()
        encryptedAccounts.push(ciphertext)
    }
    saveToLocalStorage('accounts', JSON.stringify(encryptedAccounts))
}

const decryptAccounts = () => {
    var accounts = loadFromLocalStorage('accounts')

    var decryptedAccounts = []

    for (var i = 0; i < accounts.length; i++) {
        var bytes = CryptoJS.AES.decrypt(accounts[i], 'DDSENCRYPT')
        var decryptedAccount = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        decryptedAccounts.push(decryptedAccount)
    }
    return decryptedAccounts
}

/*****************************************************************************/
export default accountsReducer
