// The Cart class represents the information displayed in the Cart tab.

import makeUuid from '../utils/makeUuid'

import CartDetail from './CartDetail'
import InventoryItem from './InventoryItem'

export class Cart {
    constructor(name) {
        this.uuid = makeUuid() // UUID to distinguish this from others
        this.accountKey = '' // User's account
        this.storeNumber = 0 // Store for which the cart applied
        this.name = name || '' // Name of the cart (for submitting to the POS)
        this.type = 'Retail' // For now, just retail. Eventually there will also be PurchaseOrder
        this.date = new Date(0) // When was the cart created?
        this.posted = false // Was this cart posted? If so, then additional posts must update the existing cart.
        // this.detail = [] // CartDetail objects
        this.details = [] // Recently added 
    }

    addDetail (item, quantity) {
        var i = this.detail.length
        this.detail[i] = new CartDetail(item, quantity)
        return this.detail[i]
    }

    // addCompactDetail (item, quantity) {
    //     this.detail[this.detail.length] = {
    //         barcode: barcode,
    //         quantity: quantity
    //     }
    // }

    // deleteDetail (item) {
    //     var matchingDetail = this.detail.findIndex((detail) {
    //         return detail.item.barcode === item.barcode
    //     })

    //     if (matchingDetail >= 0) {
    //         this.detail.splice(matchingDetail, 1)
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    empty () {
        this.detail = []   //this.detail.length = 0 // This retained the original object reference, which was sometimes inadvertatently shared with other cart objects.
    }

    getDetail (parm) {
        var self = this
        if (typeof parm === 'object') {
            if (parm.barcode) {
                return self.getDetail(parm.barcode)
            } else if (parm.item.barcode) {
                return self.getDetail(parm.item.barcode)
            }
        } else {
            for (var i = 0; i < self.detail.length; i++) {
                if (self.detail[i].item.barcode === parm) {
                    return self.detail[i]
                }
            }
        }
    }

    copyFromCart (cartToCopy) {
        if (cartToCopy === this) {
            //console.log('copyFromCart/source === target')
            return
        }
        var source = cartToCopy //JSON.parse(JSON.stringify(cartToCopy))
        var target = this

        target.uuid = source.uuid
        target.accountKey = source.accountKey
        target.storeNumber = source.storeNumber
        target.name = source.name
        target.type = source.type

        //console.log('copyFromCart/before empty: source.details=' + source.detail.length)
        target.empty()
        //console.log('copyFromCart/after empty: source.details=' + source.detail.length)
        for (var item, i = 0; i < source.detail.length; i++) {
            item = new InventoryItem()
            item.copyFromItem(source.detail[i].item)
            target.addDetail(item, source.detail[i].quantity)
        }
        //console.log('copyFromCart: target.details=' + target.detail.length)
    }

    copyToGrid (grid, manufacturer) {
        var self = this
        for (var d = 0; d < self.detail.length; d++) {
            if (!manufacturer || self.detail[d].item.manufacturer === manufacturer) {
                grid.addDetail(self.detail[d].item, self.detail.quantity)
            }
        }
    }

    // Return flattened version of the cart, or display in "list" components.
    flattenDetail () {
        var self = this
        var flat = []
        for (var d = 0; d < self.detail.length; d++) {
            flat[d] = self.detail[d].flatten()
        }
        return flat
    }
}

export default Cart