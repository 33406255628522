import { Preferences } from '../../../../classes/Preferences'
import { updateObject } from '../../../../redux/utility'
import { loadFromLocalStorage, saveToLocalStorage } from '../../../../utils/localStorage'

/*****************************************************************************/
// Module Data

/*
    MANAGE PREFERENCES
    -   Load Preferences
    -   Update Preferences
*/

const initialState = {
    preferences: new Preferences(),
    error: '',
}

const LOAD_PREFERENCES_SUCCESS = '/preferences/LOAD_PREFERENCES_SUCCESS'
const UPDATE_PREFERENCES_SUCCESS = '/preferences/UPDATE_PREFERENCES_SUCCESS'

/*****************************************************************************/
// loadPreferences Action Creators

function loadPreferencesSuccess(preferences) {
    return {
        type: LOAD_PREFERENCES_SUCCESS,
        payload: preferences,
    }
}

//Load the preferences from the local storage
export const loadPreferences = () => {
    return function (dispatch) {
        var preferences = loadFromLocalStorage('preferences')

        // preferences are not yet in local storage
        if (preferences === null) {
            preferences = new Preferences()
            saveToLocalStorage('preferences', preferences)
        }

        dispatch(loadPreferencesSuccess(preferences))
    }
}

/*****************************************************************************/
// updatePreferences Action Creators

export const updatePreferences = (preferences) => {
    saveToLocalStorage('preferences', preferences)
    return {
        type: UPDATE_PREFERENCES_SUCCESS,
        payload: preferences,
    }
}

/*****************************************************************************/
// Reducer Functions

const preferencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PREFERENCES_SUCCESS:
            return reduceLoadPreferencesSuccess(state, action)
        case UPDATE_PREFERENCES_SUCCESS:
            return reduceUpdatePreferencesSuccess(state, action)
        default:
            return state
    }
}

// ---------- LoadPreferences
const reduceLoadPreferencesSuccess = (state, action) => {
    return updateObject(state, { preferences: action.payload })
}

// ---------- UpdatePreferences
const reduceUpdatePreferencesSuccess = (state, action) => {
    return updateObject(state, { preferences: action.payload })
}

/*****************************************************************************/
export default preferencesReducer
