import {  createMuiTheme, responsiveFontSizes  } from '@material-ui/core/styles';

let mainTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#307ABC"
        }
    }
});

mainTheme = responsiveFontSizes(mainTheme)

export default mainTheme