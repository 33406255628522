import React from 'react'
import { MenuItem, TextField } from '@material-ui/core'
import { Field, ErrorMessage  } from 'formik'

const FormikSelect = ({ name, fullWidth, items, isValid, required = false, label, onClick }) => {
    return (
        <Field
            name={name}
            as={TextField}
            label={label}
            select
            helperText={<ErrorMessage name={name} />}
            fullWidth={fullWidth}
            required={required}
            error={isValid}
        >
            {items.map((item) => (
                <MenuItem key={item.value} value={item.value} onClick={()=> onClick ? onClick(item.value) : null}>
                    {item.label}
                </MenuItem>
            ))}
        </Field>
    )
}

export default FormikSelect
