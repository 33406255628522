import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import TabPanel from '../../../hoc/TabPanel/TabPanel'
import DashboardTab from '../DashboardTab/DashboardTab'
import ItemsTab from '../ItemsTab/ItemsTab'
import CartTab from '../CartTab/CartTab'
import SettingsTab from '../SettingsTab/SettingsTab'
import { ThemeProvider } from '@material-ui/core/styles'
import mainTheme from '../../../themes/mainTheme'
import Toast from '../../Toast/Toast'
import { connect } from 'react-redux'
import { setTab, TABBAR_TAB } from './tabBarReducer'
import useInterval from '../../../utils/useInterval'
import { LOGIN_MODE, login } from '../../../redux/reducers/loginReducer'
import {
    DashboardIcon,
    StoreIcon,
    ItemsIcon,
    CartIcon, CartFilledIcon,
    SettingsIcon,
} from '../../icons'

const a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
})

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#f9f9f9',
    },
    indicator: {
        backgroundColor: '#fecf48',
        top: 0,
    },
    appBar: {
        backgroundColor: '#307ABC',
        boxShadow: 'none',
        margin: '0',
        height: '72px',
    },
    tab: {
        fontSize: '0.7em',
    }
}))

export const TabBar = props => {
    const classes = useStyles()
    const history = useHistory()
    console.log('TabBar Props', props, history)

    useInterval(() => {
        if (!props.preferences.keepLoggedIn) return
        if (props.loginState.mode !== LOGIN_MODE.authenticated) return
        props.login() // history parm omitted, which signals (far down the chain) that toast is unwanted. I don't like this, and perhaps we can come up with a better way later.
    }, 60000)

    const handleChange = (event, newTab) => {
        props.setTab(newTab, history)
    }

    const dashboardLabel = () => (props.loginState.isRetailUser
        ? 'Store'
        : 'Dashboard'
    )

    const dashboardIcon = () => (props.loginState.isRetailUser
        ? <StoreIcon />
        : <DashboardIcon />
    )

    const cartLabel = () => (props.cart.details?.length > 0
        ? 'Cart (' + props.cart.details.length + ')'
        : 'Cart'
    )

    const cartIcon = () => (props.cart.details?.length > 0
        ? <CartFilledIcon />
        : <CartIcon />
    )

    const cartHidden = () => (props.loginState.isRetailUser || !props.loginState.cartEnabled)
    console.log('cartHidden', props.loginState.isRetailUser, props.loginState.cartEnabled, cartHidden())

    const tabs = { ...TABBAR_TAB, current: props.tab }
    // console.log('tabs/A', tabs)
    // if (cartHidden()) {
    //     if (tabs.current > tabs.cart) tabs.current--
    //     tabs.settings = tabs.cart
    //     tabs.cart = null
    // }
    // console.log('tabs/B', tabs)

    return (
        <ThemeProvider theme={mainTheme}>
            <Route path='/'>
                <div className={classes.root}>
                    <AppBar
                        postition='fixed'
                        className={classes.appBar}
                    >
                        <Tabs
                            value={tabs.current}
                            onChange={handleChange}
                            variant='fullWidth'
                            aria-label='application tabs'
                            classes={{
                                indicator: classes.indicator,
                            }}
                        >
                            <Tab
                                label={dashboardLabel()}
                                index={tabs.dashboard}
                                icon={dashboardIcon()}
                                className={classes.tab}
                                disabled={props.disabledDashboard}
                                {...a11yProps(tabs.dashboard)}
                            />
                            <Tab
                                label='Items'
                                index={tabs.items}
                                icon={<ItemsIcon />}
                                className={classes.tab}
                                disabled={props.disabledItems}
                                {...a11yProps(tabs.items)}
                            />
                            {/* {tabs.cart && */}
                                <Tab
                                    label={cartLabel()}
                                    index={tabs.cart}
                                    icon={cartIcon()}
                                    className={classes.tab}
                                    disabled={props.disabledCart || cartHidden()}
                                    {...a11yProps(tabs.cart)}
                                />
                            {/* } */}
                            <Tab
                                label='Settings'
                                index={tabs.settings}
                                icon={<SettingsIcon />}
                                className={classes.tab}
                                disabled={props.disabledSettings}
                                {...a11yProps(tabs.settings)}
                            />
                        </Tabs>
                    </AppBar>
                    {/* <Toolbar />  This makes the toolbar "sticky", so that the content isn't covered by the toolbar */}

                    <TabPanel value={tabs.current} index={tabs.dashboard}>
                        <DashboardTab />
                    </TabPanel>

                    <TabPanel value={tabs.current} index={tabs.items}>
                        <ItemsTab />
                    </TabPanel>

                    {/* {tabs.cart && */}
                        <TabPanel value={tabs.current} index={tabs.cart}>
                            <CartTab />
                        </TabPanel>
                    {/* } */}

                    <TabPanel value={tabs.current} index={tabs.settings}>
                        <SettingsTab />
                    </TabPanel>
                </div>
                <Toast />
            </Route>
        </ThemeProvider>
    )
}

const mapStateToProps = (state) => {
    return {
        tab: state.tabBarReducer.tab,
        disabledDashboard: state.tabBarReducer.disabledDashboard,
        disabledItems: state.tabBarReducer.disabledItems,
        disabledCart: state.tabBarReducer.disabledCart,
        disabledSettings: state.tabBarReducer.disabledSettings,
        cart: state.cartReducer.cart,
        loginState: state.loginReducer,
        preferences: state.preferencesReducer.preferences,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTab: (newTab, history) => dispatch(setTab(newTab, history)),
        login: (history) => dispatch(login(history)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabBar)
