import React from 'react';
import { connect } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Button, Typography, IconButton, makeStyles, ButtonGroup, useMediaQuery, useTheme } from '@material-ui/core'
import Quantity from "../Quantity/Quantity"
import { BackTitleIcon } from '../icons'
import { Icon } from '@iconify/react'
import EditIcon from '@iconify-icons/ic/baseline-edit'
import { cartSetItemQuantity, getItemQuantity } from '../Tabs/CartTab/cartReducer'
import "./SizeColorGridDialog.css"
import { showToast } from "../Toast/toastReducer"
import DialogLoading from "../../hoc/Loading/DialogLoading"
import OkDialogButton from "../../hoc/Buttons/OkDialogButton"

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "1px",
        margin: "0px"
    },
    buttonGroupSmall: {
        height: "30px",
        width: "25px",
        minWidth: "25px"
    },
    editIconButton: {
        minWidth: "40px",
        maxWidth: "40px",
        height: "40px"
    },
    mobileQuantity: {
        minWidth: "30px",
        minHeight: "30px",
        width: "30px",
        height: "30px"
    }
}))

const SizeColorGridDialog = (props) => {

    const classes = useStyles()

    const [browseValue, setBrowseValue] = React.useState(-1)
    const [value, confirmValue] = React.useState(1)
    const [renderValue, setRenderValue] = React.useState(0)
    const [dictionary, setDictionary] = React.useState({})
    const [selectedItem, setSelectedItem] = React.useState({})

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    React.useEffect(() => {
        setSelectedItem(props.sizeColorGridDefaultItem)
        setDictionary(props.sizeColorGrid)
    }, [props.sizeColorGridDefaultItem, props.sizeColorGrid, props.sizeColorGridLoading])

    const handleClose = () => props.closeDialog();

    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const confirmBrowseValue = (e) => {
        setBrowseValue(e)
        confirmValue(e)
    }

    const handleBrowseValueClick = () => {
        if (browseValue !== -1) {
            confirmValue(browseValue)
        }
    }

    const updateDict = (size, color) => {

        var copy_dict = dictionary
        var existingValue = dictionary[size][color].count
        var item = dictionary[size][color].item
        setSelectedItem(item)

        if (existingValue === value) {
            if (!(value === 0 || existingValue === 0)) {
                props.showToast("Value removed from cart", "warning", 1500)
            }
            copy_dict[size][color].count = 0
            props.setItemQuantity(item, 0)
        }
        else {
            if (value === 0) {
                props.showToast("Value removed from cart", "warning", 1500)
            }
            else if (existingValue === 0) {
                props.showToast("Value added to cart", "success", 1500)
            }
            else {
                props.showToast("Cart value updated", "info", 1500)
            }
            copy_dict[size][color].count = value
            props.setItemQuantity(item, value)
        }
        setDictionary(copy_dict)
        setRenderValue(renderValue + 1)
    }

    const handleGridClick = (size, color) => {
        if (dictionary[size][color].count !== null) {
            updateDict(size, color)
        }
    }

    const RenderTable = () => {

        if (props.loading || dictionary === {}) {
            return <div></div>
        }

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            className={fullScreen ? "SCG-tableHeadStaticMobile" : "SCG-tableHeadStatic"}
                            align={'center'}
                            size={"small"}
                        >
                            Size
                        </TableCell>
                        {Object.keys(dictionary[Object.keys(dictionary)[0]]).map((color) => (
                            <TableCell
                                className={fullScreen ? "SCG-tableHeadMobile" : "SCG-tableHead"}
                                align={'center'}
                                key={color}
                                size={"small"}
                            >
                                {color}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {Object.keys(dictionary).map((size) => (
                        <TableRow key={size}>
                            <TableCell
                                align="center"
                                className={fullScreen ? "SCG-leftColumnMobile" : "SCG-leftColumn"}
                                size="small"
                            >
                                {size}
                            </TableCell>
                            {Object.keys(dictionary[Object.keys(dictionary)[0]]).map((color) => (
                                <TableCell
                                    style={dictionary[size][color].count > 0 ? { fontWeight: 'bold', backgroundColor: "rgba(48,122,188,0.25)" } : null}
                                    className={fullScreen ? "SCG-tableCellMobile" : "SCG-tableCell"}
                                    onClick={() => handleGridClick(size, color)}
                                    align="center"
                                    key={color}
                                    size={"small"}
                                >
                                    {dictionary[size][color].count == null ? "-" : dictionary[size][color].count}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    const NumberSelector = () => {
        return (
            <Grid item xs={12} style={{ minWidth: "100%", display: "flex", justifyContent: "center" }}>
                <ButtonGroup size="small" classes={fullScreen ? { grouped: classes.buttonGroupSmall } : null} className={"SCG-ButtonGroup"}>

                    {/* 0-10 */}
                    {numbers.map((number) => (
                        <Button
                            key={number}
                            onClick={() => confirmValue(number)}
                            className={number === value ? "SCG-activeButton" : "SCG-button"}
                        >
                            {number === value ? <b style={{ color: 'white' }}>{number}</b> : <div>{number}</div>}
                        </Button>
                    ))}

                    {/* Quantity Value */}
                    {browseValue > 10 ?
                        <Button
                            className={browseValue === value ? "SCG-activeButton" : "SCG-button"}
                            onClick={handleBrowseValueClick}
                            // style={fullScreen ? null : {marginLeft: "20px"}}
                            classes={fullScreen ? { root: classes.mobileQuantity } : null}
                        >
                            <div style={browseValue === value ? { color: "white" } : null}>
                                {browseValue}
                            </div>
                        </Button>
                        :
                        null
                        // <Quantity confirmValue={confirmBrowseValue}>
                        //     <Button 
                        //         className={"SCG-button"}
                        //         classes={fullScreen ? {root:classes.mobileQuantity} : {root: classes.editIconButton}} 
                        //         style={!fullScreen ? {marginLeft: "20px", backgroundColor: 'rgba(0, 0, 0, 0.10)'} : null}
                        //     >
                        //         -
                        //     </Button>
                        // </Quantity>
                    }

                    {/* Quantity Icon */}
                    <Quantity initialValue={value} confirmValue={confirmBrowseValue}>
                        <Button
                            className={"SCG-button"}
                            classes={fullScreen ? { root: classes.mobileQuantity } : { root: classes.editIconButton }}>
                            <Icon icon={EditIcon} />
                        </Button>
                    </Quantity>
                </ButtonGroup>
            </Grid>
        )
    }

    return (

        <Dialog onClose={handleClose} open={props.sizeColorGridOpen} disableScrollLock={true} fullScreen={fullScreen}>

            {/* Make it appear as tab on mobile! */}
            {!fullScreen ?
                // Dialog Title
                <DialogTitle>Size/Color Grid</DialogTitle>
                :
                //Top Bar
                <div className={"row"}>
                    <IconButton onClick={handleClose}>
                        <BackTitleIcon />
                    </IconButton>
                    <DialogTitle>Size/Color Grid</DialogTitle>
                    <div style={{ width: "50px" }} />
                </div>
            }

            <DialogContent dividers classes={fullScreen ? { root: classes.root } : {}}>

                {!props.loading ?
                    <React.Fragment>
                        <Grid container spacing={fullScreen ? 0 : 2} style={{ padding: "10px", margin: 0 }}>

                            <Grid item xs={12}>
                                <Typography variant={fullScreen ? "body1" : "h6"}><b>{selectedItem.itemTitle}</b></Typography>
                            </Grid>

                            {selectedItem.isStyle ?
                                <React.Fragment>
                                    <Grid item xs={2}><b>Size</b></Grid>
                                    <Grid item xs={10}>{selectedItem.size}</Grid>
                                    <Grid item xs={2}><b>Color</b></Grid>
                                    <Grid item xs={10}>{selectedItem.thisColor} - {selectedItem.color}</Grid>
                                </React.Fragment>
                                : null}

                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <b>Pick quantity, then click grid cell:</b>
                            </Grid>
                            <NumberSelector />
                        </Grid>

                        <TableContainer className={fullScreen ? "SGC-tableContainerMobile" : "SCG-tableContainer"}>
                            <RenderTable />
                        </TableContainer>
                    </React.Fragment>
                    :
                    <DialogLoading />
                }

            </DialogContent>

            {!fullScreen ?
                <DialogActions>
                    <OkDialogButton onClick={handleClose}/>
                </DialogActions>
                : null}

        </Dialog>
    )
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
    sizeColorGrid: state.itemReducer.sizeColorGrid,
    loading: state.itemReducer.sizeColorGridLoading,
    sizeColorGridOpen: state.itemReducer.sizeColorGridOpen,
    sizeColorGridDefaultItem: state.itemReducer.sizeColorGridDefaultItem
})

const mapDispatchToProps = dispatch => ({
    setItemQuantity: (item, quantity) => { dispatch(cartSetItemQuantity(item, quantity)) },
    getItemQuantity: (cart, itemOrBarcode) => { dispatch(getItemQuantity(cart, itemOrBarcode)) },
    showToast: (message, severity, duration) => { dispatch(showToast(message, severity, duration)) },

})

export default connect(mapStateToProps, mapDispatchToProps)(SizeColorGridDialog)
