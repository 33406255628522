import React, { useState, useEffect } from 'react'
import AddAccountDialog from './AddAccountDialog/AddAccountDialog'
import { connect } from 'react-redux'
import { getSelectedAccount } from '../getSelectedAccount'

const AddAccount = (props) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        console.log('AddAccount useEffect', props)
        if (props.loginSelectingAccount && !getSelectedAccount(props.accountsReducer)) {
            handleClickOpen()
        }
    })

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        props.onClose()
    }

    return <AddAccountDialog isOpen={props.isOpen || open} onClose={handleClose} />
}

const mapStateToProps = (state) => {
    return {
        loginSelectingAccount: state.loginReducer.selectingAccount,
        accountsReducer: state.accountsReducer,
    }
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         setTab: (newTab, history) => dispatch(setTab(newTab, history)),
//     }
// }

export default connect(mapStateToProps, null)(AddAccount)
