import React from 'react'
import MenuButtonWithChildren from '../../../../hoc/MenuButtonWithChildren/MenuButtonWithChildren'
import { PreferencesIcon } from '../../../icons'
import PreferencesDialog from './PreferencesDialog/PreferencesDialog'
import { PATHS } from '../../../../paths'

export default () => (
    <MenuButtonWithChildren
        buttonText='Preferences'
        buttonIcon={PreferencesIcon}
        childPath={PATHS.preferences}
    >
        <PreferencesDialog />
    </MenuButtonWithChildren>
)