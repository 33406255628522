import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { connect } from 'react-redux'
import { hideToast } from './toastReducer'

const Alert = props => (
    <MuiAlert elevation={6} variant="filled" {...props} />
)

const Toast = props => {
    console.log('Toast Props', props)

    const handleClose = (event, reason) => {
        if (reason !== 'clickaway') {
            props.hideToast()
        }
    }

    return (
        <Snackbar open={props.open} autoHideDuration={props.duration} onClose={handleClose}>
            <Alert onClose={handleClose} severity={props.severity}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}

const mapStateToProps = (state) => {
    return {
        open: state.toastReducer.open,
        message: state.toastReducer.message,
        severity: state.toastReducer.severity,
        duration: state.toastReducer.duration,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideToast: () => dispatch(hideToast()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toast)