import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import FormikField from '../../../../../hoc/Formik/FormikField'
import FormikSelect from '../../../../../hoc/Formik/FormikSelect'
import { Grid, Typography, DialogContent, DialogActions } from '@material-ui/core'
import { createAccount, editAccount } from '../accountsReducer'
import FormSwitch from '../../../../FormSwitch/FormSwitch'
import fixHttp from "../../../../../utils/fixHttp"
import { useHistory } from 'react-router-dom'
import { login, reconnectToServer, LOGIN_MODE } from "../../../../../redux/reducers/loginReducer"
import DialogLoading from "../../../../../hoc/Loading/DialogLoading"
import DialogButton from "../../../../../hoc/Buttons/DialogButton"
import OkDialogButton from "../../../../../hoc/Buttons/OkDialogButton"
import CancelDialogButton from "../../../../../hoc/Buttons/CancelDialogButton"

/* Helper Functions */
// const isUrl = (url) => {
//     var regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
//     return regexp.test(url.toLowerCase())
//     // return true
// }

const hasHttp = (url) => {
    var regexp = /(http|https):\/\//
    return regexp.test(url.toLowerCase())
}

const AccountForm = (props) => {

    const { storesFromAccountDialog, type, mode, accounts, createAccount, editAccount, onSubmit, login, reconnectToServer, onClose } = props;
    let history = useHistory()
    let update = null

    const [allStores, setAllStores]             = React.useState(false)
    const [stores, setStores]                   = React.useState([])
    const [optionsActive, setOptionsActive]     = React.useState(false)
    const [displayOptions, setDisplayOptions]   = React.useState(false)
    const [initialValues, setInitialValues]     = React.useState(null)

    /* Add or Edit? */
    const loadValues = () => {
        var add = (type === 'add')
        var key = Math.random().toString(36).substring(7)
        var account = {
            accountName:            add ? ''  : props.account.accountName,
            hostName:               add ? ''  : props.account.hostName,
            userName:               add ? ''  : props.account.userName,
            password:               add ? ''  : props.account.password,
            key:                    add ? key : props.account.key,
            dashStoreNumber:        add ? 0   : props.account.dashStoreNumber,
            inventoryStoreNumber:   add ? 1   : props.account.inventoryStoreNumber,
            priceStoreNumber:       add ? 1   : props.account.priceStoreNumber
        }
        setAllStores(account.dashStoreNumber === 0)
        return(account)
    }

    const updateStores = () => {
        if(storesFromAccountDialog.length >= 1){
            setStores(loadStores(storesFromAccountDialog))
        }
    }

    const changeValuesFromNull = () => {
        if(initialValues === null){
            setInitialValues(loadValues())
        }
    }

    const updateObjectBasedOnType = () => {
         if(type === 'edit'){
            setOptionsActive(true)
        }
    }

    React.useEffect(updateStores, [storesFromAccountDialog])
    React.useEffect(changeValuesFromNull, [initialValues])
    React.useEffect(updateObjectBasedOnType, [type])

    React.useEffect(()=>{
        if(mode === LOGIN_MODE.credentialError){
            setDisplayOptions(false)
            setOptionsActive(false)
        }
    }, [mode])

    /* AccountName */
    const validateAccountName = (accountName) => {

        //Check if the accountName is being used already 
        for (var i = 0; i < accounts.length; i++) {
            if (accounts[i].accountName === accountName && type !== 'edit') {
                return 'An account already has that name.'
            }
        }

        //Empty account name
        if (!accountName) {
            return 'Please enter an Account Name.'
        }

        //Check for invalid characters
        //eslint-disable-next-line
        if (accountName.search(/[\/?]/) > 0) {
            return 'Your account name cannot contain any of the following characters "/,?".'
        }
    }

    /* HostName */
    const validateHostName = (hostName) => {
        //Empty host name
        if (!hostName) {
            return 'Please enter a Host Name.'
        }
    }

    /* UserName */
    const validateUserName = (userName) => {
        //Empty user name
        if (!userName) {
            return 'Please enter a User Name.'
        }
    }

    /* Password */
    const validatePassword = (password) => {
        //Empty password
        if (!password) {
            return 'Please enter a password.'
        }
    }

    //Validate all fields. Collect errors (if any).
    const validate = (values) => {

        //Check for errors.
        let errors = {}

        // if(values.accountName.toLowerCase() === "demoowner"){
        //     update('hostName', 'https://shop.theuniformsolution.com')
        //     update('userName', 'Owner')
        //     update('password', 'Master')
        //     update('key', 'TEST1')
        // }

        // if(values.accountName.toLowerCase() === "democlerk"){
        //     update('hostName', 'https://shop.theuniformsolution.com')
        //     update('userName', 'Clerk')
        //     update('password', 'OK')
        //     update('key', 'TEST2')
        // }

        if (validateAccountName(values.accountName)) {
            errors.accountName = validateAccountName(values.accountName)
        }

        if (validateHostName(values.hostName)) {
            errors.hostName = validateHostName(values.hostName)
        }

        if (validateUserName(values.userName)) {
            errors.userName = validateUserName(values.userName)
        }

        if (validatePassword(values.password)) {
            errors.password = validatePassword(values.password)
        }
        
        return errors
    }

    const handleSubmit = (values) => {

        if (allStores && values.dashStoreNumber !== 0) {
            update('dashStoreNumber', 0);
        } else {
            if (values.dashStoreNumber === '') {
                update('dashStoreNumber', 1);
            }
        }
        (type === 'add') ? createAccount(values) : editAccount(values)
        if(props.onSubmit){props.onSubmit()}
    }

    const createLabel = (store) => {
        return(
            <div>
                <div><b>{store.Store + ''} #{store.StoreNumber}</b></div>
                <div>{store.StoreAddress}</div>
                <div>{store.StoreCity}, {store.StoreState} {store.StorePostalCode}</div>
                <div>{store.StorePhone}</div>
            </div>
        )
    }

    const loadStores = (stores) => {
        var formattedStores = []
        for(var store of stores){
            formattedStores.push({value: store.StoreNumber, label: createLabel(store)})
        }
        return formattedStores
    }

    const handleAllStores = () => {
        setAllStores(!allStores)
    }

    //Update dashStoreNumber when allStores changes
    const setDashStoreNumber = () => {
         if(initialValues !== null){
            update('dashStoreNumber', allStores ? 0 : 1)
        }
    }
    React.useEffect(setDashStoreNumber, [allStores]);

    const handleOptions = (account) => {
        login(history, account, '', true)
        setDisplayOptions(true)
    }

    const handleBack = () => {
        setDisplayOptions(false)
        console.log("%c AccountForm is calling reconnect", 'color: yellow;')
        reconnectToServer()
    }

    const updateDashStoreNumber         = (value) => update('dashStoreNumber', value)
    const updateInventoryStoreNumber    = (value) => update('inventoryStoreNumber', value)
    // const updatePriceStoreNumber        = (value) => update('priceStoreNumber', value)

    const attemptToFixURL = (hostName) => {
        const doneCallback = (data) => {
            update("hostName", data)
            setOptionsActive(true)
        }

        const failCallback = (data) => {
            update("hostName", data)
            setOptionsActive(true)
        }

        if(!hasHttp(hostName)){
            fixHttp(hostName, doneCallback, failCallback)
        }
    }

    return (
        <React.Fragment>
        {initialValues === null ? <DialogLoading/> :
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate} validateOnBlur={true} validateOnChange={true}>
            {({ dirty, isValid, errors, touched, setFieldValue, values}) => {
                update = setFieldValue
                return (
                    <Form>
                        <DialogContent dividers>
                        {!displayOptions ? (
                            <Grid container spacing={1}>

                                {/* ACCOUNT NAME */}
                                <Grid item xs={4} container alignItems="center">
                                    <Typography variant='body1'>Account Name</Typography>
                                </Grid>
                                <Grid item xs={8} container alignItems="center">
                                    <FormikField
                                        name='accountName'
                                        isValid={errors.accountName && touched.accountName}
                                        required={true}
                                        disabled={type === 'add' ? false : true}
                                    />
                                </Grid>
                                
                                {/* WEB SERVER TITLE */}
                                <Grid item xs={12}>
                                    <Typography variant='h6' color='primary'>
                                        Web Server
                                    </Typography>
                                </Grid>

                                {/* HOSTNAME/DOMAIN */}
                                <Grid item xs={4} container alignItems="center">
                                    <Typography variant='body1'>Domain or IP</Typography>
                                </Grid>
                                <Grid item xs={8} container alignItems="center">
                                    <FormikField
                                        name='hostName'
                                        isValid={errors.hostName && touched.hostName}
                                        required={true}
                                        onBlur={()=>attemptToFixURL(values.hostName)}
                                    />
                                </Grid>
                                
                                {/* USER LOGIN TITLE */}
                                <Grid item xs={12}>
                                    <Typography variant='h6' color='primary'>
                                        User Login
                                    </Typography>
                                </Grid>

                                {/* USERNAME */}
                                <Grid item xs={4} container alignItems="center">
                                    <Typography variant='body1'>Username</Typography>
                                </Grid>
                                <Grid item xs={8} container alignItems="center">
                                    <FormikField
                                        name='userName'
                                        isValid={errors.userName && touched.userName}
                                        required={true}
                                    />
                                </Grid>

                                {/* PASSWORD */}
                                <Grid item xs={4} container alignItems="center">
                                    <Typography variant='body1'>Password</Typography>
                                </Grid>
                                <Grid item xs={8} container alignItems="center">
                                    <FormikField
                                        name='password'
                                        isValid={errors.password && touched.password}
                                        required={true}
                                        type='password'
                                    />
                                </Grid>

                            </Grid>
                        ) : (
                            <Grid container spacing={1}>
                                {(stores.length < 1) ?
                                    <DialogLoading/>
                                    :
                                    <React.Fragment>
                                        {/* DASHBOARD TITLE */}
                                        <Grid item xs={12}>
                                            <Typography variant='h6' color='primary'>
                                                Dashboard
                                            </Typography>
                                        </Grid>
                                        
                                        {/* ALL STORES SWITCH */}
                                        <Grid item xs={12}>
                                            <FormSwitch
                                                onChange={handleAllStores}
                                                value={allStores}
                                                name='allStores'
                                                label='All Stores'
                                            />
                                        </Grid>

                                        {/* DASHSTORENUMBER*/}
                                        {!allStores && (
                                            <Grid item xs={12}>
                                                <FormikSelect
                                                    name='dashStoreNumber'
                                                    label='Store'
                                                    items={stores}
                                                    isValid={errors.dashStoreNumber && touched.dashStoreNumber}
                                                    fullWidth={true}
                                                    onClick={updateDashStoreNumber}
                                                />
                                            </Grid>
                                        )}
                                        
                                        {/* INVENTORY AND PRICING TITLE */}
                                        <Grid item xs={12}>
                                            <Typography variant='h6' color='primary'>
                                                Inventory and Pricing
                                            </Typography>
                                        </Grid>
                                        
                                        {/* INVENTORY STORE NUMBER */}
                                        <Grid item xs={12}>
                                            <FormikSelect
                                                name='inventoryStoreNumber'
                                                label='Store'
                                                items={stores}
                                                isValid={errors.inventoryStoreNumber && touched.inventoryStoreNumber}
                                                fullWidth={true}
                                                onClick={updateInventoryStoreNumber}
                                            />
                                        </Grid>
                                    </React.Fragment>}
                            </Grid>
                        
                        )}
                        </DialogContent>

                        <DialogActions>
                            {!displayOptions ? 
                                <React.Fragment>
                                    <DialogButton disabled={!optionsActive} onClick={()=>handleOptions(values)}>
                                        Options
                                    </DialogButton>
                                    <OkDialogButton type='submit'  onClick={onClose} disabled={!isValid}/>
                                    <CancelDialogButton onClick={onClose}/>
                                </React.Fragment> 
                                :
                                <React.Fragment>
                                    <DialogButton onClick={handleBack}>
                                        Back
                                    </DialogButton>
                                </React.Fragment>
                            }
                        </DialogActions>
                    </Form>
                )
            }}
        </Formik>}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        accounts: state.accountsReducer.accounts,
        storesFromAccountDialog: state.storesReducer.storesFromAccountDialog,
        mode: state.loginReducer.mode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createAccount: (account) => dispatch(createAccount(account)),
        editAccount: (account) => dispatch(editAccount(account)),
        login: (history, editedAccount, reasonCodeAddition, limitToLogin1) => dispatch(login(history, editedAccount, reasonCodeAddition, limitToLogin1)),
        reconnectToServer: () => dispatch(reconnectToServer())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountForm)