import React, { useRef } from 'react'
import '../../../../App/App.css'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Scanner from './Scanner'
import MuiAlert from '@material-ui/lab/Alert'
import { Dialog, DialogContent, DialogActions, Snackbar, useTheme, useMediaQuery } from '@material-ui/core'
import { itemFetch } from '../../Item/itemReducer'
import CancelDialogButton from '../../../../../hoc/Buttons/CancelDialogButton'


const ScanItemDialog = (props) => {

    const history = useHistory()

    //Store code from scanner
    const [code, setCode] = React.useState('')

    //Display alert
    const [alertOpen, setAlertOpen] = React.useState(false)

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'))

    //Initialize scannerReference
    const scannerRef = useRef(null)

    //Return Material UI Alert
    const Alert = (props) => <MuiAlert elevation={6} variant='filled' {...props} />
    
    //Close the Alert
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') { return }
        setAlertOpen(false)
    }

    return (
        <Dialog
            fullScreen={mobile}
            open={true}
            onClose={props.onClose}
            aria-labelledby={'scanitem-dialog-title'}
            aria-describedby={'scanitem-dialog-description'}
        >
            <DialogContent id='barcodeScannerDialog'>

                <div id={'barcodeScannerTopTintBar'}/>
                <div id={'barcodeScannerLeftTintBar'}/>
                <div id={'barcodeScannerRightTintBar'}/>
                <div id={'barcodeScannerMiddleArea'}/>
                <div id={'barcodeScannerBottomTintBar'}/>
                
                <div ref={scannerRef} id='#barcode-scanner' className={'scannerRef'}>
                    <canvas className='drawingBuffer' />
                </div>

                <Scanner
                    scannerRef={scannerRef}
                    onDetected={(result) => {
                        setCode(result)
                        props.processCode(result)
                        if (props.singleCode) {
                            props.itemFetch(result, history, false, true)
                        }
                    }}
                />
            </DialogContent>

            {/* Close Dialog */}
            {mobile ? (
                <DialogActions>
                    <CancelDialogButton onClick={props.onClose} id={'mobileBarcodeScannerCancelButton'}/>
                </DialogActions>
            ) : (
                <React.Fragment>
                    <CancelDialogButton onClick={props.onClose} id={'desktopBarcodeScannerCancelButton'}/>
                </React.Fragment>
            )}

            {/* Used for alerts */}
            <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity='success'>
                    {code}
                </Alert>
            </Snackbar>
        </Dialog>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        itemFetch: (id, history, showInactive, scanned) => dispatch (itemFetch(id, history, showInactive, scanned))
    }
}

export default connect(null, mapDispatchToProps)(ScanItemDialog)