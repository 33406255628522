import React, { useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import './App.css'
import TabBar from '../Tabs/TabBar/TabBar'
import { connect } from 'react-redux'
import { loadAccounts } from '../Tabs/SettingsTab/Accounts/accountsReducer'
import { loadPreferences } from '../Tabs/SettingsTab/Preferences/preferencesReducer'
import { LOGIN_MODE, login } from '../../redux/reducers/loginReducer'
import { loadCart } from '../Tabs/CartTab/cartReducer'

const App = (props) => {
    let history = useHistory()

    const { loadAccounts, loadPreferences, loadCart, login, loginMode } = props;

    const relogin = () => {
        if (loginMode === LOGIN_MODE.disconnected) {
            login(history)
        }
    }

    useEffect(loadAccounts, [])
    useEffect(loadPreferences, [])
    useEffect(loadCart, [])
    useEffect(relogin, [loginMode])

    return (
        <Fragment>
            <CssBaseline />
            <div className='App'>
                <TabBar />
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        name: state.accountsReducer.name,
        loginMode: state.loginReducer.mode,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadAccounts: () => dispatch(loadAccounts()),
        loadPreferences: () => dispatch(loadPreferences()),
        loadCart: () => dispatch(loadCart()),
        login: (history) => dispatch(login(history)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
