import axios from 'axios';
import sessionIdParm from './sessionIdParm';

export const apiCheckStockForAllStores = (hostName, session, doneCallback, failCallback) => {
    
    var url = hostName
        + '/CheckStockForAllStores?api=mobile'
        + '&_ajax_=1&apiToken=' + session.apiToken
        + sessionIdParm(session.sessionId, hostName)
    
    axios({
        url: url,
        method: 'get',
        timeout: 8000
    })
        .then((response) => {
            if(!Array.isArray(response.data)){
                failCallback("Could not load stores tied to your account.")
            }
            doneCallback(response.data)
        })
        .catch((error) => {
            failCallback("Could not load stores tied to your account.")
        })
}

export default apiCheckStockForAllStores