import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core'
import { cartSaveToPOS } from "../cartReducer"
import isNonBlankString from '../../../../utils/isNonBlankString'
import OkDialogButton from "../../../../hoc/Buttons/OkDialogButton"
import CancelDialogButton from "../../../../hoc/Buttons/CancelDialogButton"

const SaveToPOSDialog = props => {
    console.log('SaveToPOSDialog', props.cart)

    const history = useHistory()
    const [cartName, setCartName] = useState(props.cart.name)
    const invalid = () => (!isNonBlankString(cartName))

    const goBack = () => history.goBack()
    const handleChange = (e) => setCartName(e.target.value)
    const saveToPOS = () => {
        props.cartSaveToPOS(cartName, history)
    }

    return (
        <Dialog
            open={true}
            onClose={goBack}
            aria-labelledby='savetopos-dialog-title'
            aria-describedby='savetopos-dialog-description'
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
        >
            <DialogTitle id='savetopos-dialog-title'>Save to POS</DialogTitle>
            <DialogContent dividers>
                <TextField
                    value={cartName}
                    onChange={handleChange}
                    autoFocus
                    margin='dense'
                    id='cartName'
                    label='Cart Name'
                    type='text'
                    fullWidth
                    disabled={isNonBlankString(props.cart.name)}
                />
            </DialogContent>
            <DialogActions>
                <OkDialogButton onClick={saveToPOS} disabled={invalid()}/>
                <CancelDialogButton onClick={goBack}/>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
})

const mapDispatchToProps = dispatch => ({
    cartSaveToPOS: (name, history) => dispatch(cartSaveToPOS(name, history)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SaveToPOSDialog)
