import React from 'react'
import { Radio, FormControlLabel } from '@material-ui/core'
import { Field } from 'formik'
import { RadioGroup } from 'formik-material-ui';

const FormikRadioGroup = ({ name, options, required, defaultValue }) => 
    
    <Field component={RadioGroup} name={name} required={required} defaultValue={defaultValue}>
        {options.map((option) => (
            <FormControlLabel
                key={option.key}
                value={option.value}
                control={<Radio />}
                label={option.label}
            />
        ))}
    </Field>

export default FormikRadioGroup