import React from 'react'
import { Dialog, DialogTitle } from '@material-ui/core'
import AccountForm from '../../AccountForm/AccountForm'

const EditAccountDialog = (props) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby='Edit-Account-dialog-title'
            aria-describedby='Edit-Account-dialog-description'
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth='xs'
        >
            <DialogTitle>Edit Account</DialogTitle>
            <AccountForm account={props.account} type='edit' onClose={props.onClose} />
        </Dialog>
    )
}

export default EditAccountDialog
