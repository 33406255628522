import React from 'react'
import { Icon } from '@iconify/react'

import dashboardIcon from '@iconify-icons/ic/outline-stacked-line-chart'
import storeIcon from '@iconify-icons/ic/outline-storefront'

import itemsIcon from '@iconify-icons/ic/outline-sell'

import cartIcon from '@iconify-icons/ic/outline-shopping-cart'
import cartFilledIcon from '@iconify-icons/ic/baseline-shopping-cart'

import settingsIcon from '@iconify-icons/ic/outline-settings'

import scanBarcodeIcon from '@iconify-icons/ic/outline-barcode'
import enterBarcodeIcon from '@iconify-icons/ic/outline-keyboard'
import searchIcon from '@iconify-icons/ic/outline-search'

import accountsIcon from '@iconify-icons/ic/outline-person' //store?
import preferencesIcon from '@iconify-icons/ic/outline-favorite'
import aboutIcon from '@iconify-icons/ic/outline-info'

import addIcon from '@iconify-icons/ic/outline-add'
import addAccountIcon from '@iconify-icons/ic/outline-person-add'
import addCartIcon from '@iconify-icons/ic/outline-add-shopping-cart'
import subtractIcon from '@iconify-icons/ic/outline-minus'

import backIcon from '@iconify-icons/ic/outline-arrow-back'
import rightIcon from '@iconify-icons/ic/outline-chevron-right'
import redoIcon from '@iconify-icons/ic/outline-refresh'
import menuIcon from '@iconify-icons/ic/outline-menu'

import disconnectedIcon from '@iconify-icons/ic/outline-wifi-off'
import connectedIcon from '@iconify-icons/ic/outline-wifi'

// --- TabBar Icons

const TabBarIcon = props => (<Icon icon={props.whichIcon} height="3em" />)

export const DashboardIcon = () => (<TabBarIcon whichIcon={dashboardIcon} />)
export const StoreIcon = () => (<TabBarIcon whichIcon={storeIcon} />)

export const ItemsIcon = () => (<TabBarIcon whichIcon={itemsIcon} />)

export const CartIcon = () => (<TabBarIcon whichIcon={cartIcon} />)
export const CartFilledIcon = () => (<TabBarIcon whichIcon={cartFilledIcon} />)

export const SettingsIcon = () => (<TabBarIcon whichIcon={settingsIcon} />)

// --- Button Icons

const ButtonIcon = props => (<Icon icon={props.whichIcon} style={{color: props.color}} height="2em" />)

export const ScanBarcodeIcon = () => (<ButtonIcon whichIcon={scanBarcodeIcon} />)
export const EnterBarcodeIcon = () => (<ButtonIcon whichIcon={enterBarcodeIcon} />)
export const SearchIcon = () => (<ButtonIcon whichIcon={searchIcon} />)

export const AccountsIcon = () => (<ButtonIcon whichIcon={accountsIcon} />)
export const PreferencesIcon = () => (<ButtonIcon whichIcon={preferencesIcon} />)
export const AboutIcon = () => (<ButtonIcon whichIcon={aboutIcon} />)

export const AddIcon = () => (<ButtonIcon whichIcon={addIcon} />)
export const AddAccountIcon = () => (<ButtonIcon whichIcon={addAccountIcon} />)
export const AddCartIcon = () => (<ButtonIcon whichIcon={addCartIcon} />)
export const SubtractIcon = () => (<ButtonIcon whichIcon={subtractIcon} />)
export const RightIcon = () => (<ButtonIcon whichIcon={rightIcon} />)

export const DisconnectedIcon = props => (<ButtonIcon {...props} whichIcon={disconnectedIcon} />)
export const ConnectedIcon = props => (<ButtonIcon  {...props} whichIcon={connectedIcon} />)

// --- Title Icons

const TitleIcon = props => (<Icon icon={props.whichIcon} height="1.2em" />)

export const BackTitleIcon = () => (<TitleIcon whichIcon={backIcon} />)
export const RedoTitleIcon = () => (<TitleIcon whichIcon={redoIcon} />)
export const MenuTitleIcon = () => (<TitleIcon whichIcon={menuIcon} />)
export const ScanBarcodeTitleIcon = () => (<TitleIcon whichIcon={scanBarcodeIcon} />)
