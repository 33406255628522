import React from 'react'
import { AddIcon, SubtractIcon } from '../../icons';
import { 
    Dialog, 
    DialogContent,
    makeStyles, 
    Button, 
    DialogTitle,
    DialogActions,
    ButtonGroup,
    IconButton,
    TextField
} from '@material-ui/core'
import "./QuantityDialog.css"
import { ClearFieldButton } from "../../../hoc/Formik/ClearFieldButton"
import OkDialogButton from "../../../hoc/Buttons/OkDialogButton"
import CancelDialogButton from "../../../hoc/Buttons/CancelDialogButton"

const useStyles = makeStyles((theme) => ({
    paper: { minWidth: "20em" },
    buttonGroup: {
        height: "3em",
        width: "3em",
        minWidth: "3em"
    },
    largeIcon: {
        width: 40,
        height: 40,
    },
    input: {
        // height: 600,
        textAlign: 'center',
        fontSize: 30
    },
}))

const QuantityDialog = (props) => {
    const classes = useStyles()
    
    const handleTextFieldChange = (event) => props.setValue(Number(event.target.value))
    
    const Rows = [
        [1, 2, 3, 4, 5],
        [10, 12, 15, 20, 25],
        [50, 75, 100, 150, 200],
    ]

    const RenderRows = () => {

        const Row = ({row}) => {
            return (
                <ButtonGroup size={"small"} classes={{grouped: classes.buttonGroup}}>
                    {row.map((number, index) => (
                        <Button 
                            key={index} 
                            className={props.value === number ? "active-button" : "button"}
                            onClick={() => {props.setValue(number)}}
                        >
                            {number}
                        </Button>
                    ))}
                </ButtonGroup>
            )
        }

        return(
            <div className={"QuantityRowsContainer"}>
                {Rows.map((row, index) => (
                    <Row row={row} key={index} setValue={props.setValue}/>
                ))}
            </div>
        )
    }

    const Increment = () => 
        <IconButton color={"primary"} onClick={() => props.setValue(props.value + 1)}>
            <AddIcon className={classes.largeIcon}/>
        </IconButton>
    
    const Decrement = () => 
        <IconButton color={"secondary"} onClick={() => props.setValue(props.value - 1)} disabled={props.value < 1}>
            <SubtractIcon className={classes.largeIcon}/>
        </IconButton>
    
    const handleConfirm = () => props.onConfirm()
    const handleClose = () => props.onClose()
    const handleClear = () => props.setValue(0)
    
    return (
        <Dialog 
            open={props.isOpen} 
            onClose={props.onClose} 
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth={"xs"}
            classes={{ paper: classes.paper}}
        >
            <DialogTitle>
                {props.title || 'Quantity'}
            </DialogTitle>

            <DialogContent dividers>
                <div className={"controlRow"}>
                    <div className={"controlNumber"}>
                        <Decrement/>
                        <TextField 
                            style={{width: "7em"}}
                            type={'number'}
                            margin={'none'} 
                            size={'medium'}
                            value={props.value}  
                            InputProps={{
                                className: classes.input,
                                endAdornment: <ClearFieldButton visible={(props.value > 0)} onClick={handleClear}/>
                            }}
                            onChange={handleTextFieldChange}
                        />
                        <Increment/>
                    </div>
                </div>
                <RenderRows/>
            </DialogContent>

            <DialogActions>
                <OkDialogButton onClick={handleConfirm}>Sumbit</OkDialogButton>
                <CancelDialogButton onClick={handleClose}/>
            </DialogActions>
        </Dialog>
    )
}

export default QuantityDialog
