import { combineReducers } from 'redux'
import accountsReducer from '../../components/Tabs/SettingsTab/Accounts/accountsReducer'
import cartReducer from '../../components/Tabs/CartTab/cartReducer'
import dashboardReducer from '../../components/Tabs/DashboardTab/dashboardReducer'
import itemReducer from "../../components/Tabs/ItemsTab/Item/itemReducer"
import loginReducer from './loginReducer'
import preferencesReducer from '../../components/Tabs/SettingsTab/Preferences/preferencesReducer'
import storesReducer from './storesReducer'
import tabBarReducer from '../../components/Tabs/TabBar/tabBarReducer'
import toastReducer from '../../components/Toast/toastReducer'

export default combineReducers({
    accountsReducer,
    cartReducer,
    dashboardReducer,
    itemReducer,
    loginReducer,
    preferencesReducer,
    storesReducer,
    tabBarReducer,
    toastReducer,
})
