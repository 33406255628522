import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    makeStyles,
} from "@material-ui/core";
import CartRow from './CartRow/CartRow';
import QuantityDialog from '../../../Quantity/QuantityDialog/QuantityDialog'
import SizeColorGridDialog from '../../../SizeColorGridDialog/SizeColorGridDialog'
import { setSizeColorGridOpen } from '../../ItemsTab/Item/itemReducer'
import { cartSetItemQuantity } from '../cartReducer';

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%'
    },
}))

const CartTable = props => {
    const classes = useStyles();

    // QuantityDialog
    const [quantityDetail, setQuantityDetail] = useState(null)

    const openQuantity = detail => setQuantityDetail(detail)
    const closeQuantity = () => setQuantityDetail(null)
    const setQuantity = quantity => setQuantityDetail({...quantityDetail, quantity: quantity})

    const onConfirmQuantity = () => {
        props.cartSetItemQuantity(quantityDetail.item, quantityDetail.quantity)
        closeQuantity()
    }

    // CartTable Content
    return <Fragment>
        <TableContainer>
            <Table
                stickyHeader
                className={classes.table}
                aria-label="cart"
            >
                <TableHead>
                    <TableRow >
                        <TableCell align='center' width='5rem' style={{ backgroundColor: "#DCDCDC" }}>Qty</TableCell>
                        <TableCell style={{ backgroundColor: "#DCDCDC" }}>Item</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.cart.details.map(detail => (
                        <CartRow key={detail.item.barcode} detail={detail} openQuantity={openQuantity} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <QuantityDialog
            isOpen={Boolean(quantityDetail)}
            onClose={closeQuantity}
            setValue={setQuantity}
            value={quantityDetail?.quantity || 0}
            onConfirm={onConfirmQuantity}
        />
        <SizeColorGridDialog
            open={props.sizeColorGridOpen}
            closeDialog={() => props.setSizeColorGridOpen(false)}
            item={props.item}
        />
    </Fragment>
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
    sizeColorGridOpen: state.itemReducer.sizeColorGridOpen,
    item: state.itemReducer.item
})

const mapDispatchToProps = dispatch => ({
    setSizeColorGridOpen: open => dispatch(setSizeColorGridOpen(open)),
    cartSetItemQuantity: (item, quantity) => dispatch(cartSetItemQuantity(item, quantity))
})

export default connect(mapStateToProps, mapDispatchToProps)(CartTable)
