import React from 'react'
import { useHistory } from 'react-router-dom'
import MenuButton from '../../../../hoc/MenuButton/MenuButton'
import { PATHS } from '../../../../paths'
import { AccountsIcon } from '../../../icons'

const AccountsButton = () => {
    const history = useHistory()

    const onAccountsButtonPressed = () => {
        history.push(PATHS.accounts)
    }

    return <MenuButton icon={AccountsIcon} onClick={onAccountsButtonPressed}>
        Accounts
    </MenuButton >
}

export default AccountsButton
