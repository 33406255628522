import React from 'react'
import MenuButtonWithChildren from '../../../../hoc/MenuButtonWithChildren/MenuButtonWithChildren'
import { AboutIcon } from '../../../icons'
import AboutDialog from './AboutDialog/AboutDialog'
import { PATHS } from '../../../../paths'

export default () => (
    <MenuButtonWithChildren
        buttonText='About'
        buttonIcon={AboutIcon}
        childPath={PATHS.about}
    >
        <AboutDialog />
    </MenuButtonWithChildren>
)