import { updateObject } from "../../../../redux/utility"
import apiGetProduct from "../../../../utils/apiGetProduct"
import apiGetNewColor from "../../../../utils/apiGetNewColor"
import { showToast, TOAST_SEVERITY } from "../../../Toast/toastReducer"
import getSelectedAccount from "../../SettingsTab/Accounts/getSelectedAccount"
import InventoryItem from "../../../../classes/InventoryItem"
import { PATHS } from '../../../../paths'
import apiInventorySearch from "../../../../utils/apiInventorySearch"
import apiCheckStockForStore from "../../../../utils/apiCheckStockForStore"
import apiCheckStockForAllStores from "../../../../utils/apiCheckStockForAllStores"
import apiVStockGet from "../../../../utils/apiVStockGet"
import { getItemQuantity } from "../../CartTab/cartReducer"
import isNonBlankString from "../../../../utils/isNonBlankString"

//used to render the item
const initialState = {
    newBarcode: '',
    data: null,
    loading: false,
    items: [],
    item: {},

    advancedSearchLoading: false,
    searchFormContents: {},
    searchResults: [],
    showSearchResults: false,

    otherStoreStock: [],
    showOtherStoreStock: false,
    otherStoreStockLoading: false,

    sizeColorGrid: {},
    sizeColorGridLoading: false,
    sizeColorGridOpen: false,
    sizeColorGridDefaultItem: {}
}

const ITEM_FETCH_SUCCESS = '/item/ITEM_FETCH_SUCCESS'
const ITEM_SET_LOADING = '/item/ITEM_SET_LOADING'

const ITEM_ADVANCED_SEARCH_SUCCESS = '/item/ITEM_ADVANCED_SEARCH_SUCCESS'
const ITEM_SET_SHOW_SEARCH_RESULTS = '/item/ITEM_SET_SHOW_SEARCH_RESULTS'
const ITEM_SET_SEARCH_FORM_CONTENTS = '/item/ITEM_SET_SEARCH_FORM_CONTENTS'
const ITEM_SET_ADVANCED_SEARCH_LOADING = '/item/ITEM_SET_ADVANCED_SEARCH_LOADING'

const ITEM_FETCH_OTHER_STORE_STOCK_SUCCESS = '/item/ITEM_FETCH_OTHER_STORE_STOCK_SUCCESS'
const ITEM_SET_SHOW_OTHER_STORE_STOCK = '/item/ITEM_SET_SHOW_OTHER_STORE_STOCK'
const ITEM_SET_OTHER_STORE_STOCK_LOADING = '/item/ITEM_SET_OTHER_STORE_STOCK_LOADING'

const ITEM_FETCH_VSTOCK_SUCCESS = '/item/ITEM_FETCH_VSTOCK_SUCCESS'

const ITEM_SET_SIZE_COLOR_GRID = "/item/ITEM_SET_SIZE_COLOR_GRID"
const ITEM_SET_SIZE_COLOR_GRID_LOADING = "/item/ITEM_SET_SIZE_COLOR_GRID_LOADING"
const ITEM_SET_SIZE_COLOR_GRID_OPEN = "/item/ITEM_SET_SIZE_COLOR_GRID_OPEN"
const ITEM_SET_SIZE_COLOR_GRID_DEFAULT_ITEM = "/item/ITEM_SET_SIZE_COLOR_GRID_DEFAULT_ITEM"

const itemFetchSuccess = (data) => ({
    type: ITEM_FETCH_SUCCESS,
    payload: data
})

const itemSetLoading = (data) => ({
    type: ITEM_SET_LOADING,
    payload: data
})

const itemSetAdvancedSearchLoading = (data) => ({
    type: ITEM_SET_ADVANCED_SEARCH_LOADING,
    payload: data
})

const advancedSearchSuccess = (data) => ({
    type: ITEM_ADVANCED_SEARCH_SUCCESS,
    payload: data
})

const itemSetShowSearchResults = (data) => ({
    type: ITEM_SET_SHOW_SEARCH_RESULTS,
    payload: data
})

const itemSetSearchFormContents = (data) => ({
    type: ITEM_SET_SEARCH_FORM_CONTENTS,
    payload: data
})

const itemFetchOtherStoreStockSuccess = (data) => ({
    type: ITEM_FETCH_OTHER_STORE_STOCK_SUCCESS,
    payload: data
})

const itemSetShowOtherStoreStock = (data) => ({
    type: ITEM_SET_SHOW_OTHER_STORE_STOCK,
    payload: data
})

const itemSetOtherStoreStockLoading = (data) => ({
    type: ITEM_SET_OTHER_STORE_STOCK_LOADING,
    payload: data
})

const itemFetchVStockSuccess = (data) => ({
    type: ITEM_FETCH_VSTOCK_SUCCESS,
    payload: data
})

const itemSetSizeColorGrid = (data) => ({
    type: ITEM_SET_SIZE_COLOR_GRID,
    payload: data
})

const itemSetSizeColorGridLoading = (data) => ({
    type: ITEM_SET_SIZE_COLOR_GRID_LOADING,
    payload: data
})

const itemSetSizeColorGridOpen = (data) => ({
    type: ITEM_SET_SIZE_COLOR_GRID_OPEN,
    payload: data
})

const itemSetSizeColorGridDefaultItem = (data) => ({
    type: ITEM_SET_SIZE_COLOR_GRID_DEFAULT_ITEM,
    payload: data
})

export const itemFetch = (id, history, showInactive, scanned, pathOnSuccess = PATHS.item, actionOnSuccess, toastOnSuccess) => {

    return (dispatch, getState) => {

        const selectedAccount = getSelectedAccount(getState().accountsReducer)
        const session = getState().loginReducer.session

        const doneCallback = (data) => {
            console.log("ITEM FOUND!")
            console.log(data)
            if (isNonBlankString(pathOnSuccess)) {
                if (pathOnSuccess === PATHS.goBack) {
                    history.goBack()
                } else {
                    history.push(pathOnSuccess)
                }
            }
            var item = new InventoryItem(data)
            item.loadItem(data, selectedAccount, session)
            dispatch(itemFetchSuccess(item))
            dispatch(itemSetLoading(false))

            if (isNonBlankString(toastOnSuccess)) {
                dispatch(showToast(toastOnSuccess, TOAST_SEVERITY.info))
            }

            if (isNonBlankString(actionOnSuccess)) {
                dispatch({
                    type: actionOnSuccess,
                    item: item,
                })
            }
        }

        const failCallback = (error) => {
            dispatch(showToast('Item not found!', TOAST_SEVERITY.error))
            dispatch(itemSetLoading(false));
        }

        dispatch(itemSetLoading(true))
        apiGetProduct(selectedAccount.hostName, session, id, showInactive, scanned, doneCallback, failCallback)
    }
}

export const itemFetchFromColor = (colorCode, item, history) => {

    return (dispatch, getState) => {

        const selectedAccount = getSelectedAccount(getState().accountsReducer)
        const session = getState().loginReducer.session

        const doneCallback = (data) => {
            dispatch(itemFetch(data.NEWBARCODE, history, false, false))
        }

        const failCallback = (error) => {
            dispatch(showToast('Could not find item with that color!', TOAST_SEVERITY.error))
        }

        apiGetNewColor(selectedAccount, session, colorCode, item, doneCallback, failCallback)
    }
}

export const advancedSearch = (formResults, vendor, maxResults, history) => {

    return (dispatch, getState) => {
        const selectedAccount = getSelectedAccount(getState().accountsReducer)
        const session = getState().loginReducer.session

        const doneCallback = (data) => {

            //If only one result, show the item
            if (data.NUMBEROFITEMS === 1) {
                dispatch(itemFetch(data.RESULTS[0].BARCODE, history, false, false))

                // wait and then
                setTimeout(() => { dispatch(itemSetAdvancedSearchLoading(false)) }, 2000)
                setTimeout(() => { dispatch(itemSetShowSearchResults(data.NUMBEROFITEMS > 1)) }, 2000)

            }
            else {
                dispatch(itemSetShowSearchResults(data.NUMBEROFITEMS > 1))
                dispatch(advancedSearchSuccess(data))
                dispatch(itemSetAdvancedSearchLoading(false))
            }
        }

        const failCallback = (error) => {
            dispatch(itemSetShowSearchResults(false))
            dispatch(itemSetAdvancedSearchLoading(false))
            dispatch(showToast(error, TOAST_SEVERITY.error))
        }

        dispatch(itemSetAdvancedSearchLoading(true))
        apiInventorySearch(selectedAccount, session, formResults, doneCallback, failCallback, vendor, maxResults)
    }
}

export const setShowSearchResults = (show) => {
    return (dispatch, getState) => {
        dispatch(itemSetShowSearchResults(show))
    }
}

export const setSearchFormContents = (contents) => {
    return (dispatch, getState) => {
        dispatch(itemSetSearchFormContents(contents))
    }
}

export const fetchOtherStoreStock = (id) => {

    return (dispatch, getState) => {
        // Note: The storesReducer does not have a URL for each store.

        const selectedAccount = getSelectedAccount(getState().accountsReducer)
        const session = getState().loginReducer.session
        var stores = [];
        var otherStoreStock = [];
        var storesChecked = 0;

        // Callback functions for fetching other stores. (URLs)
        const doneCallbackStores = (data) => {

            //update stores
            stores = data

            //loop through each store
            for (var i = 0; i < stores.length; i++) {
                var store = stores[i]
                apiCheckStockForStore(selectedAccount.hostName, session, store, id, doneCallback, failCallback)
            }
        }

        const failCallbackStores = (error) => {

            //hide store stock dialog
            dispatch(itemSetShowOtherStoreStock(false))

            //set loading to false
            dispatch(itemSetOtherStoreStockLoading(false))

            //display error
            dispatch(showToast('Could not load the stores tied to this account.', TOAST_SEVERITY.error))
        }

        //set loading to true
        dispatch(itemSetOtherStoreStockLoading(true))

        //display store stock dialog
        dispatch(itemSetShowOtherStoreStock(true))

        apiCheckStockForAllStores(selectedAccount.hostName, session, doneCallbackStores, failCallbackStores)

        // Callback functions for fetching stock per store.
        const doneCallback = (store, data) => {
            otherStoreStock.push(['Success', store, data])
            storesChecked += 1
            if (storesChecked === stores.length) {

                // Update state
                dispatch(itemFetchOtherStoreStockSuccess(otherStoreStock))

                // Set loading to false
                dispatch(itemSetOtherStoreStockLoading(false))
            }
        }

        const failCallback = (store, error) => {
            otherStoreStock.push(['Error', store, error])
            storesChecked += 1
            if (storesChecked === stores.length) {

                // Update state
                dispatch(itemFetchOtherStoreStockSuccess(otherStoreStock))

                // Set loading to false
                dispatch(itemSetOtherStoreStockLoading(false))
            }
        }
    }
}

export const setShowOtherStoreStock = (show) => {
    return (dispatch, getState) => {
        dispatch(itemSetShowOtherStoreStock(show))
    }
}

export const fetchVStock = (id) => {
    return (dispatch, getState) => {

        const selectedAccount = getSelectedAccount(getState().accountsReducer)
        const session = getState().loginReducer.session
        const item = { ...getState().itemReducer.item }

        const doneCallback = (data) => {

            //update vstock attributes of item object
            item.vstock = data
            item.vstockQtyVisible = true
            item.getVstockButtonVisible = false
            item.showVStockColumn = true

            for (var i = 0; i < item.sizes.length; i++) {
                if (item.sizes[i].BARCODE === id) {
                    item.sizes[i].VSTOCK = data
                }
            }

            dispatch(itemFetchVStockSuccess(item))
        }

        const failCallback = (error) => {
            dispatch(showToast('Could not load VStock.', TOAST_SEVERITY.error))
        }

        apiVStockGet(selectedAccount.hostName, session, id, doneCallback, failCallback)
    }
}

// Loads all VStock one by one
export const fetchVStockAllSizes = (id) => {

    return (dispatch, getState) => {
        const selectedAccount = getSelectedAccount(getState().accountsReducer)
        const session = getState().loginReducer.session

        var originalID = id
        var index = 0

        const GetStartingIndex = (item) => {
            for (var i = 0; i < item.sizes.length; i++) {
                if (item.sizes[i].VSTOCK === '') {
                    return i
                }
            }
        }

        const CheckIfCompleted = (nVStockItemsToGet) => {
            index += 1
            return index >= nVStockItemsToGet
        }

        const doneCallback = (data) => {

            //Create copy of item
            let item = { ...getState().itemReducer.item }

            //Get nVstockItemsToGet
            let nVstockItemsToGet = item.nVstockItemsToGet

            //Update item
            item.sizes[index].VSTOCK = data

            //Update original item
            if (data.BARCODE === originalID) {
                item.vstock = data
                item.getVstockButtonVisible = false
            }

            //Check if complete
            if (CheckIfCompleted(nVstockItemsToGet)) {
                if (nVstockItemsToGet > 0 || GetStartingIndex(item) === item.sizes.length) {
                    item.getSizeVstockButtonVisible = false
                }
                dispatch(itemFetchVStockSuccess(item))
            } else {
                dispatch(itemFetchVStockSuccess(item))
                apiVStockGet(selectedAccount.hostName, session, item.sizes[index].BARCODE, doneCallback, failCallback)
            }
        }

        const failCallback = (error) => {

            //Print error
            console.log(error)

            //Create copy of item
            let item = { ...getState().itemReducer.item }

            //Get nVstockItemsToGet
            let nVstockItemsToGet = item.nVstockItemsToGet

            //Check if complete
            if (CheckIfCompleted(nVstockItemsToGet)) {
                if (nVstockItemsToGet > 0 || GetStartingIndex(item) === item.sizes.length) {
                    item.getSizeVstockButtonVisible = false
                }
            } else {
                apiVStockGet(selectedAccount.hostName, session, item.sizes[index].BARCODE, doneCallback, failCallback)
            }

            dispatch(itemFetchVStockSuccess(item))
        }

        //Update item initially.
        var item = { ...getState().itemReducer.item }

        //Update how many items need to be fetched
        item.nVstockItemsToGet = item.sizes.length

        //Show the vstock Qty in the table.
        item.vstockQtyVisible = true
        item.showVStockColumn = true

        //Show the cancel vstock button
        item.cancelVStockSizeButtonVisible = true

        //Update item before fetching
        dispatch(itemFetchVStockSuccess(item))

        //Get First Index, Start the Cycle
        index = GetStartingIndex(item)
        apiVStockGet(selectedAccount.hostName, session, item.sizes[index].BARCODE, doneCallback, failCallback)
    }
}

export const cancelVStock = () => {
    return (dispatch, getState) => {
        let item = { ...getState().itemReducer.item }
        item.nVstockItemsToGet = 0
        item.getSizeVstockButtonVisible = true
        item.cancelVStockSizeButtonVisible = false
        dispatch(itemFetchVStockSuccess(item))
    }
}

export const loadSizeColorGrid = (barcode) => {
    return(dispatch, getState) => {

        const account = getSelectedAccount(getState().accountsReducer)
        const session = getState().loginReducer.session
        const cart = getState().cartReducer.cart
        // let item = {...getState().itemReducer.item}

        let matrixProducts = []
        let matrixSizes = []
        let matrixColors = []
        let sizeColorGrid = {}

        var inactiveSizes = []
        var activeSizes = []

        var cleanSizes = []

        var sizeList = []
        var colorList = []

        const cleanUpSizes = () => {
            for(var size of matrixSizes){
                if(size.inactive){
                    inactiveSizes.push(size.size.replace("*",''))
                }
                else{
                    activeSizes.push(size.size.replace("*",''))
                }
            }
            for(size of matrixSizes){
                if(activeSizes.includes( size.size)) {
                    if(!sizeList.includes(size.size)){
                        cleanSizes.push(size)
                        sizeList.push(size.size)
                    }
                }
                else if((inactiveSizes.includes(size.size.replace("*",'')) && (!activeSizes.includes(size.size.replace("*",''))))){
                    if(!sizeList.includes(size.size.replace("*",''))){
                        cleanSizes.push(size)
                        sizeList.push(size.size.replace("*",''))
                    }
                }
            }
            matrixSizes = cleanSizes
        }

        const sizeIndex = (size) => {
            for(var cleanSize of cleanSizes){
                if(cleanSize.size.replace('*', '') === size.replace('*', '')){
                    return cleanSize.size
                }
            }
        }

        const colorIndex = (color) => {
            for(var cleanColor of matrixColors){
                if(cleanColor.color.replace('*', '') === color.replace('*', '')){
                    return cleanColor.inactive ? ('*' + cleanColor.color) : cleanColor.color
                }
            }
        }

        const createMatrix = () => {

            for(var color of matrixColors){
                colorList.push(color.color)
            }
        
            cleanUpSizes()

            for(var sizeItem of matrixSizes){
                var size = sizeItem.size
                sizeColorGrid[sizeIndex(size)] = {}
                for(var colorItem of matrixColors){
                    sizeColorGrid[sizeIndex(size)][colorIndex( colorItem.color)] = {
                        item: null,
                        count: null
                    }
                }
            }

            for(var item of matrixProducts){

                sizeColorGrid[sizeIndex(item.size)][colorIndex(item.thisColor)] = {
                    item: item,
                    count: getItemQuantity(cart, item.barcode)
                }       
            }

            console.log(sizeColorGrid)

            dispatch(itemSetSizeColorGrid({...sizeColorGrid}))
            dispatch(itemSetSizeColorGridLoading(false))
        }

        const done = (data) => {
            var itemElement;
            if (data.Error) {
                // fail()
                // dispatch(showToast('Could not refresh product info from server!', TOAST_SEVERITY.error))
                return
            }
            else if(Array.isArray(data)){
                for(var element of data) {
                    itemElement = new InventoryItem(element)
                    itemElement.loadItem(element, account, session)
                    if(itemElement.barcode === barcode){
                        dispatch(itemSetSizeColorGridDefaultItem(itemElement))
                    }
                    buildMatrix(itemElement);
                };
            }else{
                itemElement = new InventoryItem(data)
                itemElement.loadItem(data, account, session)
                buildMatrix(itemElement);
            }

            sortSizes();
            sortColors();
            createMatrix()

            function buildMatrix(product) {

                if (!product.isStyle) {
                    var singleColorAndSize = product.size;

                    if (Array.isArray(product.sizes) && product.sizes.length === 0) {
                        product.sizes.push({
                            SORT: 1,
                            SIZE: singleColorAndSize,
                            INACTIVE: false // ???
                        });
                    }

                    if (Array.isArray(product.colors) && product.colors.length === 0) {
                        product.colors.push({
                            COLORCODE: singleColorAndSize,
                            NAME: singleColorAndSize,
                            INACTIVE: false // ???
                        });
                    }
                }

                buildProducts();
                buildSizes(product.sizes);
                buildColors(product.colors);

                function buildProducts() {
                    matrixProducts.push(product);
                }

                function buildSizes(productSizes) {
                   
                    if (Array.isArray(productSizes)) {
                        productSizes.forEach(function (productSize) {
                            
                            var foundSize = matrixSizes.find(function (matrixSize) {
                                return ((matrixSize.size) === (productSize.SIZE));
                            });

                            if (foundSize) {
                                if (foundSize.inactive === 1 && productSize.INACTIVE === 0) {
                                    foundSize.inactive = 0;
                                }
                            } else {
                                matrixSizes.push({
                                    sort: productSize.SORT,
                                    size: productSize.SIZE,
                                    inactive: productSize.INACTIVE
                                });
                            }
                        });
                    }
                }

                function buildColors(productColors) {
                    if (Array.isArray(productColors)) {
                        productColors.forEach(function (productColor) {
                            var foundColor = matrixColors.find(function (matrixColor) {
                                return matrixColor.color === productColor.COLORCODE;
                            });
                            if (foundColor) {
                                if (foundColor.inactive === 1 && productColor.INACTIVE === 0) {
                                    foundColor.inactive = 0;
                                }
                            } else {
                                matrixColors.push({
                                    color: productColor.COLORCODE,
                                    name: productColor.NAME,
                                    inactive: productColor.INACTIVE
                                });
                            }
                        });
                    }
                }
            }

            function sortSizes() {
                matrixSizes.sort(function (a, b) {
                    return a.sort - b.sort;
                });
            }

            function sortColors() {
                matrixColors.sort(function (a, b) {
                    if (a.color < b.color) {
                        return -1;
                    } else if (a.color > b.color) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
        }

        const fail = (error) => {
            dispatch(itemSetSizeColorGridOpen(false))
            dispatch(itemSetSizeColorGrid({}))
            dispatch(showToast(error || 'Could not refresh product info from server!', TOAST_SEVERITY.error))
            setTimeout(function(){
                dispatch(itemSetSizeColorGridLoading(false))
            }, 2000); 
            return       

        }

        dispatch(itemSetSizeColorGrid({}))
        dispatch(itemSetSizeColorGridLoading(true))
        dispatch(itemSetSizeColorGridOpen(true))
        apiGetProduct(account.hostName, session, [barcode, '*R*'], true, false, done, fail, 20000);
        
    }
}

export const setSizeColorGridOpen = (data) => {
    return (dispatch, getState) => {
        dispatch(itemSetSizeColorGridOpen(data))
    }
}

// reducer
const itemReducer = (state = initialState, action) => {
    switch (action.type) {
        case ITEM_FETCH_SUCCESS:
            return reduceItemFetchSuccess(state, action)
        case ITEM_SET_LOADING:
            return reduceItemSetLoading(state, action)
        case ITEM_ADVANCED_SEARCH_SUCCESS:
            return reduceItemAdvancedSearchSuccess(state, action)
        case ITEM_SET_SHOW_SEARCH_RESULTS:
            return reduceItemSetShowSearchResults(state, action)
        case ITEM_SET_SEARCH_FORM_CONTENTS:
            return reduceItemSetSearchFormContents(state, action)
        case ITEM_SET_ADVANCED_SEARCH_LOADING:
            return reduceItemSetAdvancedSearchLoading(state, action)
        case ITEM_FETCH_OTHER_STORE_STOCK_SUCCESS:
            return reduceItemFetchOtherStoreStockSuccess(state, action)
        case ITEM_SET_SHOW_OTHER_STORE_STOCK:
            return reduceItemSetShowOtherStoreStock(state, action)
        case ITEM_SET_OTHER_STORE_STOCK_LOADING:
            return reduceItemSetOtherStoreStockLoading(state, action)
        case ITEM_FETCH_VSTOCK_SUCCESS:
            return reduceItemFetchVStockSuccess(state, action)
        case ITEM_SET_SIZE_COLOR_GRID:
            return reduceItemSetSizeColorGrid(state, action)
        case ITEM_SET_SIZE_COLOR_GRID_LOADING:
            return reduceItemSetSizeColorGridLoading(state, action)
        case ITEM_SET_SIZE_COLOR_GRID_OPEN:
            return reduceItemSetSizeColorOpen(state, action)
        case ITEM_SET_SIZE_COLOR_GRID_DEFAULT_ITEM:
            return reduceItemSetSizeColorGridDefaultItem(state, action)
        default:
            return state
    }
}

// ---------- ItemFetch
const reduceItemFetchSuccess = (state, action) => {
    return updateObject(state, { item: action.payload })
}

// ---------- ItemSetLoadingFalse
const reduceItemSetLoading = (state, action) => {
    return updateObject(state, { loading: action.payload })
}

// ---------- ItemAdvancedSearchSuccess
const reduceItemAdvancedSearchSuccess = (state, action) => {
    return updateObject(state, { searchResults: action.payload })
}

// ---------- ItemSetShowSearchResults
const reduceItemSetShowSearchResults = (state, action) => {
    return updateObject(state, { showSearchResults: action.payload })
}

// ----------- ItemSetSearchFormContents
const reduceItemSetSearchFormContents = (state, action) => {
    return updateObject(state, { searchFormContents: action.payload })
}

// ----------- ItemSetAdvancedSearchLoading
const reduceItemSetAdvancedSearchLoading = (state, action) => {
    return updateObject(state, { advancedSearchLoading: action.payload })
}

// ------------ ItemFetchOtherStoreStockSuccess
const reduceItemFetchOtherStoreStockSuccess = (state, action) => {
    return updateObject(state, { otherStoreStock: action.payload })
}

// ------------ ItemSetShowOtherStoreStock
const reduceItemSetShowOtherStoreStock = (state, action) => {
    return updateObject(state, { showOtherStoreStock: action.payload })
}

// ------------- ItemSetOtherStoreStockLoading
const reduceItemSetOtherStoreStockLoading = (state, action) => {
    return updateObject(state, { otherStoreStockLoading: action.payload })
}

// ------------- ItemFetchVStockSuccess
const reduceItemFetchVStockSuccess = (state, action) => {
    return updateObject(state, { item: action.payload })
}

// ------------- reduceItemSetSizeColorGrid
const reduceItemSetSizeColorGrid = (state, action) => {
    return updateObject(state, { sizeColorGrid: action.payload })
}

//-------------- reduceItemSetSizeColorGridLoading
const reduceItemSetSizeColorGridLoading = (state, action) => {
    return updateObject(state, { sizeColorGridLoading: action.payload })
}

//-------------- reduceItemSetSizeColorOpen
const reduceItemSetSizeColorOpen = (state, action) => {
    return updateObject(state, { sizeColorGridOpen: action.payload })
}

//-------------- reduceItemSetSizeColorGridDefaultItem
const reduceItemSetSizeColorGridDefaultItem = (state, action) => {
    return updateObject(state, { sizeColorGridDefaultItem: action.payload })
}

/*****************************************************************************/
export default itemReducer