import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import {
    Menu,
    MenuItem,
    TableRow,
    TableCell,
} from '@material-ui/core'
import { cartDeleteDetail } from '../../cartReducer'
import { loadSizeColorGrid } from '../../../ItemsTab/Item/itemReducer'
import { isStyle } from '../../../../../utils/isStyle'

const CartRow = (props) => {
    const quantity = props.detail.quantity
    const item = props.detail.item
    const barcode = item.barcode

    // Menu Controls
    const [anchorEl, setAnchorEl] = useState(null)

    const handleContextMenu = event => {
        setAnchorEl(event.currentTarget)
        event.preventDefault()
        return false
    }

    const closeMenu = event => {
        if (anchorEl) {
            setAnchorEl(null)
        }
        if (typeof(event) === 'object' && 'defaultPrevented' in event) {
            event.preventDefault()
            return false
        }
    }

    const handleChangeQuantityClick = () => {
        closeMenu()
        props.openQuantity(props.detail)
    }

    const handleSizeColorGridClick = () => {
        closeMenu()
        props.loadSizeColorGrid(barcode)
    }

    const handleDeleteFromCartClick = () => {
        closeMenu()
        props.cartDeleteDetail(barcode)
    }

    // Item TableCell Content
    const itemContent = () => {
        const renderAttribute = (value, label) => {
            if (isStyle(item.itemType) && value && value.length > 0) {
                return <span>{label}&nbsp;{value}; </span>
            }
        }

        return (
            <React.Fragment>
                <span style={{ fontWeight: 'bold' }}>{item.itemTitle}</span>
                <br />
                {renderAttribute(item.color, 'Color')}
                {renderAttribute(item.size, 'Size')}
                <span style={{ fontWeight: '500' }}>{item.priceFormatted}</span>
            </React.Fragment>
        )
    }

    // TableRow Content
    return (
        <Fragment>
            <TableRow key={barcode}>
                <TableCell align="center" onContextMenu={handleContextMenu}>
                    {quantity}
                </TableCell>
                <TableCell component="th" scope="row" onContextMenu={handleContextMenu}>
                    {itemContent()}
                </TableCell>
            </TableRow>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                disableScrollLock
                onContextMenu={closeMenu}
            >
                <MenuItem onClick={handleChangeQuantityClick}>Change Quantity</MenuItem>
                <MenuItem onClick={handleSizeColorGridClick}>Size/Color Grid</MenuItem>
                <MenuItem onClick={handleDeleteFromCartClick}>Delete from Cart</MenuItem>
            </Menu>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
})

const mapDispatchToProps = dispatch => ({
    loadSizeColorGrid: (barcode) => dispatch(loadSizeColorGrid(barcode)),
    cartDeleteDetail: item => dispatch(cartDeleteDetail(item)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CartRow)
