import React, { Fragment } from 'react'
import ScanItemDialog from './ScanItemDialog/ScanItemDialog'
import { Route, useHistory } from 'react-router-dom'

export default (props) => {
    const dialogPath = props.scanPath
    const history = useHistory()

    const handleClickOpen = () => {
        history.push(dialogPath)
    }

    const handleClose = () => {
        history.goBack()
    }

    return (
        <Fragment>
            <div onClick={handleClickOpen}>{props.children}</div>
            <Route exact path={dialogPath}>
                <ScanItemDialog onClose={handleClose} processCode={props.processCode} singleCode={props.singleCode} />
            </Route>
        </Fragment>
    )
}
