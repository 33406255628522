import React from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import DialogButton from "./DialogButton"

const styles = {
    root: {
       
    },
};

const CancelDialogButton = (props) => {
    const { classes, children, className, ...other } = props

    return(
        <DialogButton color={props.color || "default"} variant={props.variant || "contained"} className={clsx(classes.root, className)} {...other}>
            {children || "CANCEL"}
        </DialogButton>
    )
}

DialogButton.propTypes = {
    children: PropTypes.node,
}

export default withStyles(styles)(CancelDialogButton);