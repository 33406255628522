import React from 'react';
import { connect } from 'react-redux'
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    makeStyles,
    useTheme,
    useMediaQuery
} from '@material-ui/core'
import DialogLoading from "../../../../hoc/Loading/DialogLoading"
import "./OtherStoreStockDialog.css"
import OkDialogButton from '../../../../hoc/Buttons/OkDialogButton';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "5px",
        margin: "5px"
    },
}))

const OtherStoreStockDialog = (props) => {
    const classes = useStyles()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const handleClose = () => {
        props.closeDialog();
    };

    return(
        <Dialog onClose={handleClose} open={props.open} fullScreen={fullScreen}>
            <DialogTitle>Stock in Other Stores</DialogTitle>
            <DialogContent dividers classes={fullScreen ? {root: classes.root} : {}}>
                <div className={"DialogContainer"}>
                    {props.loading ? 
                        <DialogLoading/>
                        :
                        <TableContainer component={Paper}>
                            <Table className={"OtherStoreTable"}>
                                <TableHead style={{backgroundColor: "#307ABC"}}> 
                                    <TableRow>
                                        <TableCell style={{color: 'white'}}>Store</TableCell>
                                        <TableCell align='center' style={{color: 'white'}}>Qty</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { props.otherStoreStock.map((store) => (
                                        <TableRow key={store[2].StoreNumber}>
                                            <TableCell> 
                                                <div><b>{store[1].Store}</b></div>
                                                <div>{store[1].StoreAddress}</div>
                                                <div>{store[1].StoreCity} {store[1].StoreState} {store[1].StorePostalCode}</div>
                                            </TableCell>
                                            <TableCell align='center'>
                                                {store[0] === 'Success' ? 
                                                <div>{store[2].StockedQuantity}</div> :
                                                <div>Could not load inventory.</div>}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <OkDialogButton onClick={handleClose}/>
            </DialogActions>
        </Dialog>
    )
};

const mapStateToProps = (state) => {
    return {
        otherStoreStock: state.itemReducer.otherStoreStock,
        loading: state.itemReducer.otherStoreStockLoading
    }
}

export default connect(mapStateToProps, null)(OtherStoreStockDialog)