import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme, useMediaQuery, IconButton, Menu, MenuItem, Divider, Hidden,Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, Paper, TableRow,CircularProgress } from '@material-ui/core'
import { BackTitleIcon, MenuTitleIcon, AddCartIcon, RightIcon } from '../../../icons';
import SelectColorDialog from '../SelectColorDialog/SelectColorDialog';
import OtherStoreStockDialog from '../OtherStoreStockDialog/OtherStoreStockDialog'
import SizeColorGridDialog from '../../../SizeColorGridDialog/SizeColorGridDialog'
import { itemFetch, fetchOtherStoreStock, setShowOtherStoreStock, fetchVStock, fetchVStockAllSizes, cancelVStock, loadSizeColorGrid, setSizeColorGridOpen  } from "./itemReducer"
import { cartAddItem } from '../../CartTab/cartReducer'
import DialogButton from '../../../../hoc/Buttons/DialogButton'
import OkDialogButton from '../../../../hoc/Buttons/OkDialogButton'
import CancelDialogButton from '../../../../hoc/Buttons/CancelDialogButton'

import './Item.css'

const Item = (props) => {
    const history = useHistory()

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    // Cart
    const handleAddToCart = () => props.cartAddItem(props.item)
       
    // VStock
    const handleGetVStockSize = () => props.fetchVStockAllSizes(props.item.barcode)
    const handleGetVStock = () => props.fetchVStock(props.item.barcode)
    
    // Other Store Stock
    const handleGetStoreStock = () => props.fetchOtherStoreStock(props.item.barcode)
    
    // Select Color Dialog
    const [openSelectColorDialog, setOpenSelectColorDialog] = React.useState(false);
    const handleBrowseColor = () => setOpenSelectColorDialog(true); 
    
    // const [openSizeColorGridDialog, setOpenSizeColorGridDialog] = React.useState(false)
   
    const ItemHeader = () => {
        // Header menu controls
        const [anchorEl, setAnchorEl] = React.useState(null)
        const handleClick = (event) => setAnchorEl(event.currentTarget)
        const handleMenuClose = () => { if (anchorEl) setAnchorEl(null) }
        const goBack = () => history.goBack()

        // Size Color Grid Dialog
        const handleSizeColorGrid = () => {
            
            handleMenuClose()
            props.loadSizeColorGrid(props.item.barcode)
        }

        return(
            <React.Fragment>
                <div className={"row"}>
                    <IconButton onClick={goBack}>
                        <BackTitleIcon />
                    </IconButton>
                    <h2 className={"headerTitle"}>Item</h2>
                    <IconButton onClick={handleClick}>
                        <MenuTitleIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose} disableScrollLock>
                        {/* TODO: [Add to Cart] should not be available if server doesn't support. */}
                        <MenuItem onClick={handleAddToCart}>Add to Cart</MenuItem>
                        <MenuItem onClick={handleBrowseColor}>Change Color</MenuItem>
                        <MenuItem onClick={handleSizeColorGrid}>Size/Color Grid</MenuItem>
                    </Menu>
                </div>
            </React.Fragment>
        )
    }

    const Image = ({item}) => <img src={item.imgsrc} alt={""} className={"ItemDetailsImage"}/>  
    
    const SizeTable = ({item}) => {
        const onRowClick = (id) => props.itemFetch(id, history, false, false)
        return(
            <TableContainer component={Paper} className={"tableContainer"} >
                <Table size="small" stickyHeader>
                    <caption>{item.inactiveItemsShown ?  "(* Inactive)" : null}</caption>
                    <TableHead>
                        <TableRow> 
                            {/* Size */}
                            <TableCell className={"tableHead"} color={"primary"} align="left">Size</TableCell>
                            {/* Quantity */}
                            <TableCell className={"tableHead"} align="center">Qty</TableCell>
                            {/* VStock */}
                            {item.showVStockColumn ? 
                                <TableCell className={"tableHead"} align="center">VStock</TableCell> 
                            : null }
                            {/* Price */}
                            <TableCell className={"tableHead"} align="right">Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {item.sizes.map((size) => (
                        <TableRow key={size.BARCODE} className={"tableRow"} onClick={()=>{onRowClick(size.BARCODE)}}>
                            {/* Size */}
                            <TableCell component="th" scope="row">
                                {size.SIZE === item.size ? <b>{size.SIZE}</b> : <React.Fragment>{size.SIZE}</React.Fragment>}
                            </TableCell>
                            {/* Quantity */}
                            <TableCell align="center">{size.QTY}</TableCell>
                            {/* VStock */}
                            {item.showVStockColumn ? 
                                <TableCell align="center">{size.VSTOCK.QTY ? size.VSTOCK.QTY : '-'}</TableCell> 
                            : null }
                            {/* Price */}
                            <TableCell align="right">{size.PRICEFORMATTED}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
               
            </TableContainer>
        )
    }
    
    const ItemDetailsButton = ({text, onClick}) => 
        <DialogButton onClick={onClick} className={"ItemDetailsButton"} size={"small"}>
            {text}
        </DialogButton>

    const ItemDetailsList = ({item}) => {

        const mobileNoSpace = {
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 0,
            marginBottom: 0
        }

        return(
            <Grid container spacing={3}> 
                {/* Column 1 */}
                <Grid item xs={12} md={6} style={mobile ? mobileNoSpace : null}> 
                    {/* Color */}
                    <Grid xs={12} item className={"ItemDetail"}>
                        <Grid item xs={3}><b>Color</b></Grid>
                        <Grid item xs={8} className={"ItemColor"} onClick={handleBrowseColor}>
                            {item.color} ({item.thisColor})
                            <RightIcon color={'primary'}/>
                        </Grid>
                    </Grid>
                    {/* Size */}
                    <Grid xs={12} item className={"ItemDetail"}>
                        <Grid item xs={3}><b>Size</b></Grid>
                        <Grid item xs={8}>
                            {item.size}
                        </Grid>
                    </Grid>
                    {/* Price */}
                    <Grid xs={12} item className={"ItemDetail"}>
                        <Grid item xs={3}><b>Price</b></Grid>
                        <Grid item xs={8}>
                            {item.priceFormatted}
                        </Grid>
                    </Grid>
                    {/* Quantity */}
                    <Grid xs={12} item className={"ItemDetail"}>
                        <Grid item xs={3}><b>Qty</b></Grid>
                        <Grid item xs={8} className={"ItemQuantity"}>
                            {item.quantity}
                            {item.getOtherStoreStockButtonVisible ? 
                                <ItemDetailsButton text={"CHECK OTHER STORES"} onClick={handleGetStoreStock}/>
                            : null}
                        </Grid>
                    </Grid>
                </Grid>

                {/* Column 2 */}
                <Grid item xs={12} md={6}> 
                    {/* Code */}
                    <Grid xs={12} item className={"ItemDetail"}>
                        <Grid item xs={3}><b>Code</b></Grid>
                        <Grid item xs={8}>
                            {item.codeDescription === 'Discontinued' ? <Typography color={"secondary"}>Discontinued</Typography> :<div>{item.codeDescription}</div>}
                        </Grid>
                    </Grid>
                    {/* VStock */}
                    {item.hasVstock ? 
                    <Grid xs={12} item className={"ItemDetail"}>
                        <Grid item xs={3}><b>VStock</b></Grid>
                        <Grid item xs={8} className={"ItemVStock"}>
                            {item.vstockQtyVisible ? item.vstock.QTY : null}
                            {item.getVstockButtonVisible ? 
                                <ItemDetailsButton text={"GET VENDOR STOCK"} onClick={handleGetVStock}/>
                            : null}
                        </Grid>
                    </Grid> : null}
                    {/* Description */}
                    <Grid xs={12} item className={"ItemDetail"}>
                        <Grid item xs={3}><b>Desc</b></Grid>
                        <Grid item xs={8} className={"ItemDescription"}>
                            {item.description}
                            {item.hasVstock && item.getSizeVstockButtonVisible ? 
                                <React.Fragment>
                                    {!item.cancelVStockSizeButtonVisible ? 
                                        <ItemDetailsButton text={"GET VSTOCK FOR ALL SIZES"} onClick={handleGetVStockSize} />
                                        :
                                        <CancelDialogButton onClick={()=>props.cancelVStock()}>Cancel Vstock Lookup</CancelDialogButton>
                                    }
                                </React.Fragment>
                            : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    
    const MobileItemDisplay = ({item}) => {
       
        return(
            <React.Fragment>
                {item.showItemInfoVisible ? 
                <div className={"MobileItemInfoContainer"}>
                    <Paper className={"MobilePaperContainer"}>

                        <Typography variant="h6" className={"ItemTitle"}>
                            {props.companyNameText} {props.numberOfStoresText}
                        </Typography>

                        <Divider style={{width: "100%", marginBottom: "10px"}}/>

                        <Image item={item}/>
                        
                        <OkDialogButton onClick={handleAddToCart} className={"cartButton"} startIcon={<AddCartIcon/>}>
                            Add to Cart
                        </OkDialogButton>

                        <Typography variant="subtitle2" className={"MobileItemTitle"}>
                                {item.itemTitle}
                        </Typography>

                    </Paper>

                    <div className={"MobileContainer"}>
                        <div className={"MobileItemDetailContainer"}>
                            <div className={"MobileItemDetail"}>
                                <ItemDetailsList item={item} />
                            </div>
                        </div>
                    </div>
                </div>
                : null}

                
                {item.sizeTableVisible ? 
                     <div className={"MobileSizeTableContainer"}>
                        <SizeTable item={item}/>
                     </div> 
                : null}
            </React.Fragment>
        )
    }

    const DesktopItemDisplay = ({item}) => {
        return(
            <Grid container spacing={2} className={"DesktopItemDisplayContainer"}>
                <Grid item xs={12} md={7}>
                    <Paper className={"DesktopItemDisplayColumn"}>
                        <div className={"DesktopItemDisplayTopContainer"}>

                            <Typography variant="h5" style={{margin: "10px"}}> 
                                    {props.companyNameText}{props.numberOfStoresText}
                            </Typography>

                            <Divider style={{marginBottom: "5px", width: "100%"}}/>
                            
                            <div elevation={5} className={"DesktopItemDisplayImageContainer"}>
                                <Image item={item}/>
                            </div> 

                            <Typography variant="h5" className={"ItemTitle"}>
                                    {item.itemTitle}
                            </Typography>

                            <OkDialogButton onClick={handleAddToCart} className={"cartButton"} startIcon={<AddCartIcon/>}>
                                Add to Cart
                            </OkDialogButton>
                        </div>
                        <Divider className={"ItemDivider"}/>
                        <div className={"showItemInfoVisible"}> 
                            <div className={"ItemDetailContainer"}>
                                <ItemDetailsList item={item} />
                            </div>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    {item.sizeTableVisible ? 
                        <div className={"DesktopItemDisplayColumn"}>
                            <SizeTable item={item}/>
                        </div>
                    : null}
                </Grid>
            </Grid>
        )
    }

    return (
        <React.Fragment>
            {props.loading ? 
                <div className={"circularProgressContainer"}>
                   <CircularProgress/>
                </div>
                :
                <React.Fragment>
                    <ItemHeader/>
                    <Divider/>
                    <Hidden mdUp>
                        <MobileItemDisplay item={props.item}/>
                    </Hidden>
                    <Hidden smDown>
                        <DesktopItemDisplay item={props.item}/>
                    </Hidden>

                    {/* Dialogs */}
                    <SelectColorDialog      
                        open={openSelectColorDialog}        
                        closeDialog={() => setOpenSelectColorDialog(false)}       
                        item={props.item}
                    />
                    <OtherStoreStockDialog  
                        open={props.showOtherStoreStock}    
                        closeDialog={() => props.setShowOtherStoreStock(false)} 
                        item={props.item}
                    />
                    <SizeColorGridDialog    
                        open={props.sizeColorGridOpen}      
                        closeDialog={() => props.setSizeColorGridOpen(false)} 
                        item={props.item}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        item: state.itemReducer.item,
        loading: state.itemReducer.loading,
        newBarcode: state.itemReducer.newBarcode,
        showOtherStoreStock: state.itemReducer.showOtherStoreStock,
        companyNameText: state.storesReducer.companyNameText,
        numberOfStoresText: state.storesReducer.numberOfStoresText
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        itemFetch: (id, history, showInactive, scanned) => dispatch (itemFetch(id, history, showInactive, scanned)),
        fetchOtherStoreStock: (id) => dispatch(fetchOtherStoreStock(id)),
        setShowOtherStoreStock: (show) => {dispatch(setShowOtherStoreStock(show))},
        fetchVStock: (id) => {dispatch(fetchVStock(id))},
        fetchVStockAllSizes: (id) => {dispatch(fetchVStockAllSizes(id))},
        cartAddItem: (item, quantity) => {dispatch(cartAddItem(item, quantity))},
        cancelVStock: () => {dispatch(cancelVStock())},
        loadSizeColorGrid: (barcode) => {dispatch(loadSizeColorGrid(barcode))},
        setSizeColorGridOpen: (open) => {dispatch(setSizeColorGridOpen(open))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)