import axios from 'axios'
import { updateObject } from '../utility'
import { storesSetFromLogin1, storesSetFromLogin2, storesSetFromAccountDialog } from './storesReducer'
import Session from '../../classes/Session'
import makeUuid from '../../utils/makeUuid'
import { hex_hmac_md5 } from '../../utils/md5'
import { getSelectedAccount } from '../../components/Tabs/SettingsTab/Accounts/getSelectedAccount'
import { showToast, TOAST_SEVERITY } from '../../components/Toast/toastReducer'
import { TABBAR_TAB, setTab, disableTab, enableTab } from '../../components/Tabs/TabBar/tabBarReducer'
import { PATHS } from '../../paths'
import isNonBlankString from '../../utils/isNonBlankString'

/*****************************************************************************/

export const currentVersion = 1.300  // ***** rev this when the server api is out of sync. Be sure to adjust setLowerVersion below!!!!

/*****************************************************************************/
// Module data

const LOGIN_SELECTING_ACCOUNT = '/login/LOGIN_SELECTING_ACCOUNT'
const LOGIN_START = '/login/LOGIN_START'
const LOGIN_SUCCESS = '/login/LOGIN_SUCCESS'
const LOGIN_FAILURE = '/login/LOGIN_FAILURE'
const LOGIN_NO_OP = '/login/LOGIN_NO_OP'
const LOGIN_SERVER_ACCESSED = '/login/LOGIN_SERVER_ACCESSED'
const LOGIN_DISCONNECT = '/login/LOGIN_DISCONNECT'
const LOGIN_REGRESS_VERSION = '/login/LOGIN_REGRESS_VERSION'

const CREDENTIAL_FAILURE = '/login/CREDENTIAL_FAILURE'
const CLEAR_LOGIN_ATTEMPTS = '/login/CLEAR_LOGIN_ATTEMPTS'

export const LOGIN_MODE = Object.freeze({
    disconnected: 'Disconnected',
    authenticating: 'Authenticating',
    authenticated: 'Authenticated',
    error: 'Error',
    credentialError: 'credentialError'
})

// const selectedAccount = new Account( //TODO: Change to use user's SelectedAccount
//     'demoowner',
//     'https://shop.theuniformsolution.com',
//     'Owner',
//     'Master',
//     'TEST',
//     1,
//     1,
//     1
// )

const initialState = {
    mode: LOGIN_MODE.disconnected,
    selectingAccount: false,
    serverAccessed: 0,
    lowerVersion: undefined,
    error: null,
    session: new Session(),
    isRetailUser: true,
    retailUserEnabled: false,
    cartEnabled: true,

    failedLoginAttempts: 0,
}

let newestLoginId = null // Used to ensure that only most recent login attempt is allowed to proceed

/*****************************************************************************/
// Action Creators

export const loginSelectingAccount = selectingAccount => ({
    type: LOGIN_SELECTING_ACCOUNT,
    selectingAccount: selectingAccount
})

export const loginStart = () => ({ type: LOGIN_START })

export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    error: error
})

export const credentialFailure = error => ({
    type: CREDENTIAL_FAILURE,
    error: error
})

// Find a better way to ignore requests to login when it's already underway
export const loginNoOp = () => ({ type: LOGIN_NO_OP })

export const loginServerAccessed = () => ({ type: LOGIN_SERVER_ACCESSED })

export const login = (history, editedAccount, reasonCodeAddition, limitToLogin1) => { //editedAccount is passed only by the EditAccounts form, when a temporary login needs to occur for the Options dialog
    return (dispatch, getState) => {
        const selectedAccount = editedAccount || getSelectedAccount(getState().accountsReducer)
        const accounts = getState().accountsReducer.accounts

        newestLoginId = makeUuid(36)
        const newState = { ...getState().loginReducer, loginId: newestLoginId }
        // console.log('%cNew Login ' + newestLoginId, 'color: blue;')

        if (newState.failedLoginAttempts >= 1) {
            //Stop trying to log in.
            dispatch(credentialFailure("Unable to authenticate with server."))
            return
        }

        if (selectedAccount === null && accounts.length > 0) {
            //Selected account was deleted. A new one needs to be created or selected.
            dispatch(disableTabs())
            dispatch(showToast('Please select a new account or create a new one.', TOAST_SEVERITY.warning))
            dispatch(setTab(TABBAR_TAB.settings, history, PATHS.accounts))
            return
        }

        if (selectedAccount) {


            //Additional code for options in account creation
            if (!limitToLogin1) {
                // dispatch(setTab(TABBAR_TAB.dashboard, history))
            }

            login1(dispatch, selectedAccount, newState, reasonCodeAddition, limitToLogin1, history)
        } else {
            dispatch(loginSelectingAccount(true))
            dispatch(showToast('Please add an account.', TOAST_SEVERITY.info))
            dispatch(setTab(TABBAR_TAB.settings, history, PATHS.accounts))
        }
    }
}

const disableTabs = () => {
    return (dispatch, getState) => {
        dispatch(disableTab(TABBAR_TAB.dashboard))
        dispatch(disableTab(TABBAR_TAB.items))
        dispatch(disableTab(TABBAR_TAB.cart))
    }
}

const enableTabs = () => {
    return (dispatch, getState) => {
        dispatch(enableTab(TABBAR_TAB.dashboard))
        dispatch(enableTab(TABBAR_TAB.items))
        dispatch(enableTab(TABBAR_TAB.cart))
    }
}

export const loginDisconnect = () => ({ type: LOGIN_DISCONNECT })


export const reconnectToServer = () => {
    return (dispatch, getState) => {
        dispatch(loginDisconnect())
        dispatch(clearLoginAttempts())
        dispatch(login())
    }
}

export const clearLoginAttempts = () => ({ type: CLEAR_LOGIN_ATTEMPTS })

/*****************************************************************************/
// Reducer

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SELECTING_ACCOUNT: return updateOn_LOGIN_SELECTING_ACCOUNT(state, action)
        case LOGIN_START: return updateOn_LOGIN_START(state, action)
        case LOGIN_SUCCESS: return updateOn_LOGIN_SUCCESS(state, action)
        case LOGIN_FAILURE: return updateOn_LOGIN_FAILURE(state, action)
        case LOGIN_NO_OP: return state
        case LOGIN_SERVER_ACCESSED: return updateOn_LOGIN_SERVER_ACCESSED(state, action)
        case LOGIN_DISCONNECT: return updateOn_LOGIN_DISCONNECT(state, action)
        case CREDENTIAL_FAILURE: return updateOn_CREDENTIAL_FAILURE(state, action)
        case CLEAR_LOGIN_ATTEMPTS: return updateOn_CLEAR_LOGIN_ATTEMPTS(state, action)
        case LOGIN_REGRESS_VERSION: return updateOn_LOGIN_REGRESS_VERSION(state, action)
        default: return state
    }
}

const updateOn_LOGIN_SELECTING_ACCOUNT = (state, action) => (
    updateObject(state, { selectingAccount: action.selectingAccount })
)

const updateOn_LOGIN_START = (state, action) => (
    updateObject(state, { mode: LOGIN_MODE.authenticating })
)

const updateOn_LOGIN_SUCCESS = (state, action) => (
    updateObject(state, {
        mode: LOGIN_MODE.authenticated,
        retailUserEnabled: action.state.retailUserEnabled,
        isRetailUser: action.state.isRetailUser,
        error: null,
        session: {
            ...state.session,
            apiKey: action.state.session.apiToken,
            sessionId: action.state.session.sessionId,
            deviceId: action.state.session.deviceId,
            deviceName: action.state.session.deviceName,
            deviceModel: action.state.session.deviceModel,
            devicePlatform: action.state.session.devicePlatform,
            deviceVersion: action.state.session.deviceVersion
        },
        failedLoginAttempts: 0
    })
)

const updateOn_LOGIN_FAILURE = (state, action) => (
    updateObject(state, {
        mode: LOGIN_MODE.disconnected,
        error: action.error,
        session: new Session(),
        failedLoginAttempts: state.failedLoginAttempts += 1
    })
)

const updateOn_CREDENTIAL_FAILURE = (state, action) => (
    updateObject(state, {
        mode: LOGIN_MODE.credentialError,
        error: action.error,
        session: new Session(),
    })
)

const updateOn_LOGIN_SERVER_ACCESSED = (state, action) => (
    updateObject(state, { serverAccessed: state.serverAccessed + 1 })
)

const updateOn_LOGIN_DISCONNECT = (state, action) => (
    updateObject(state, { mode: LOGIN_MODE.disconnected })
)

const updateOn_CLEAR_LOGIN_ATTEMPTS = (state, action) => (
    updateObject(state, { failedLoginAttempts: 0 })
)

const updateOn_LOGIN_REGRESS_VERSION = (state, action) => {
    // TODO
    console.log("updateOn_LOGIN_REGRESSION_VERSION ", 'color: red; background: white;')
    return updateObject(state, { lowerVersion: action.payload.lowerVersion })
}

/*****************************************************************************/
// Module Functions

const setLowerVersion = (dispatch, newState) => {

    const regressVersion = (lowerVersion, cartEnabled) => ({
        type: LOGIN_REGRESS_VERSION,
        payload: {
            lowerVersion: lowerVersion,
            cartEnabled: cartEnabled,
        }
    })

    // This will not be updated these varables directly.
    switch (workingVersion(newState)) {
        case 1.400: // Dashboard without sales tax
            dispatch(regressVersion(1.300, true))
            return true

        case 1.300: // Carts
            dispatch(regressVersion(1.200, false))
            return true

        case 1.200: // Multi-Store Inventory
            dispatch(regressVersion(1.100, false))
            return true

        default:
            return false
    }
};

const workingVersion = state => {
    return state.lowerVersion || currentVersion
}

const sessionIdParm = (selectedAccount, state) => {
    if (state.session.sessionId === '') return ''

    let sidSuffix = ""
    const newNetTalk = (workingVersion(state) >= 1.300)
    const insecureServer = (selectedAccount.hostName.substring(0, 5).toLowerCase() === "http:")
    if (newNetTalk && insecureServer) {
        // Starting with 1.300, this session id suffix was added for insecure connections
        sidSuffix = "X"
    }
    return "&SESSIONID" + sidSuffix + "=" + state.session.sessionId
}

/*****************************************************************************/
// login1 Functions

// login1 does the first call to the WebServer apiLogin. It retrieves version information, the store list, etc.
// On the happy path, it calls login2 to perform the actual login, which retrieves the token from the server.

const login1 = (dispatch, selectedAccount, newState, reasonCodeAddition, limitToLogin1, history) => {

    console.log("%cLogin1 working verison " + workingVersion(newState).toFixed(3), "color: orange;")

    if (!newState.lowerVersion && newState.mode === LOGIN_MODE.authenticating) {
        dispatch(loginNoOp())
    } else {
        dispatch(loginStart())
        console.log(selectedAccount)

        axios
            .get(selectedAccount.hostName
                + '/apiLogin'
                + '?d=' + newState.loginId
                + '&dm=PWA' // '&dm=' + 'PWA' //Cordova device.model or DevExpress.devices.current().platform
                + '&dp=PWA' // '&dp=' + 'PWA' //DevExpress.devices.current().platform
                + '&dv=0.x' // '&dv=' + '0.x' //Cordova device.version
                + '&apiVersion=' + workingVersion(newState).toFixed(3)
                + sessionIdParm(selectedAccount, newState)
                + (reasonCodeAddition ? '&ra=' + reasonCodeAddition : '')
            )
            .then((response) => {
                if (newState.loginId !== newestLoginId) {
                    // console.log('%cAborted login1 ' + newState.loginId, 'color: red;')
                    return
                }
                // console.log('%cProceeding login1 ' + newState.loginId, 'color: green;')
                dispatch(enableTabs())
                takeLogin1Response(dispatch, selectedAccount, newState, reasonCodeAddition, response, limitToLogin1, history)
            })
            .catch((error) => {
                //update failedLoginAttempts, disable unusable tabs
                dispatch(disableTabs())
                takeLogin1Error(dispatch, selectedAccount, newState, error, limitToLogin1, history)
            })
    }
}

const takeLogin1Response = (dispatch, selectedAccount, newState, reasonCodeAddition, response, limitToLogin1, history) => {

    console.log("%cLogin1Response working verison " + workingVersion(newState).toFixed(3), "color: orange;")

    newState.serverAccessed++ // Used to force re-login after approximately 15 mins
    if (typeof response.data === 'string') { // device will return 200 OK if url or ip is wrong, but data will not contain json
        console.log('takeLogin1Response/A', response)

        dispatchLoginFailure(dispatch, {
            message: 'Unable to connect, please check that the Web Server Domain or IP in the account settings is correct.',
            title: 'Check Server Settings',
            error: response.data
        })
    } else {
        console.log('takeLogin1Response/B', response)
        newState.session.apiKey = response.data.S

        if (response.data.S === "" || isNonBlankString(response.data.APIVERINFO)) {
            newState.session.sessionId = "";

            console.log('APIVERINFO=' + response.data.APIVERINFO);
            if (isNonBlankString(response.data.APIVERINFO)) {
                if (setLowerVersion(dispatch, newState)) {
                    console.log("%cLogin1Response2 working verison " + workingVersion(newState).toFixed(3), "color: orange;")
                    dispatch(reconnectToServer())
                    return;
                } else {
                    //         var apiVerMessage;
                    //         apiVerMessage = data.APIVERINFO !== undefined ? data.APIVERINFO : 'You must upgrade The Uniform Solution+ server to use The Uniform Solution mobile app.';
                    //         DevExpress.ui.dialog.alert(apiVerMessage, 'Version Conflict!');
                    dispatch(showToast('You must upgrade The Uniform Solution+ server to use The Uniform Solution mobile app.', TOAST_SEVERITY.error))
                    dispatch(disableTabs())
                    dispatch(setTab(TABBAR_TAB.settings, history, PATHS.accounts))
                }
            } else {
                //     DevExpress.ui.dialog.alert('Unable to get a session key from the server at this time, please contact tech support.', 'No Session Key');
                dispatch(showToast('Unable to get a session key from the server at this time, please contact tech support.', TOAST_SEVERITY.error))
                dispatch(disableTabs())
                dispatch(setTab(TABBAR_TAB.settings, history, PATHS.accounts))
            }

        } else {
            newState.session.sessionId = response.data.SESSIONID;

            if (limitToLogin1) {
                dispatch(storesSetFromAccountDialog(response.data.STORES))
                return
            }

            //Populates stores list in storesReducer.
            dispatch(storesSetFromLogin1(response.data))

            login2(dispatch, selectedAccount, newState, reasonCodeAddition, history)
        }
    }
}

const takeLogin1Error = (dispatch, selectedAccount, newState, error, limitToLogin1, history) => {
    // return(dispatch, getState) => {

    console.log("Unable to login!")

    if (limitToLogin1) {
        console.log("MODE: ", newState.mode)
        dispatch(showToast('Unable to load store list', TOAST_SEVERITY.error))
        //Need to set mode to: creditials error

        dispatchCredentialFailure(dispatch, {
            message: 'Unable to validate account credentials.',
            title: 'Account Credential Error',
            error: error
        })
        return
    }

    console.log('takeLogin1Error', error)

    dispatch(setTab(TABBAR_TAB.settings, history, PATHS.accounts))

    dispatchLoginFailure(dispatch, {
        message: 'Unable to contact the server.',
        title: 'Login Error',
        error: error
    })
}

/*****************************************************************************/
// login2 Functions
// Once the stage has been set by login1, login2 fetches the authentication token

const login2 = (dispatch, selectedAccount, newState, reasonCodeAddition, history) => {
    axios
        .get(selectedAccount.hostName
            + '/apiLogin'
            + "?info=" + selectedAccount.userName
            + "&isn=" + selectedAccount.inventoryStoreNumber
            + "&n=" + hex_hmac_md5(newState.session.apiKey, selectedAccount.password.trim().toUpperCase())
            + sessionIdParm(selectedAccount, newState)
            + (reasonCodeAddition ? '&ra=' + reasonCodeAddition : '')
        )
        .then((response) => {
            if (newState.loginId !== newestLoginId) {
                // console.log('%cAborted login2 ' + newState.loginId, 'color: red;')
                return
            }
            // console.log('%cProceeding login2 ' + newState.loginId, 'color: green;')
            takeLogin2Response(dispatch, selectedAccount, newState, response, history)
        })
        .catch((error) => {
            dispatch(disableTabs())
            takeLogin2Error(dispatch, error, history)
        })
}

const takeLogin2Response = (dispatch, selectedAccount, newState, response, history) => {
    // console.log('Login Stage 2 Then', response2)

    if (response.data.TOKEN === "") {
        //TODO
        //var dialog = DdsDash.showLoginFailNotification(response.data.FAILREASON);  // This was called 7 times after a timed out session, with FAILREASON=1 (Login not accepted). Unresolved!
        // dialog.done(function () {
        //     newState.loggingIn = false;
        //     if (successCallback !== undefined && failureCallback) {
        //         successCallback();
        //     }
        // })

        dispatch(setTab(TABBAR_TAB.settings, history, PATHS.accounts))
        dispatch(disableTabs())
        dispatchCredentialFailure(dispatch, {
            message: 'Unable to validate account credentials.',
            title: 'Account Credential Error',
            error: 'Credential Error'
        })

    } else {
        dispatch(storesSetFromLogin2(response.data, selectedAccount))

        const enableToast = Boolean(history)
        dispatchLoginSuccess(dispatch, selectedAccount, newState, response.data, enableToast)

        dispatch(enableTabs())
    }
}

const takeLogin2Error = (dispatch, error, history) => {
    console.log('Catch Login State 2', error)
    dispatch(disableTabs())

    //TODO: Check if error has something useful to display
    // dispatchLoginFailure(dispatch, {
    //     message: 'Unable to contact the server.',
    //     title: 'Check Server Settings',
    //     error: error
    // })
    dispatch(setTab(TABBAR_TAB.settings, history, PATHS.accounts))
    dispatchCredentialFailure(dispatch, {
        message: 'Unable to validate account credentials.',
        title: 'Account Credential Error',
        error: 'Credential Error'
    })

}

const dispatchLoginSuccess = (dispatch, selectedAccount, newState, data, enableToast = true) => {
    newState.serverAccessed++
    newState.session.apiToken = data.TOKEN
    newState.session.sessionId = data.SESSIONID

    newState.retailUserEnabled = (data.ISRETAILUSER !== undefined)
    newState.isRetailUser = !newState.retailUserEnabled ? false : (data.ISRETAILUSER === 1)
    console.log('ISRETAILUSER', typeof data.ISRETAILUSER, data.ISRETAILUSER, newState.retailUserEnabled, (data.ISRETAILUSER === 1), newState.isRetailUser)

    newState.session.access34 = (data.ACCESS34 > 0)
    newState.session.access36 = (data.ACCESS36 > 0)
    newState.session.access37 = (data.ACCESS37 > 0)
    newState.session.access79 = (data.ACCESS79 > 0)
    newState.session.sessionId = data.SESSIONID

    //TODO: The DashbaordTab needs to render based upon this type of logic
    // if (newState.isRetailUser) {
    //     DdsDash.app.navigation[0].option('title', "Store");
    //     DdsDash.app.navigation[0].option('icon', "home");
    //     DdsDash.app.navigation[2].option('visible', false);
    // } else {
    //     DdsDash.app.navigation[0].option('title', "Dashboard");
    //     DdsDash.app.navigation[0].option('icon', "chart");
    //     DdsDash.app.navigation[2].option('visible', DdsDash.cartEnabled);
    // }

    //TODO: DevExpress.ui.notify("You are now logged into the server.", "success", 1000);

    if (enableToast) dispatch(showToast('Login Success!', TOAST_SEVERITY.success))
    dispatch({ type: LOGIN_SUCCESS, state: newState })
}

const dispatchLoginFailure = (dispatch, error) => {
    console.log('dispatchLoginFalure', error)
    dispatch(showToast(error.message || 'Login Error!', TOAST_SEVERITY.error, 6000))
    dispatch(loginFailure(error))
}

const dispatchCredentialFailure = (dispatch, error) => {
    console.log('dispatchCredentialFailure', error)
    dispatch(showToast(error.message || 'Credential Error!', TOAST_SEVERITY.error, 6000))
    dispatch(credentialFailure(error))
}
