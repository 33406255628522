import React from 'react'
import { TextField } from '@material-ui/core'
import { Field, ErrorMessage } from 'formik'

const FormikField = (props) => {
    const { classes, children, className, endAdornment, isValid, ...other } = props
    return (
        <Field
            autoComplete='none'
            autoCapitalize='none'
            as={TextField}
            helperText={<ErrorMessage name={props.name} />}
            fullWidth
            error={props.isValid}
            InputProps={{ endAdornment: endAdornment || null}} 
            {...other}
        />
    )
}

export default FormikField
