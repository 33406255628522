import { useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import Quagga from '@ericblade/quagga2'
import './Scanner.css'
import React from 'react'

let possibleCodes = []

// Used to make scanner more accurate
function getMedian(arr) {
    arr.sort((a, b) => a - b)
    const half = Math.floor(arr.length / 2)
    if (arr.length % 2 === 1) {
        return arr[half]
    }
    return (arr[half - 1] + arr[half]) / 2
}

// Used to make scanner more accurate
function getMedianOfCodeErrors(decodedCodes) {
    const errors = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error)
    const medianOfErrors = getMedian(errors)
    return medianOfErrors
}

//Set size of the Camera Display
function getContraints() {
    if (window.innerWidth > 960) {
        return { width: 600, height: 650 }
    } else {
        return { width: window.innerWidth, height: window.innerHeight }
    }
}

function analyzePossibleCodes(codes){

  codes.sort()
  var counts = {};

  for (var i = 0; i < codes.length; i++) {
    var num = codes[i];
    counts[num] = counts[num] ? counts[num] + 1 : 1;
  }

  let numList = Object.keys(counts)

  for(num of numList){
    if(counts[num] > 4){
      return num
    }
  }

  return false
}

const defaultLocatorSettings = {
    patchSize: 'medium',
    halfSample: true,
}

// const defaultDecoders = ['ean_reader', 'i2o5_reader', '2o5_reader']
const defaultDecoders = [
    'code_128_reader',
    // 'ean_reader',
    // 'ean_8_reader',
    // 'code_39_reader',
    // 'code_39_vin_reader',
    // 'codabar_reader',
    'upc_reader',
    // 'upc_e_reader',
    'i2of5_reader',
    // '2of5_reader',
    // 'code_93_reader',
]

const Scanner = ({
    onDetected,
    scannerRef,
    onScannerReady,
    cameraId,
    facingMode,
    constraints = getContraints(),
    locator = defaultLocatorSettings,
    numOfWorkers = navigator.hardwareConcurrency || 0,
    decoders = defaultDecoders,
    locate = false,
    single,
}) => {

    React.useEffect(()=>{
      possibleCodes = []
    })

    const errorCheck = useCallback(
        (result) => {
            if (!onDetected) {
                return
            }
            const err = getMedianOfCodeErrors(result.codeResult.decodedCodes)

            if (err < 0.16 && validateBarcode(result.codeResult.format, result.codeResult.code)) {
                
                possibleCodes.push(result.codeResult.code)
                
                if(possibleCodes.length > 10){
                    possibleCodes.shift()
                }

                // console.log("Possible codes: ", possibleCodes)
                // console.log("Length: ", possibleCodes.length)

                if(possibleCodes.length >= 10){
                  let finalCode = analyzePossibleCodes(possibleCodes)

                  if(finalCode){
                    onDetected(finalCode)
                  }
                }   
            }
            // console.log(result.codeResult.code, err)
        },
        [onDetected]
    )

    const validateBarcode = (type, code) => {
        console.log('BarCode Type: ' + type)
        switch (type) {
            case 'i2of5_reader':
                return code.length >= 8
            default:
                return true
        }
    }

    const handleProcessed = (result) => {
        // Make sure the code is correct
        // var drawingCtx = Quagga.canvas.ctx.overlay,
        // drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        // if (result.boxes) {
        //   drawingCtx.clearRect(
        //     0,
        //     0,
        //     parseInt(drawingCanvas.getAttribute("width")),
        //     parseInt(drawingCanvas.getAttribute("height"))
        //   );
        //   result.boxes
        //     .filter(function(box) {
        //       return box !== result.box;
        //     })
        //     .forEach(function(box) {
        //       Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
        //         color: "green",
        //         lineWidth: 2
        //       });
        //     });
        // }

        // if (result.box) {
        //   Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
        //     color: "#00F",
        //     lineWidth: 2
        //   });
        // }

        // if (result.codeResult && result.codeResult.code) {
        //   Quagga.ImageDebug.drawPath(
        //     result.line,
        //     { x: "x", y: "y" },
        //     drawingCtx,
        //     { color: "red", lineWidth: 3 }
        //   );
        // }
      }
    }

    useLayoutEffect(() => {
        if (scannerRef.current == null) {
            return
        }
        Quagga.init(
            {
                inputStream: {
                    type: 'LiveStream',
                    constraints: {
                        ...constraints,
                        ...(cameraId && { deviceId: cameraId }),
                        ...(!cameraId && { facingMode }),
                    },
                    target: scannerRef.current,
                    area: { // defines rectangle of the detection/localization area
                        top: "10%",    // top offset
                        // right: "5%",  // right offset
                        // left: "5%",   // left offset
                        bottom: "50%"  // bottom offset
                    },
                },
                locator,
                numOfWorkers,
                decoder: { readers: decoders },
                locate: false,

            },
            (err) => {
                Quagga.onProcessed(handleProcessed)

                if (err) {
                    return console.log('Error starting Quagga:', err)
                }

                if (scannerRef && scannerRef.current) {
                    Quagga.start()

                    if (onScannerReady) {
                        onScannerReady()
                    }
                }
            }
        )
        Quagga.onDetected(errorCheck)
        return () => {
            Quagga.offDetected(errorCheck)
            Quagga.offProcessed(handleProcessed)
            Quagga.stop()
        }
    }, [
        cameraId,
        onDetected,
        onScannerReady,
        scannerRef,
        errorCheck,
        constraints,
        locator,
        decoders,
        locate,
        facingMode,
        numOfWorkers,
    ])
    return null
}

Scanner.propTypes = {
    onDetected: PropTypes.func.isRequired,
    scannerRef: PropTypes.object.isRequired,
    onScannerReady: PropTypes.func,
    cameraId: PropTypes.string,
    facingMode: PropTypes.string,
    constraints: PropTypes.object,
    locator: PropTypes.object,
    numOfWorkers: PropTypes.number,
    decoders: PropTypes.array,
    locate: PropTypes.bool,
}

export default Scanner
