// Global Feature Switches and related support variables
// DdsDash.otherStoreStockEnabled = true;  // requires server api version 1.200
// DdsDash.retailUserEnabled = true;       // requires server api version 1.200 - Driven by presence of ISRETAILUSER attribute from apiLogin
// DdsDash.cartEnabled = true;             // requires server api version 1.300

export const currentVersion = 1.300 // ***** rev this when the server api is out of sync. Be sure to adjust setLowerVersion below!!!!
export const currentVersionText = '1.3.10'

export const firstServerVersionToUseIdSuffix = 1.300

// TODO: This is used directly by the loginReducer, so perhaps some it it will go there
// DdsDash.setLowerVersion = function () {
//     //alert('Testing ' + DdsDash.version())
//     switch (DdsDash.version()) {
//         case 1.300: // Carts
//             DdsDash.lowerVersion = 1.200
//             DdsDash.cartEnabled = false
//             DdsDash.tryingLowerVersion = true
//             //DevExpress.ui.dialog.alert('Version set to ' + DdsDash.version())
//             return true

//         case 1.200: // Multi-Store Inventory
//             DdsDash.lowerVersion = 1.100
//             DdsDash.otherStoreStockEnabled = false
//             DdsDash.tryingLowerVersion = true
//             //DevExpress.ui.dialog.alert('Version set to ' + DdsDash.version())
//             return true
//     }
//     return false
// }

// TODO: This is used directly by the loginReducer, so perhaps some it it will go there
// DdsDash.resetVersion = function () {
//     DdsDash.tryingLowerVersion = false
//     DdsDash.lowerVersion = undefined
//     DdsDash.otherStoreStockEnabled = true
//     DdsDash.cartEnabled = true
//     //DevExpress.ui.dialog.alert('Version reset to ' + DdsDash.version())
// };

export const workingVersion = (loginState) => {
    if (loginState && loginState.lowerVersion) {
        return loginState.lowerVersion
    } else {
        return currentVersion
    }
}
