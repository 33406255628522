import React from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogTitle } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import FindItemForm from "../FindItemForm/FindItemForm"
import FindItemResultsDialog from "../FindItemResultsDialog/FindItemResultsDialog"
import { setSearchFormContents } from '../../Item/itemReducer'

const FindItemDialog = (props) => {

    const history = useHistory()
    const [title, setTitle] = React.useState('Search for an Item')

    const onFormClose = () => {
        props.setSearchFormContents({})
        history.goBack()
    }
    
    return (
        <React.Fragment>
            <Dialog
                open={true}
                onClose={onFormClose}
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle id='findtem-dialog-title'>{title}</DialogTitle>
                    {(!props.showSearchResults) ?
                        <FindItemForm onClose={onFormClose}/>
                        :
                        <FindItemResultsDialog setTitle={setTitle}/>
                    }
            </Dialog>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        showSearchResults:  state.itemReducer.showSearchResults,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSearchFormContents: (contents) => dispatch(setSearchFormContents(contents))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindItemDialog)