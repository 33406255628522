import axios from 'axios'
import { updateObject } from '../../../redux/utility'
import { showToast, TOAST_SEVERITY } from '../../Toast/toastReducer'
import PATHS from '../../../paths'
import getSelectedAccount from '../SettingsTab/Accounts/getSelectedAccount'
import InventoryItem from '../../../classes/InventoryItem'
import { ApiCart } from '../../../classes/ApiCart'
import { loadFromLocalStorage, saveToLocalStorage } from '../../../utils/localStorage'
import makeUuid from '../../../utils/makeUuid'
import sessionIdParm from '../../../utils/sessionIdParm'
import apiGetProduct from '../../../utils/apiGetProduct'
import { Cart } from '../../../classes/Cart'

/*****************************************************************************/
// Module data

const CART_CREATE_CART = '/cart/CART_CREATE_CART'
const CART_LOAD_CART = '/cart/CART_LOAD_CART'
const CART_SET_CART = '/cart/CART_SET_CART'
const CART_SET_NAME = '/cart/CART_SET_NAME'
const CART_SET_ITEM_QUANTITY = '/cart/CART_SET_ITEM_QUANTITY'
const CART_DELETE_DETAIL = '/cart/CART_DELETE_DETAIL'
const CART_EMPTY = '/cart/CART_EMPTY'
const CART_SWITCH_CART = '/cart/CART_SWITCH_CART'
const CART_DELETE_CART = '/cart/CART_DELETE_CART'

export const CART_ENTER_ITEM = '/cart/CART_ENTER_ITEM'

const initialState = {
    cart: {
        // uuid: makeUuid(), // UUID to distinguish this from others
        // accountKey: '', // User's account
        // storeNumber: 0, // Store for which the cart applied
        // name: '', // Name of the cart (for submitting to the POS)
        // type: 'Retail', // For now, just retail. Eventually there will also be PurchaseOrder
        // date: new Date(0), // When was the cart created?
        // posted: false, // Was this cart posted? If so, then additional posts must update the existing cart.
        // details: [], // CartDetail objects
    },
    carts: []  // Eventually we'll have the option for multiple carts, but for now this is unused
}

/*****************************************************************************/
// Action Creators

//When new account is created, create a new cart. Save to local storage.
export const createCart = (accountKey) => {
    return(dispatch)=> {
        const cart = new Cart();
        cart.accountKey = accountKey
        
        let carts = loadFromLocalStorage('carts') || []
        carts.push(cart)

        saveToLocalStorage('cart', cart)
        saveToLocalStorage('carts', carts)

        dispatch({
            type: CART_CREATE_CART,
            cart: cart,
        })
    }
}


//Load the preferences from the local storage
export const loadCart = () => {
    return (dispatch) => {
        const cart = loadFromLocalStorage('cart') || { ...initialState.cart }
        const carts = loadFromLocalStorage('carts') || []

        dispatch({
            type: CART_LOAD_CART,
            cart: cart,
            carts: carts
        })
    }
}

export const setCart = cart => ({
    type: CART_SET_CART,
    cart: cart
})

export const setCartName = name => ({
    type: CART_SET_NAME,
    name: name
})

export const cartAddItem = (item, quantity) => ({
    type: CART_SET_ITEM_QUANTITY,
    detail: {
        item: item,
        quantity: quantity || 1,
        incrementQuantity: true
    }
})

export const cartFetchBarcodeThenAdd = (barcode, callback) => {
    return (dispatch, getState) => {
        const selectedAccount = getSelectedAccount(getState().accountsReducer)
        const session = getState().loginReducer.session

        const executeCallback = success => {
            if (callback && typeof (callback) === 'function') {
                callback(success)
            }
        }

        const doneCallback = (data) => {
            const item = new InventoryItem(data)
            item.loadItem(data, selectedAccount, session)
            dispatch(cartAddItem(item))
            dispatch(showToast('Item added to cart', TOAST_SEVERITY.info))
            executeCallback(true)
        }

        const failCallback = (error) => {
            dispatch(showToast('Item not found!', TOAST_SEVERITY.error))
            executeCallback(false)
        }

        const showInactive = true
        const scanned = true

        apiGetProduct(selectedAccount.hostName, session,
            barcode, showInactive, scanned,
            doneCallback, failCallback)
    }
}

export const cartSetItemQuantity = (item, quantity) => {
    return (dispatch) => {
        if (quantity > 0) {
            dispatch({
                type: CART_SET_ITEM_QUANTITY,
                detail: {
                    item: item,
                    quantity: quantity,
                }
            })
        } else {
            dispatch(cartDeleteDetail(item))
        }
    }
}

export const cartDeleteDetail = itemOrBarcode => ({
    type: CART_DELETE_DETAIL,
    detail: {
        item: composeItem(itemOrBarcode)
    }
})

export const emptyCart = () => ({
    type: CART_EMPTY
})

export const cartSaveToPOS = (name, history) => {
    return (dispatch, getState) => {
        const selectedAccount = getSelectedAccount(getState().accountsReducer)
        const session = getState().loginReducer.session
        const apiCart = new ApiCart(getState().cartReducer.cart, name, selectedAccount.userName)

        axios({
            url: selectedAccount.hostName
                + "/apiPostCart"
                + "?apiToken=" + session.apiToken
                + sessionIdParm(session.sessionId, selectedAccount.hostName),
            headers: { 'content-type': 'text/plain' },
            data: JSON.stringify(apiCart),
            method: 'post',
            timeout: 10000
        })
            .then((response) => {
                console.log('apiPostCart/then', response)
                dispatch(showToast(`Cart "${name}" saved to POS`, TOAST_SEVERITY.info))
                dispatch(setCartName(name))
                history.replace(PATHS.cartEmptyAfterSaveToPOS)
            })
            .catch((error) => {
                console.log('apiPostCart/catch', error)
                dispatch(showToast('Cart could not be saved!', TOAST_SEVERITY.error))
            })
    }
}

export const switchCart = (accountKey) => {
    //Swap state.cart with cart from local storage.
    //Save updated cart to local storage.
    return(dispatch, getState) => {

        let carts = getState().cartReducer.carts
        let currentlySelectedCart = getState().cartReducer.cart
        let newCart = null

        //Update carts with any changes.
        for(var i=0; i < carts.length; i++){
            if(carts[i].accountKey === currentlySelectedCart.accountKey){
                carts[i] = currentlySelectedCart
            }
            if(carts[i].accountKey === accountKey){
                newCart = carts[i]
            }
        }
        saveToLocalStorage('cart', newCart)
        saveToLocalStorage('carts', carts)
        dispatch({
            type: CART_SWITCH_CART,
            cart: newCart,
            carts: carts
        })
    }
}

export const deleteCart = (accountKey) => {
    //Delete cart from localStorage

    return(dispatch, getState) => {
        let activeCart = getState().cartReducer.cart
        let carts = getState().cartReducer.carts
        let deleteActiveCart = (accountKey === activeCart.accountKey)
        var filteredCarts = carts.filter(cart => (cart.accountKey !== accountKey))

        saveToLocalStorage('cart', deleteActiveCart ? {} : activeCart)
        saveToLocalStorage('carts', filteredCarts)

        dispatch({
            type: CART_DELETE_CART,
            cart: deleteActiveCart ? {} : activeCart,
            carts: filteredCarts
        })
    }
}

/*****************************************************************************/
// Reducer

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_CREATE_CART: return updateOn_CART_CREATE_CART(state, action)
        case CART_LOAD_CART: return updateOn_CART_LOAD_CART(state, action)
        case CART_SET_CART: return updateOn_CART_SET_CART(state, action)
        case CART_SET_NAME: return updateOn_CART_SET_NAME(state, action)
        case CART_ENTER_ITEM: return updateOn_CART_ENTER_ITEM(state, action)
        case CART_SET_ITEM_QUANTITY: return updateOn_CART_SET_ITEM_QUANTITY(state, action)
        case CART_DELETE_DETAIL: return updateOn_CART_DELETE_DETAIL(state, action)
        case CART_EMPTY: return updateOn_CART_EMPTY(state, action)
        case CART_SWITCH_CART: return udpateOn_CART_SWITCH_CART(state, action)
        case CART_DELETE_CART: return updateOn_CART_DELETE_CART(state, action)
        default: return state
    }
}

const updateOn_CART_CREATE_CART = (state, action) => {
    return updateObject(state, { cart: action.cart, carts: [...state.carts, action.cart] })
}

const updateOn_CART_LOAD_CART = (state, action) => {
    checkAndSaveCart(action.cart)
    return updateObject(state, { cart: action.cart, carts: action.carts })
}

const updateOn_CART_SET_CART = (state, action) => {
    const cart = {
        uuid: action.cart.uuid,
        accountKey: action.cart.accountKey,
        storeNumber: action.cart.storeNumber,
        name: action.cart.name,
        type: action.cart.type,
        date: action.cart.date,
        posted: action.cart.posted,
        details: []
    }
    action.cart.details.forEach(detail => {
        addDetail(cart.details, detail)
    })
    checkAndSaveCart(cart)
    return updateObject(state, { cart: cart })
}

const updateOn_CART_SET_NAME = (state, action) => {
    const cart = {
        ...state.cart,
        details: [...state.cart.details],
        name: action.name
    }
    checkAndSaveCart(cart)
    return updateObject(state, { cart: cart })
}

const updateOn_CART_ENTER_ITEM = (state, action) => {
    // dispatch(showToast('Item not found!', TOAST_SEVERITY.error))
    if ('item' in action) {
        action.type = CART_SET_ITEM_QUANTITY
        action.detail = {
            item: action.item,
            quantity: 1,
            incrementQuantity: true
        }
        delete action.item
        return updateOn_CART_SET_ITEM_QUANTITY(state, action)
    }
    return state
}

const updateOn_CART_SET_ITEM_QUANTITY = (state, action) => {
    const cart = {
        ...state.cart,
        details: [...state.cart.details],
    }
    const foundIndex = cart.details.findIndex(detail => detail.item.barcode === action.detail.item.barcode)
    if (foundIndex >= 0) {
        if (action.detail.incrementQuantity) {
            cart.details[foundIndex].quantity += action.detail.quantity
        } else {
            cart.details[foundIndex].quantity = action.detail.quantity
        }
    } else {
        addDetail(cart.details, action.detail)
    }
    checkAndSaveCart(cart)
    return updateObject(state, { cart: cart })
}

const updateOn_CART_DELETE_DETAIL = (state, action) => {
    const cart = {
        ...state.cart,
        details: state.cart.details.filter(detail => (detail.item.barcode !== action.detail.item.barcode)),
    }
    if (cart.details.length === 0) {
        cart.name = ''
    }
    checkAndSaveCart(cart)
    return updateObject(state, { cart: cart })
}

const updateOn_CART_EMPTY = (state, action) => {
    const cart = { ...state.cart, details: [], name: '' }
    checkAndSaveCart(cart)
    return updateObject(state, { cart: cart })
}

const udpateOn_CART_SWITCH_CART = (state, action) => {
    return updateObject(state, { cart: action.cart, carts: action.carts})
}

const updateOn_CART_DELETE_CART = (state, action) => {
    return updateObject(state, { cart: action.cart, carts: action.carts})
}

/*****************************************************************************/
// Internal Functions

const addDetail = (details, detail) => {
    details[details.length] = {
        quantity: detail.quantity,
        item: { ...detail.item }
    }
}

const composeItem = itemOrBarcode => {
    if (typeof itemOrBarcode === 'object' && 'barcode' in itemOrBarcode) {
        return { ...itemOrBarcode }
    }
    return { barcode: itemOrBarcode }
}

const checkAndSaveCart = cart => {
    if (Array.isArray(cart?.details)) {
        if (cart.details.length === 0) {
            cart.date = new Date(0)
        } else if (!cart.date) {
            cart.date = new Date()
        }
    }
    saveToLocalStorage('cart', cart)
}

/*****************************************************************************/
// Exported Functions

export const getItemQuantity = (cart, itemOrBarcode) => {
    var barcode
    if (typeof itemOrBarcode === 'object' && 'barcode' in itemOrBarcode) {
        barcode = itemOrBarcode.barcode
    } else {
        barcode = itemOrBarcode
    }
    if (barcode) {
        const foundIndex = cart.details.findIndex(detail => detail.item.barcode === barcode)
        if (foundIndex >= 0) {
            return cart.details[foundIndex].quantity
        }
    }
    return 0
}

export const getDetail = (details, parm) => {
    const findFirst = barcode => {
        const detail = details.filter(d => d.item.barcode === barcode)
        if (detail.length > 0) return detail[0]
    }

    if (parm?.barcode) return findFirst(parm.barcode)
    if (parm?.item?.barcode) return findFirst(parm.item.barcode)
    return findFirst(parm)
}
