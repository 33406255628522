import React from 'react'
import PropTypes from 'prop-types'
import AlertDialog from '../../../../AlertDialog/AlertDialog'
import { updateSelectedAccount, deleteAccount } from '../accountsReducer'
import getAccountName from '../getAccountName'
import isNonBlankString from '../../../../../utils/isNonBlankString'
import { connect } from 'react-redux'

const DeleteAccountDialog = (props) => {
    const onAgree = () => {
        props.deleteAccount(props.accountKey)
        if (props.accountsState.selectedAccount === props.accountKey) {
            props.updateSelectedAccount(null)
        }
    }

    const accountName = getAccountName(props.accountsState, props.accountKey)

    return (
        <AlertDialog
            open={props.open && isNonBlankString(props.accountKey)}
            setOpen={props.setOpen}
            title='Confirm Delete'
            message={'Are you sure you want to delete ' + (accountName ? 'the "' + accountName + '"' : 'this') + ' account?'}
            onAgree={onAgree}
        />
    )
}

DeleteAccountDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    accountKey: PropTypes.string,
}

const mapStateToProps = (state) => {
    return {
        accountsState: state.accountsReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedAccount: (accountKey) => dispatch(updateSelectedAccount(accountKey)),
        deleteAccount: (accountKey) => dispatch(deleteAccount(accountKey)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountDialog)
