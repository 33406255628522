import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel';

export const ClearFieldButton = ({visible, onClick}) => {
    if(visible){
        return(
            <CancelIcon color={'primary'} position={'end'} onClick={onClick} style={{cursor: 'pointer'}}/>
        )
    }
    return null
}