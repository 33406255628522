import React from 'react'
import QuantityDialog from './QuantityDialog/QuantityDialog'

const Quantity = (props) => {
    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState(props.initialValue >= 0 ? props.initialValue : 0)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    //Confirm Value is callback function!
    const onConfirm = () => {
        props.confirmValue(value)
        setOpen(false)
    }

    return (
        <React.Fragment>
            <div onClick={handleClickOpen}>
                {/* Click on Children to open dialog */}
                {props.children}
            </div>
            <QuantityDialog
                isOpen={open}
                onClose={handleClose}
                setValue={setValue}
                value={value}
                title={props.title}
                onConfirm={onConfirm}
            ></QuantityDialog>
        </React.Fragment>
    )
}

export default Quantity
