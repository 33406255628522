import React from 'react'
import MenuButtonWithChildren from '../../../../hoc/MenuButtonWithChildren/MenuButtonWithChildren'
import { EnterBarcodeIcon } from '../../../icons'
import EnterItemDialog from './EnterItemDialog/EnterItemDialog'
import { PATHS } from '../../../../paths'

export default () => (
    <MenuButtonWithChildren buttonText='Enter Barcode' buttonIcon={EnterBarcodeIcon} childPath={PATHS.enterItem}>
        <EnterItemDialog />
    </MenuButtonWithChildren>
)
