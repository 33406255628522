import React, { Fragment, useEffect } from 'react'
import { Container, CircularProgress } from '@material-ui/core'
import PullToRefresh from 'react-simple-pull-to-refresh'
import { connect } from 'react-redux'
import { LOGIN_MODE, loginDisconnect } from '../../../redux/reducers/loginReducer'
import { getSelectedAccount } from '../SettingsTab/Accounts/getSelectedAccount'
import { inList } from '../../../utils/inList'
import { thisYear, lastYear, thisMonth, thisDay } from '../../../utils/dates'
import { useInterval } from '../../../utils/useInterval'
import { DASHBOARD_MODE, dashboardSetMode } from './dashboardReducer'

import './Dashboard.css'

const DashboardTab = (props) => {
    console.log('DashboardTab/IN', props.mode, props.visible)

    const selectedAccount = getSelectedAccount(props.accountsState)
    const userName = (selectedAccount && selectedAccount.userName) ? selectedAccount.userName : ''
    const isEmployeeUser = (!props.isRetailUser)

    const isLoaded = () => (inList(props.mode, [
        DASHBOARD_MODE.blank,
        DASHBOARD_MODE.dashboard,
        DASHBOARD_MODE.store
    ]))
    const isLoading = () => (props.loginmode === LOGIN_MODE.authenticating || !isLoaded())

    const setMode = mode => {
        props.dashboardSetMode(mode, selectedAccount, props.session)
    }

    useEffect(() => {
        if (props.loginMode === LOGIN_MODE.authenticated) {

            switch (props.mode) {
                case DASHBOARD_MODE.initial:
                    console.log('DashboardTab/UseEffect: initial/' + props.mode)
                    setMode(DASHBOARD_MODE.loading)
                    break
                case DASHBOARD_MODE.loading:
                    console.log('DashboardTab/UseEffect: loading/' + props.mode)

                    setMode(DASHBOARD_MODE.dashboard)
                    // const interval = setInterval(() => {
                    //     clearInterval(interval)
                    //     setMode(DASHBOARD_MODE.dashboard, sampleResponse)
                    // }, 10000);
                    break
                case DASHBOARD_MODE.polling:
                    console.log('DashboardTab/UseEffect: polling/' + props.mode)

                    // useInterval(() => {
                    //     console.log('DashboardTab/useInterval')
                    // }, 1000)
                    break
                default:
                    console.log('DashboardTab/UseEffect: default/' + props.mode)
            }
        }
    }, [props.loginMode, props.accountsState, props.session, props.mode, setMode])

    const onRefresh = () => {
        return new Promise((resolve, reject) => {
            setMode(DASHBOARD_MODE.loading)
            resolve()
        })
    }

    const loadingContent = () => {

        // newState.visible.refreshButton = false //TODO: $root.settingsShowRefreshButton;

        // const refreshButton = () => (refreshButtonVisible && <div style="margin-top: 10px;">
        //     <div style="" data-bind="dxButton: { onClick: refreshButtonClicked, text: 'Refresh' } "></div>
        // </div >)

        const pollingCountdownContent = () => (props.visible.polling && <Fragment>
            <div className="bold">Waiting for results</div>
            <div className="rem2" style="font-style: italic;">Rechecking in <strong>{props.polling.countdown}</strong> seconds...</div>
        </Fragment>)

        return (props.visible.loading &&
            // <div style="position: relative; top: 20px; width: 100%; text-align: center;">
            <div>
                <CircularProgress />
                {pollingCountdownContent()}
                {/* {refreshButton()} */}
            </div>
        )
    }

    const dashboardContent = () => {

        const headerContent = () => {
            if (!props.visible.header) return null

            if (isEmployeeUser) {
                return <h1 className='Title'>
                    <span>{props.companyNameText}</span>
                    <span>{props.numberOfStoresText}</span>
                </h1>
            }

            const w = '80vw'
            const h = '60vh'
            return <Fragment>
                <h1 className='Title'>
                    <div>{props.companyNameText}</div>
                    {(props.companyNameText !== props.store.name) && <div>{props.store.name}</div>}
                </h1>
                <div style={{ textAlign: 'center' }}>
                    <div>{props.store.address}</div>
                    <div>{props.store.address2}</div>
                    <div>{props.store.cityStatePostalCode}</div>
                    <div>{props.store.phone}</div>
                </div>
            </Fragment >
        }

        const summaryContent = () => {

            const summaryDetails = () => {

                const myTodaysSalesVisible = () => (props.preferences.showUserSales)
                const todaysSalesVisible = () => (props.session.access36)
                const thisMonthSalesVisible = () => (props.session.access36)
                const yearSalesVisible = () => (props.session.access36)
                const inventoryVisible = () => (props.session.access34 && props.preferences.showInventory)
                const poVisible = () => (props.session.access36 && props.preferences.showPO)
                const arVisible = () => (props.session.access37 && props.preferences.showAR)
                const apVisible = () => (props.session.access36 && props.preferences.showAP)

                const pctChangeClass = pctChange => (
                    pctChange.indexOf('-') >= 0 ? 'red' : 'green'
                )

                const myTodaysSalesContent = () => (<Fragment>
                    <div className='bold'>Today's Sales / AVG Ticket ({userName})</div>
                    <div className='rem2'>{props.summary.myTodaysSales} / {props.summary.myAvgTicket} ({props.summary.myNumberOfTickets} Tickets)</div>
                </Fragment>)

                const todaysSalesContent = () => (<Fragment>
                    <div className="bold">Today's Sales / AVG Ticket {props.numberOfStoresText}</div>
                    <div className="rem2">{props.summary.todaysSales} / {props.summary.avgTicket} ({props.summary.numberOfTickets} Tickets)</div>
                </Fragment>)

                const thisMonthSalesContent = () => (<Fragment>
                    <div className="bold">{thisMonth()} 1 to {thisMonth()} {thisDay()} ({thisYear()}/{lastYear()})</div>
                    <div className="rem2">
                        {props.summary.monthThisYear} / {props.summary.monthLastYear}
                        <span className={pctChangeClass(props.summary.pctChangeMTD)}> ({props.summary.pctChangeMTD})</span>
                    </div>
                </Fragment>)

                const yearsSalesContent = () => (<Fragment>
                    <div className="bold">January 1 to {thisMonth()} {thisDay()} ({thisYear()}/{lastYear()})</div>
                    <div className="rem2">
                        {props.summary.ytdThisYear} / {props.summary.ytdLastYear}
                        <span className={pctChangeClass(props.summary.pctChangeYTD)}> ({props.summary.pctChangeYTD})</span>
                    </div>
                </Fragment>)

                const inventoryContent = () => (<Fragment>
                    <div className="bold">Current Inventory (Cost / Retail)</div>
                    <div className="rem2">{props.summary.inventoryCost} / {props.summary.inventoryRetail}</div>
                </Fragment>)

                const poContent = () => (<Fragment>
                    <div className="bold">Open PO's</div>
                    <div className="rem2">{props.summary.openPOs}</div>
                </Fragment>)

                const arContent = () => (<Fragment>
                    <div className="bold">Accounts Receivable</div>
                    <div className="rem2">{props.summary.accountsReceivable}</div>
                </Fragment>)

                const apContent = () => (<Fragment>
                    <div className="bold">Accounts Payable</div>
                    <div className="rem2">{props.summary.accountsPayable}</div>
                </Fragment>)

                var content = null

                if (props.visible.data) {
                    if (
                        myTodaysSalesVisible() ||
                        todaysSalesVisible() ||
                        thisMonthSalesVisible() ||
                        yearSalesVisible() ||
                        inventoryVisible() ||
                        poVisible() ||
                        arVisible() ||
                        apVisible()
                    ) {
                        content = <Fragment>
                            {myTodaysSalesVisible() && myTodaysSalesContent()}
                            {todaysSalesVisible() && todaysSalesContent()}
                            {thisMonthSalesVisible() && thisMonthSalesContent()}
                            {yearSalesVisible() && yearsSalesContent()}
                            {inventoryVisible() && inventoryContent()}
                            {poVisible() && poContent()}
                            {arVisible() && arContent()}
                            {apVisible() && apContent()}
                        </Fragment>
                    } else {
                        content = <div className="bold">Show user's sales is turned off</div>
                    }
                }
                return content
            }

            return (isEmployeeUser &&
                <Fragment>
                    {loadingContent()}
                    {summaryDetails()}
                </Fragment>
            )
        }

        return <Fragment>
            {/* <div id="scrollView" data-bind="dxScrollView: { onPullDown: viewPulledDown }"> */}
            {/* <div data-bind="visible: newState.visible.pullDownReminder" style="text-align: center;" class="dds-sales-heading">Pull down to refresh</div> */}
            {headerContent()}
            {summaryContent()}
            {/* </div> */}
        </Fragment>

    }

    return <Container style={{ display: 'flex', justifyContent: 'center' }}>
        <PullToRefresh onRefresh={onRefresh}>
            <div className='Dashboard'>
                {isLoading() ? loadingContent() : (props.loginMode === LOGIN_MODE.authenticated ? dashboardContent() : null)}
            </div>
        </PullToRefresh>
    </Container>
}

const mapStateToProps = (state) => {
    return {
        accountsState: state.accountsReducer,

        preferences: state.preferencesReducer.preferences,

        loginReducer: state.loginReducer,
        loginMode: state.loginReducer.mode,
        session: state.loginReducer.session,
        isRetailUser: state.loginReducer.isRetailUser,

        store: state.storesReducer.store,
        companyNameText: state.storesReducer.companyNameText,
        numberOfStoresText: state.storesReducer.numberOfStoresText,

        mode: state.dashboardReducer.mode,
        visible: state.dashboardReducer.visible,
        polling: state.dashboardReducer.polling,
        summary: state.dashboardReducer.summary,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dashboardSetMode: (mode, selectedAccount, session) => { dispatch(dashboardSetMode(mode, selectedAccount, session)) },
        loginDisconnect: () => { dispatch(loginDisconnect()) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTab)
