import React from "react"
import PropTypes from 'prop-types'
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import isNonBlankString from '../../utils/isNonBlankString'
import CancelDialogButton from "../../hoc/Buttons/CancelDialogButton"
import OkDialogButton from "../../hoc/Buttons/OkDialogButton"

const AlertDialog = (props) => {
    const closeDialog = () => {
        props.setOpen(false)
    }

    const onAgree = () => {
        closeDialog()
        props.onAgree()
    }

    const onDisagree = () => {
        closeDialog()
        if (props.onDisagree) {
            props.onDisagree()
        }
    }

    const titleContent = () => {
        return (isNonBlankString(props.title) &&
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
        )
    }

    const messageContent = () => {
        return (isNonBlankString(props.message) &&
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
        )
    }

    const buttonsContent = () => {

        return (
            <DialogActions>
                <OkDialogButton style={{autoFocus: null}} onClick={onAgree}> {props.agreeText || "OK"} </OkDialogButton>
                <CancelDialogButton onClick={onDisagree}> {props.agreeText || "Cancel"} </CancelDialogButton>
            </DialogActions>
        )
    }

    return (
        <Dialog
            open={props.open}
            onClose={onDisagree}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {titleContent()}
            {messageContent()}
            {buttonsContent()}
        </Dialog>
    )
}

AlertDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    title: PropTypes.string,
    agreeText: PropTypes.string,
    disagreeText: PropTypes.string,
    onAgree: PropTypes.func.isRequired,
    onDisagree: PropTypes.func,
}

export default AlertDialog